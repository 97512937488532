<template>
  <div class="practice-rate">
    <div class="title">
      <div class="rectangle">
        <p>{{i18n.Pra.bx}}</p>
      </div>
      <div class="triangle"></div>
    </div>
    <div class="content">
      <div class="left-card">
        <div class="hiw-rate">
          <div class="title" style="color: #1f1f40">
            {{ detail.cate_info.cate_name }}{{i18n.Pra.Average}}
          </div>
          <div class="every-progress">
            <span class="every">{{i18n.Pra.everybody}}</span>
            <div style="flex: 1">
              <Progress :percentage="rateList.all_avg.all_rate" />
            </div>
            <span>
              {{ stringJudgment(rateList.all_avg.all_rate) }}
            </span>
          </div>
          <div class="my-progress">
            <span>{{i18n.Pra.my}}</span>
            <div style="flex: 1">
              <Progress :percentage="rateList.all_avg.my_rate" type="warning" />
            </div>
            <span>
              {{ stringJudgment(rateList.all_avg.my_rate) }}
            </span>
          </div>
        </div>
        <div class="line"></div>
        <div class="my-rate">
          <div class="title">{{i18n.Pra.text113}}</div>
          <div class="every-progress">
            <span>{{i18n.Pra.everybody}}</span>
            <div style="flex: 1">
              <Progress :percentage="rateList.one_avg.all_rate" />
            </div>
            <span>
              {{ stringJudgment(rateList.one_avg.all_rate) }}
            </span>
          </div>
          <div class="my-progress">
            <span>{{i18n.Pra.my}}</span>
            <div style="flex: 1">
              <Progress :percentage="rateList.one_avg.my_rate" type="warning" />
            </div>
            <span> {{ stringJudgment(rateList.one_avg.my_rate) }} </span>
          </div>
        </div>
      </div>
      <div class="right-card">
        <div class="my-hiw-rate">
          <span style="color: #1f1f40">
            {{i18n.Pra.my}}{{ detail.cate_info.cate_name }}{{i18n.Pra.text114}}
          </span>
          <div class="fu-title">
            <span> - - - - - </span>
            <span style="color: #3b86ff; margin-left: 8px">
              {{ goalScore }}{{i18n.Pra.points}}
            </span>
            <span> {{i18n.Pra.text115}}：</span>
            <span style="color: #3b86ff">
              {{ judgementCorrectRate(detail.cate_info.cate_name) }}%
            </span>
          </div>
          <div
            class="separation-line"
            :style="{ top: `${40 + separationlineHeight}px` }"
          >
            <span>
              - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
              - - - - - - - - - - - - - - - - - - - - - - - - - - -
            </span>
          </div>
        </div>
        <div class="week-progress">
          <ul class="every-week">
            <li
              class="middle-progress"
              v-for="(value, key, index) of rateList.my_avg"
              :key="index"
            >
              <p>{{ value === '-' ? i18n.Pra.text116 : value + '%' }}</p>
              <div class="progress">
                <ProgressColumn :percentage="value" v-if="value !== '-'" />
                <ProgressColumn :percentage="value" nodata v-else />
              </div>
              <p class="bottom-title">{{ weekList['week_' + index] }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Progress from './progress'
import ProgressColumn from './progress-column'
import { apiUserHomeStat, apiWFDRate } from '@/api/api'

export default {
  name: 'index',
  components: { Progress, ProgressColumn },
  props: {
    id: {
      type: [Number, String],
    },
    detail: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    i18n() {
      return this.$t('messages')
    },
  },
  data() {
    return {
      rateList: { all_avg: { all_rate: '-' }, one_avg: { all_rate: '-' } },
      weekList: {
        week_0: 'Now',
        week_1: '- Week 1',
        week_2: '- Week 2',
        week_3: '- Week 3',
        week_4: '- Week 4',
        week_5: '- Week 5',
        week_6: '- Week 6',
        week_7: '- Week 7',
      },
      goalScore: 0,
      correctList: [],
      separationlineHeight: 0,
    }
  },
  created() {
    this.fetchWFDRate()
    this.fetchUserStat()
  },
  methods: {
    fetchWFDRate() {
      apiWFDRate({
        question_id: this.id,
      }).then((res) => {
        if (res.code !== 200) {
          return
        }
        this.rateList = res.data
      })
    },
    stringJudgment(value) {
      if (value === '-') {
        return '数据不足'
      } else {
        return `${value}%`
      }
    },
    fetchUserStat() {
      apiUserHomeStat().then((res) => {
        if (res.code !== 200) {
          return
        }
        this.goalScore = res.data.goal_score ? res.data.goal_score : 0
        this.judgementCorrectList(this.goalScore)
      })
    },
    judgementCorrectList(val) {
      switch (true) {
        case val >= 84:
          this.correctList = [
            { cateName: 'MCM', value: 50 },
            { cateName: 'MCS', value: 50 },
            { cateName: 'RO', value: 90 },
            { cateName: 'RFIB', value: 90 },
            { cateName: 'FIBW', value: 90 },
            { cateName: 'FIB', value: 100 },
            { cateName: 'HCS', value: 100 },
            { cateName: 'SMW', value: 100 },
            { cateName: 'HIW', value: 100 },
            { cateName: 'WFD', value: 100 },
          ]
          break
        case val >= 79 && val <= 83:
          this.correctList = [
            { cateName: 'MCM', value: 50 },
            { cateName: 'MCS', value: 50 },
            { cateName: 'RO', value: 100 },
            { cateName: 'RFIB', value: 90 },
            { cateName: 'FIBW', value: 90 },
            { cateName: 'FIB', value: 100 },
            { cateName: 'HCS', value: 100 },
            { cateName: 'SMW', value: 100 },
            { cateName: 'HIW', value: 100 },
            { cateName: 'WFD', value: 100 },
          ]
          break
        case val >= 73 && val <= 78:
          this.correctList = [
            { cateName: 'MCM', value: 50 },
            { cateName: 'MCS', value: 50 },
            { cateName: 'RO', value: 90 },
            { cateName: 'RFIB', value: 80 },
            { cateName: 'FIBW', value: 80 },
            { cateName: 'FIB', value: 90 },
            { cateName: 'HCS', value: 50 },
            { cateName: 'SMW', value: 50 },
            { cateName: 'HIW', value: 90 },
            { cateName: 'WFD', value: 100 },
          ]
          break
        case val >= 65 && val <= 72:
          this.correctList = [
            { cateName: 'MCM', value: 25 },
            { cateName: 'MCS', value: 25 },
            { cateName: 'RO', value: 80 },
            { cateName: 'RFIB', value: 60 },
            { cateName: 'FIBW', value: 60 },
            { cateName: 'FIB', value: 80 },
            { cateName: 'HCS', value: 25 },
            { cateName: 'SMW', value: 25 },
            { cateName: 'HIW', value: 90 },
            { cateName: 'WFD', value: 90 },
          ]
          break
        case val >= 58 && val <= 64:
          this.correctList = [
            { cateName: 'MCM', value: 25 },
            { cateName: 'MCS', value: 25 },
            { cateName: 'RO', value: 80 },
            { cateName: 'RFIB', value: 60 },
            { cateName: 'FIBW', value: 60 },
            { cateName: 'FIB', value: 80 },
            { cateName: 'HCS', value: 25 },
            { cateName: 'SMW', value: 25 },
            { cateName: 'HIW', value: 80 },
            { cateName: 'WFD', value: 80 },
          ]
          break
        case val >= 50 && val <= 57:
          this.correctList = [
            { cateName: 'MCM', value: 25 },
            { cateName: 'MCS', value: 25 },
            { cateName: 'RO', value: 60 },
            { cateName: 'RFIB', value: 50 },
            { cateName: 'FIBW', value: 50 },
            { cateName: 'FIB', value: 60 },
            { cateName: 'HCS', value: 25 },
            { cateName: 'SMW', value: 25 },
            { cateName: 'HIW', value: 80 },
            { cateName: 'WFD', value: 80 },
          ]
          break
        case val >= 42 && val <= 49:
          this.correctList = [
            { cateName: 'MCM', value: 0 },
            { cateName: 'MCS', value: 0 },
            { cateName: 'RO', value: 60 },
            { cateName: 'RFIB', value: 50 },
            { cateName: 'FIBW', value: 50 },
            { cateName: 'FIB', value: 60 },
            { cateName: 'HCS', value: 0 },
            { cateName: 'SMW', value: 0 },
            { cateName: 'HIW', value: 60 },
            { cateName: 'WFD', value: 60 },
          ]
          break
        case val <= 41:
          this.correctList = [
            { cateName: 'MCM', value: 0 },
            { cateName: 'MCS', value: 0 },
            { cateName: 'RO', value: 50 },
            { cateName: 'RFIB', value: 40 },
            { cateName: 'FIBW', value: 40 },
            { cateName: 'FIB', value: 50 },
            { cateName: 'HCS', value: 0 },
            { cateName: 'SMW', value: 0 },
            { cateName: 'HIW', value: 50 },
            { cateName: 'WFD', value: 50 },
          ]
          break
        default:
          break
      }
    },
    judgementCorrectRate(val) {
      let nowCate = this.correctList.filter((item) => val === item.cateName)
      let percent = nowCate == false ? 0 : nowCate[0].value
      this.locationSeparationline(percent)
      return percent
    },
    locationSeparationline(val) {
      this.separationlineHeight = 52 - (val / 100) * 52
    },
  },
}
</script>

<style lang="scss" scoped>
.practice-rate {
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
  flex-direction: column;
  font-size: 14px;
  .title {
    display: flex;
    .rectangle {
      width: 140px;
      height: 30px;
      background: #f4f4f4;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 5px;
    }
    .triangle {
      width: 0;
      height: 0;
      border: 15px solid #f4f4f4;
      border-right-color: transparent;
      border-top-color: transparent;
      border-bottom-right-radius: 5px;
    }
  }
  .content {
    width: 1175px;
    height: 165px;
    background: #f4f4f4;
    display: flex;
    justify-content: space-between;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    .left-card {
      width: 560px;
      height: 138px;
      background: #ffffff;
      border-radius: 5px;
      margin-top: 14px;
      margin-left: 20px;
      display: flex;
      justify-content: space-between;
      color: #a4afb7;
      .hiw-rate {
        margin-top: 12px;
        margin-left: 24px;
        width: 220px;
        .every-progress {
          display: flex;
          margin-top: 24px;
          > span {
            font-size: 12px;
          }
        }
        .my-progress {
          display: flex;
          justify-content: space-between;
          margin-top: 24px;
          > span {
            font-size: 12px;
          }
        }
      }
      .line {
        height: 100px;
        border: 1px solid #f4f4f4;
        margin-top: 20px;
      }
      .my-rate {
        margin-top: 12px;
        margin-right: 24px;
        width: 220px;
        .title {
          color: #1f1f40;
          font-size: 14px;
        }
        .every-progress {
          display: flex;
          justify-content: space-between;
          margin-top: 24px;
          > span {
            font-size: 12px;
          }
        }
        .my-progress {
          display: flex;
          justify-content: space-between;
          margin-top: 24px;
          > span {
            font-size: 12px;
          }
        }
      }
    }
    .right-card {
      width: 560px;
      height: 138px;
      background: #ffffff;
      border-radius: 5px;
      margin-top: 14px;
      margin-right: 20px;
      .my-hiw-rate {
        margin-top: 12px;
        margin-left: 18px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .fu-title {
          margin-right: 40px;
          font-size: 12px;
        }
        .separation-line {
          position: absolute;
          margin-left: 4px;
        }
      }

      .week-progress {
        display: flex;
        height: 76%;
        font-size: 12px;
        .every-week {
          width: 90%;
          margin-left: 30px;
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          .middle-progress {
            display: flex;
            flex-direction: column;
            margin: 10px 6px 4px 6px;
            text-align: center;
            .progress {
              flex: 1;
              display: flex;
              margin-left: 13px;
              margin-top: 4px;
            }
            .bottom-title {
              width: 48px;
            }
          }
        }
      }
    }
  }
}
</style>

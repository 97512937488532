import VueVideo from "@/components/vue-video.vue";

const videoUrl = 'https://ptegofeifan.oss-accelerate.aliyuncs.com/upload/video/feifan/202007/08/RS%E6%84%8F%E7%BE%A4%E5%8A%9F%E8%83%BD%E8%AE%B2%E8%A7%A3%E5%B7%B2%E5%89%AA%E8%BE%91.mp4'

export default {
  components: {
    VueVideo
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localVisible: this.visible,
      activeName: 'video',
      showDialog: true,
      videoUrl,
    }
  },
  watch: {
    showDialog(val) {
      this.localVisible = val
    }
  },
  methods: {
    handleShowDialog(flag) {
      this.showDialog = flag
    },
    handleClose(){
      this.$emit('closeDialog')
		}
  }
}
<template>
  <div class="progressContainer">
    <div
      class="progress"
      :style="{
        height: `${percentage === '-' ? 100 : percentage}%`,
        background: nodata ? '#ddd' : '#FFC06A'
      }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    percentage: {
      type: Number | String,
      default: 0
    },
    nodata: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
div.progressContainer {
  height: 90%;
  width: 10px;
  border-radius: 10px;
  margin-top: 3%;
  margin-left: 12%;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}
div.progress {
  border-radius: 10px;
  height: 10px;
  line-height: 10px;
}
</style>

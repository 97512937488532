<template>
  <div class="exercises-tabs-item exercises-tabs-me">
    <div class="everyone-header" v-if="isPractice">
      <TabsBtn :list="typeList" :onClick="handleType" />
    </div>

    <List
      :id="id"
      :isPractice="isPractice"
      :typeInfo="typeInfo"
      :type="1"
      :detail="detail"
      :visible="currType === 'exercises'"
      v-show="currType === 'exercises'"
    />
    <Work
      :id="id"
      :isPractice="isPractice"
      :typeInfo="typeInfo"
      :detail="detail"
      :visible="currType === 'work'"
      v-show="currType === 'work'"
    />
  </div>
</template>

<script>
import TabsBtn from '@/components/tabs/btn'
import List from '../everyone/list'
import Work from './list'
import i18n from '@/i18n/i18n'
const TYPE_LIST = [
  {
    name: () => (i18n.locale === 'ch' ? '练习' : 'Practice'),
    value: 'exercises',
  },
  { name: () => (i18n.locale === 'ch' ? '作业' : 'Task'), value: 'work' },
]

export default {
  components: {
    TabsBtn,
    List,
    Work,
  },
  props: {
    id: {
      type: [Number, String],
    },
    isPractice: {
      type: Boolean,
    },
    typeInfo: {
      type: Object,
      default: () => {},
    },
    detail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currType: TYPE_LIST[0].value,
    }
  },
  computed: {
    typeList() {
      return TYPE_LIST.map((item) => ({
        ...item,
        name: item.name(),
      }))
    },
   
  },
  methods: {
    handleType({ value }) {
      this.currType = value
    },
  },
}
</script>
<style lang="scss" lang="scss">
.exercises-tabs-me {
  padding: 0 20px;
  overflow: hidden;
  .everyone-header {
    display: flex;
    align-items: center;
    color: #a4afb7;
    font-size: 12px;
  }
}
</style>

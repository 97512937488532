<template>
  <el-dialog
    :width="'786px'"
    :visible.sync="localVisible"
    :before-close="handleClose"
    class="exercises-introduce-advise"
  >
    <div class="title">Read aloud {{ i18n.Pra.text76 }}</div>
    <el-tabs v-model="activeName">
      <el-tab-pane :label="i18n.Pra.text63" :name="'video'">
        <VueVideo @showDialog="handleShowDialog" :src="videoUrl" />
      </el-tab-pane>
      <el-tab-pane :label="i18n.Pra.text64" :name="'text'" class="text-content">
        <div>
          <h3>{{ i18n.Pra.text77 }}</h3>
          <p>
            {{ i18n.Pra.text78 }}
            <br />
            <br />
            {{ i18n.Pra.example }}： <span style="margin-left: 26px">●</span
            ><span style="margin-left: 64px">●</span>
            <br />
            <span style="margin-left: 40px">I believe I can fly.</span>
            <br />
            <br />
            {{ i18n.Pra.text79 }}
            <br />
          </p>
        </div>
        <div>
          <h3>{{ i18n.Pra.text80 }}</h3>
          <p>
            {{ i18n.Pra.text81 }}
            <br />
            <br />
            {{ i18n.Pra.text82 }}： ▃ ▇ ▃ ▃
            <br />
            <span style="margin-left: 40px">com MU ni ty</span>
            <br />
            <br />
            comMUnity{{ i18n.Pra.text83 }}
          </p>
        </div>
        <div>
          <h3>{{ i18n.Pra.text84 }}</h3>
          <p>
            {{ i18n.Pra.text85 }}
            <br />
            <br />
            1.{{ i18n.Pra.text86 }}
            <br />
            {{ i18n.Pra.text87 }}
            <br />
            <br />
            2. {{ i18n.Pra.text89 }}
            <br />
            {{ i18n.Pra.text90 }}
            <br />
            <br />
            {{ i18n.Pra.text91 }}
          </p>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
import VueVideo from '@/components/vue-video.vue'

const videoUrl =
  'https://ptegofeifan.oss-accelerate.aliyuncs.com/go/admin_file_1613241208721110729594.mp4'

export default {
  components: {
    VueVideo,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localVisible: this.visible,
      activeName: 'video',
      showDialog: true,
      videoUrl,
    }
  },
  watch: {
    showDialog(val) {
      this.localVisible = val
    },
  },
  computed: {
    i18n() {
      return this.$t('messages')
    },
  },
  methods: {
    handleShowDialog(flag) {
      this.showDialog = flag
    },
    handleClose() {
      this.$emit('closeDialog')
    },
  },
}
</script>

<style lang="less">
.exercises-introduce-advise {
  .el-dialog__body {
    margin-top: -30px;
  }
  .el-dialog {
    border-radius: 10px;
    height: 550px;
    .el-dialog__title {
      display: none;
    }
  }
  .title {
    padding-bottom: 8px;
    color: #a4afb7;
    font-size: 14px;
    border-bottom: 1px solid #ebebf2;
  }
  video {
    width: 100%;
  }
  h3 {
    color: #1f1f40;
    font-size: 14px;
  }
  p {
    color: #a4afb7;
    padding: 0 0 15px;
  }
  .bar {
    margin-left: 30px;
  }
  .one,
  .three,
  .two {
    text-align: center;
  }
  .one,
  .three {
    width: 94px;
  }
  .three {
    width: 120px;
  }
  .two {
    width: 140px;
  }
  span {
    display: inline-block;
  }
  .text-content {
    height: 400px;
    overflow: auto;
  }
}
</style>

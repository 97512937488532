var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.visible)?_c('div',{staticClass:"exercises-tabs-everyone-work"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading && _vm.page === 1),expression:"loading && page === 1"}],staticClass:"wrapper"},[(_vm.list.length && _vm.isPractice)?_c('div',{staticClass:"infinite-list-wrapper"},[_c('ul',{staticClass:"detail-list"},_vm._l((_vm.list),function(item,index){return _c('li',{key:`${item.id}-${_vm.type}`,staticClass:"detail-list-item"},[_c('img',{attrs:{"src":item.user_logo}}),_c('div',[_c('div',[_c('span',{staticClass:"name"},[_vm._v(_vm._s(item.user_name))]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(item.user_goal))])]),(item.content_info.type === 'audio')?[_c('Audio',{attrs:{"url":item.contents,"id":item.id,"currentId":_vm.currentId},on:{"palyAudio":_vm.palyAudio}})]:[(item.answer_status && Number(item.answer_status) === 1)?_c('div',{staticClass:"text-record",domProps:{"innerHTML":_vm._s(item.contents)}}):_c('div',{staticClass:"text-record"},[(item.content_list)?_vm._l((item.content_list),function(text){return _c('p',{key:text},[_vm._v(" "+_vm._s(text)+" ")])}):(['HIW'].includes(_vm.typeInfo.type))?[_c('WordDiff',{attrs:{"type":"array","answer":_vm.detail.content_text
                        .match(/\{([^{]+)\}/gi)
                        .map((m) => m.replace(/\{|\}/g, '')),"content":item.content_info.content.split(',')}})]:[_vm._v(" "+_vm._s(item.content_info.content)+" ")]],2),(
                  [
                    'RO',
                    'MCM',
                    'FIB',
                    'HCS',
                    'MCS',
                    'SMW',
                    'HIW',
                    'WFD',
                    'RFIB',
                    'RWFIB',
                  ].includes(_vm.typeInfo.type)
                )?_c('span',{staticClass:"score"},[_vm._v(" "+_vm._s(item.score_my)+_vm._s(_vm.$t('gre.point'))+" ")]):_vm._e()],_c('div',{staticClass:"bottom"},[_c('span',{staticClass:"time"},[_vm._v(_vm._s(item.created_at))]),_c('span',{staticClass:"favour"},[_c('i',{staticClass:"f-icon",class:[item.is_liked ? 'icon-likes' : 'icon-like'],on:{"click":function($event){return _vm.handleLike(item, index)}}}),_vm._v(_vm._s(item.like_cnt))])]),(item.subList && item.subList.length)?_c('div',{staticClass:"fold"},[_vm._v(" "+_vm._s(_vm.i18n.Pra.Folded)+_vm._s(item.subList.length)+_vm._s(_vm.i18n.Pra.Records)+"，"),_c('span',{on:{"click":function($event){return _vm.showAll(index)}}},[_vm._v(_vm._s(_vm.i18n.Forum.Click))])]):_vm._e()],2)])}),0),_c('More',{attrs:{"page":_vm.page,"pageSize":_vm.pageSize,"loading":_vm.loading,"totalPage":_vm.count.page},on:{"load":_vm.load}})],1):_vm._e(),(!_vm.isPractice || (!_vm.loading && !_vm.list.length))?_c('Empty'):_vm._e()],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
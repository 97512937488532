const LIST = [
  {
    icon: 'icon-two',
    name: i18n.locale === 'ch' ? '顺序模式' : 'Sequential',
    type: 1
  },
  {
    icon: 'icon-lianhe',
    name: i18n.locale === 'ch' ? '随机模式' : 'Random',
    type: 2
  }
]
import i18n from '@/i18n/i18n'

export default {
  props: {
    filters: Object,
    search: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      visible: false,
      list: LIST,
      model: LIST[0]
    }
  },
  created() {
    this.model =
      LIST.find((item) => item.type === this.filters.play_order) || {}
  },
  methods: {
    handleSelect(item) {
      this.model = item
      this.visible = false
      this.search({ ...this.filters, play_order: this.model.type })
    }
  }
}

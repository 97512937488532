<template>
  <el-dialog
    :visible.sync="visible"
    class="exercise-ai-content"
    width="900px"
    :close-on-click-modal="false"
  >
    <div class="title">{{ i18n.Pra.text20 }}</div>
    <div class="core-table">
      <template v-if="['ASQ'].includes(typeInfo.type)">
        <ul class="header">
          <li>{{ i18n.Pra.text23 }}</li>
          <li>{{ i18n.Pra.text24 }}</li>
          <li class="desc header">
            <span>{{ i18n.Pra.text25 }}</span>
            <a
              href="/#/course/chapter/Detail?skillId=35&type=1"
              target="_blank"
              class="link"
              >{{ i18n.Pra.text26 }} <i class="iconfont icon-info"></i
            ></a>
          </li>
        </ul>
        <ul>
          <li>{{ i18n.Pra.text27 }}</li>
          <li class="core">{{ wordDetailsScoreFlag ? 1 : 0 }}</li>
          <li class="desc">
            {{ wordDetailsScoreFlag ? i18n.Pra.text28 : i18n.Pra.text29 }}
          </li>
        </ul>
        <ul class="footer">
          <li>{{ i18n.Pra.text30 }}</li>
          <li class="core">
            <span class="total-score">{{ wordDetailsScoreFlag ? 1 : 0 }}</span>
          </li>
          <li class="desc">
            <div class="audio-wrap">
              <span>{{ i18n.Pra.text31 }}</span>
              <AudioToolMini :audioUrl="recorderUrl" />
            </div>
          </li>
        </ul>
      </template>
      <template v-else>
        <ul class="header">
          <li>{{ i18n.Pra.text32 }}</li>
          <li>{{ i18n.Pra.text33 }}</li>
          <li class="desc header">
            <span>{{ i18n.Pra.text34 }}</span>
            <a
              href="/#/course/chapter/Detail?skillId=35&type=1"
              target="_blank"
              class="link"
              >{{ i18n.Pra.text26 }} <i class="iconfont icon-info"></i
            ></a>
          </li>
        </ul>
        <ul>
          <li>{{ i18n.Pra.fluency }}</li>
          <li class="core">{{ score.fluency }}</li>
          <li class="desc">
            {{ score.fluency | getFluencyDesc(typeInfo.type) }}
          </li>
        </ul>
        <ul>
          <li>{{ i18n.Pra.text35 }}</li>
          <li class="core">{{ score.pron }}</li>
          <li class="desc">
            {{ score.pron | getPronDesc(typeInfo.type) }}
          </li>
        </ul>
        <ul>
          <li>{{ i18n.Pra.text36 }}</li>
          <li class="core">{{ score.integrity }}</li>
          <li class="desc">{{ i18n.Pra.text37 }}</li>
        </ul>
        <ul class="footer">
          <li>{{ i18n.Pra.text38 }}</li>
          <li class="core">
            <span class="total-score">{{ score.overall }}</span>
          </li>
          <li class="desc">
            <div class="audio-wrap">
              <span>{{ i18n.Pra.text39 }}</span>
              <AudioToolMini :audioUrl="recorderUrl" />
            </div>
          </li>
        </ul>
      </template>
    </div>

    <div class="block-ai-content">
      <div class="block-header">
        <div class="block-header-title">
          <span>AI</span>{{ i18n.Pra.text40 }}
        </div>
        <div class="mark-tag">
          <template v-if="!['DI', 'RL', 'ASQ'].includes(typeInfo.type)">
            <div class="mark success">{{ i18n.Pra.text41 }}</div>
            <div class="mark middle">{{ i18n.Pra.good }}</div>
            <div class="mark fail">{{ i18n.Pra.difference }}</div>
            <template v-if="['RA', 'RS'].includes(typeInfo.type)">
              <div class="mark miss">{{ i18n.Pra.text42 }}</div>
              <!--              <div class="mark more">{{ i18n.Pra.text43 }}</div>-->
            </template>
          </template>
        </div>
      </div>

      <div class="block-ai-body">
        <template v-if="['DI', 'RL'].includes(typeInfo.type)">
          <span
            v-for="(text, index) of audioTextList"
            :key="`${index}_${text}`"
          >
            <span class="word">{{ text }} </span>
          </span>
        </template>
        <template v-else>
          <span v-for="group of wordDetails" :key="group.text">
            <span
              v-for="(item, index) of group.snt_details"
              :key="`${item.char}_${index}`"
              class="word"
              :class="genWordClass(item)"
            >
              <template v-if="item.dp_type == 1"> ({{ item.char }}) </template>
              <template v-else>{{ item.char }} </template>
            </span>
          </span>
        </template>
      </div>
    </div>

    <div class="block-text">
      <template v-if="!isShowMore">
        <div class="block-header">
          <div class="block-header-title">
            <span
              >本题语音识别正确率：{{
                Math.round(recorderCore.similar_rate / 100)
              }}%</span
            >
            <span class="tip">
              <el-tooltip placement="top">
                <div slot="content">
                  识别正确率与你的发音正相关。<br />
                  下方展示的是你的读音被AI识别出的文本。<br />
                  其中黑色部分表示与原文文本一致，蓝色部<br />
                  分表示与原文文本不一致。请仔细对照，并<br />
                  注意这些单词的发音问题。
                </div>
                <img
                  :src="
                    tipImgFlag
                      ? 'https://ptegofeifan.oss-accelerate.aliyuncs.com/go/b7bc5532dedf00a39f9c8f2b38d0de1ad19ddde441f43c4afdd91bcdfd03f0bc.png'
                      : 'https://ptegofeifan.oss-accelerate.aliyuncs.com/go/0ff7cfb267d0af779a2b19b324bd4f488425127e136cd071ebd8480aed7e6168.png'
                  "
                  alt=""
                  @mouseenter="tipImgFlag = true"
                  @mouseleave="tipImgFlag = false"
                />
              </el-tooltip>
            </span>
          </div>
          <div class="block-header-tools" @click="isShowMore = !isShowMore">
            <span>展开详情</span>
            <i class="iconfont icon-Group64"></i>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="block-header">
          <div class="block-header-title">
            本题语音识别正确率：{{
              Math.round(recorderCore.similar_rate / 100)
            }}%
          </div>
          <div class="block-header-tag">
            <div>与原文一致</div>
            <div class="active">与原文不一致</div>
          </div>
        </div>
        <div class="block-body">
          <span
            v-for="item of similarText"
            :class="{ active: item.removed || item.added }"
            >{{ item.value }}
          </span>
        </div>
      </template>
    </div>
  </el-dialog>
</template>

<script>
import AudioToolMini from '@/components/AudioToolMini/AudioToolMini.vue'
import { wordSpaceCalc, symbolsFix } from '@/utils/parctice'
import { diffWordsArray } from '@/utils/string'
import { getFluencyText, getPronText } from './aiCoreDesc'
import { apiAiText } from '@/api/api'

export default {
  components: {
    AudioToolMini
  },
  filters: {
    getPronDesc(score, type) {
      return getPronText(score, type)
    },
    getFluencyDesc(score, type) {
      return getFluencyText(score, type)
    }
  },
  props: {
    typeInfo: {
      type: Object,
      default: () => ({})
    },
    answer: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      visible: false,

      isShowMore: false,
      tipImgFlag: false,

      recorderCore: {},
      audioTextList: []
    }
  },
  computed: {
    i18n() {
      return this.$t('messages')
    },
    score() {
      const { pron, fluency, integrity, overall } = this.recorderCore || {}
      return {
        pron: pron || 0,
        fluency: fluency || 0,
        integrity: integrity || 0,
        overall: overall || 0
      }
    },
    recorderUrl() {
      return this.recorderCore.contents
    },
    wordDetails() {
      try {
        const jsonData = JSON.parse(this.recorderCore.details_json)
        return jsonData
      } catch (e) {
        return []
      }
    },
    similarText() {
      // return this.recorderCore.similar_text.split(/\s+/g)
      return diffWordsArray(this.answer, this.recorderCore.similar_text)
    },

    wordDetailsScoreFlag() {
      return this.wordDetails
        .filter((m) => m.score > 0)
        .map((m) => m.text)
        .join(' ')
        .includes(this.answer)
    }
  },
  methods: {
    open(data) {
      this.recorderCore = data

      this.visible = true
      this.isShowMore = false

      this.audioTextList = data.similar_text ? data.similar_text.split(' ') : []
      if (['RL', 'DI'].includes(this.typeInfo.type) && !data.similar_text) {
        this.fetchAudioText()
      }
    },
    wordSpace(greRecorder, item, index) {
      return wordSpaceCalc(greRecorder, item, index)
    },

    async fetchAudioText() {
      const { data } = await apiAiText({
        question_id: 0,
        oss_url: this.recorderUrl
      })
      this.audioTextList = (data || []).map((item) => item.text)
    },

    genWordClass(item) {
      if (item.score > 45) {
        return 'success'
      } else if (item.score > 10) {
        return 'middle'
      } else {
        if (item.dp_type == 1) {
          return 'miss'
        } else if (item.dp_type == 2) {
          return 'more'
        }
        return 'fail'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.exercise-ai-content {
  ::v-deep .el-dialog {
    border-radius: 10px;
  }

  ::v-deep .el-dialog__header {
    padding: 0;

    .el-dialog__title {
      display: none;
    }

    .el-dialog__headerbtn {
      top: 20px;
    }
  }

  .title {
    color: #a4afb7;
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 6px;
  }

  .block {
    .block-title {
      font-size: 14px;
      font-weight: bold;
      color: #1f1f40;
      margin-top: 10px;
    }

    .block-content {
      margin-top: 6px;
      line-height: 24px;
      font-size: 14px;
      color: #1f1f40;
    }
  }

  .core-table {
    border-radius: 5px;
    border: 1px solid #ebebf2;
    margin-top: 10px;

    > ul {
      padding: 8px 10px;
      border-bottom: 1px solid #ebebf2;
      display: flex;
      align-items: center;

      &.header {
        background-color: #f4f5f9;
      }

      &.footer {
        background-color: #f4f5f9;
      }

      > li {
        flex: 1;
        padding: 0 10px;

        &.core {
          color: #3b86ff;
          font-weight: bold;
          padding-left: 20px;

          .total-score {
            width: 34px;
            height: 34px;
            display: inline-block;
            border-radius: 5px;
            color: #fff;
            background-color: #3b86ff;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: -10px;
          }
        }

        &.desc {
          flex: 5;

          &.header {
            display: flex;
            justify-content: space-between;

            > .link {
              color: #bfbfbf;
              cursor: pointer;
              text-decoration: none;
            }
          }

          .audio-wrap {
            display: flex;
          }
        }

        &.tools {
          text-align: right;
        }
      }
    }
  }

  .block-audio {
    margin-top: 20px;
    padding: 17px 20px;
    border-radius: 5px;
    background-color: #f4f5f9;
    display: flex;
    align-items: center;

    ::v-deep .audio-list-mini {
      height: 30px;
      border-radius: 15px;
      line-height: 30px;
    }
  }

  .block-ai-content {
    margin-top: 24px;

    .block-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .block-header-title {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #ffc06a;
        font-weight: bold;

        > span {
          margin-right: 4px;
          font-size: 12px;
          height: 18px;
          width: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px;
          background-color: #ffc06a;
          color: #fff;
        }
      }

      .mark-tag {
        display: flex;
        align-items: center;

        .mark {
          margin-left: 30px;
          position: relative;
          color: #36c626;

          &:after {
            content: '';
            position: absolute;
            left: -14px;
            top: 6px;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background-color: #36c626;
          }

          &.success {
            color: #36c626;

            &:after {
              background-color: #36c626;
            }
          }

          &.middle {
            color: #ffc06a;

            &:after {
              background-color: #ffc06a;
            }
          }

          &.fail {
            color: #ff606d;

            &:after {
              background-color: #ff606d;
            }
          }

          &.miss {
            color: #bfbfbf;

            &:after {
              background-color: #bfbfbf;
            }
          }

          &.more {
            text-decoration: line-through;
            color: #bfbfbf;

            &:after {
              background-color: #bfbfbf;
            }
          }
        }
      }
    }

    .block-ai-body {
      margin-top: 10px;
      line-height: 24px;
      font-size: 14px;
      min-height: 100px;

      .word {
        color: #36c626;

        &.success {
          color: #36c626;
        }

        &.middle {
          color: #ffc06a;
        }

        &.fail {
          color: #ff606d;
        }

        &.miss {
          color: #bfbfbf;
        }

        &.more {
          text-decoration: line-through;
          color: #bfbfbf;
          display: none;
        }
      }
    }
  }

  .block-text {
    padding: 10px 14px;
    background-color: #f4f5f9;
    border-radius: 5px;

    .block-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-title {
        font-size: 14px;
        color: #1f1f40;
        display: flex;
        align-items: center;

        .tip {
          margin-left: 12px;
          display: flex;
          align-items: center;

          > img {
            width: 15px;
            height: 15px;
            cursor: pointer;
          }
        }
      }

      &-tag {
        display: flex;
        align-items: center;

        > div {
          color: #9c9c9c;
          font-size: 14px;
          padding-left: 14px;
          position: relative;
          margin-left: 16px;

          &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #9c9c9c;
          }

          &.active {
            color: #3b86ff;

            &:after {
              background-color: #3b86ff;
            }
          }
        }
      }

      &-tools {
        font-size: 14px;
        color: #3b86ff;
        cursor: pointer;

        > i {
          display: inline-block;
          transform: rotateZ(180deg);
          margin-left: 6px;
        }
      }
    }

    .block-body {
      margin-top: 12px;
      font-size: 14px;
      line-height: 24px;
      color: #9c9c9c;
      .active {
        color: #3b86ff;
      }
    }
  }
}
</style>

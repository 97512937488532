<template>
  <div class="exercises-tabs-everyone-list">
    <div v-loading="loading && page === 1" class="wrapper">
      <div class="infinite-list-wrapper" v-if="list.length && isPractice">
        <ul
          class="detail-list"
          v-infinite-scroll="load"
          :infinite-scroll-disabled="true"
          :infinite-scroll-immediate="false"
        >
          <Item
            v-for="(item, index) of list"
            :key="`${item.id}-${type}`"
            :item="item"
            :detail="detail"
            :index="index"
            :id="id"
            :isPractice="isPractice"
            :typeInfo="typeInfo"
            :type="type"
            :currentId="currentId"
            :palyAudio="palyAudio"
            :handleShowAudio="handleShowAudio"
            :showAiCore="showAiCore"
            :showAiProgress="showAiProgress"
            :handleLike="handleLike"
            :handleDel="handleDel"
            :showAll="showAll"
            :showAiVip="showAiVip"
            :onShare="handleShare"
          />
        </ul>

        <More
          :page="page"
          :pageSize="pageSize"
          :loading="loading"
          :totalPage="Math.min(10, count.page)"
          @load="load"
        />
      </div>
      <Empty v-if="!isPractice || (!loading && !list.length)" />
    </div>

    <AiCore
      ref="aiCore"
      :typeInfo="typeInfo"
      :answer="detail.question_answer"
    />
    <AiProgress ref="aiProgress" />
    <AiVip ref="aiVip" />
    <Share ref="share" />
  </div>
</template>

<script>
import Empty from '@/components/ExercisesEmpty.vue'
import More from '@/components/more'
import AiCore from '@/components/ExercisesContent/components/aiCore'
import AiProgress from '@/components/ExercisesContent/components/aiProgress'
import AiVip from '@/components/ExercisesContent/components/aiVip'
import Share from '@/components/share/practice'
import Item from './item'

import {
  apiCommentLike,
  apiDelMyTry,
  apiQuestionTryList,
  apiAiCore
} from '@/api/api'

export default {
  components: {
    Empty,
    More,
    AiCore,
    AiProgress,
    Item,
    AiVip,
    Share
  },
  props: {
    id: {
      type: [Number, String]
    },
    isPractice: {
      type: Boolean
    },
    typeInfo: {
      type: Object,
      default: () => {}
    },
    type: {
      type: Number,
      default: 0
    },
    onTotal: {
      type: Function,
      default: () => {}
    },
    detail: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    disabled() {
      return this.loading
    }
  },
  data() {
    return {
      page: 1,
      pageSize: 10,

      count: {
        done: 0,
        view: 0,
        all: 0,
        page: 0
      },

      loading: false,
      likeLoading: false,
      list: [],
      currentId: null
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    getSelectAnswer(contents) {
      if (!contents) {
        return contents
      }
      const list = contents.split(',')
      const letterList = list.map((item) =>
        String.fromCharCode(65 + Number(item))
      )
      return letterList.join(',')
    },
    getNumberAnswer(contents) {
      if (!contents) {
        return contents
      }
      const list = contents.split(',')
      const letterList = list.map((item) => Number(item) + 1)
      return letterList.join(',')
    },
    palyAudio(id) {
      this.currentId = id
    },
    load(page) {
      this.fetchList(page)
    },
    handleLike(item, index) {
      if (this.likeLoading) {
        return
      }
      apiCommentLike({
        aid: item.id,
        type: 'user_questions'
      })
        .then((res) => {
          if (res.code != 200) {
            return
          }
          this.$layer.msg(res.msg)
          const add = item.is_liked ? -1 : 1
          this.list[index].is_liked = add > 0 ? 1 : 0
          this.list[index].like_cnt += add
        })
        .finally(() => {
          this.likeLoading = false
        })
    },
    handleDel(item) {
      apiDelMyTry({
        id: item.id
      }).then((res) => {
        if (res.code != 200) {
          return
        }
        this.$layer.msg(res.msg)
        this.fetchList(1)
      })
    },
    fetchList(page) {
      if (!this.isPractice) {
        return
      }
      if (this.loading) {
        return
      }
      this.loading = true
      if (page) {
        this.page = page
      }
      apiQuestionTryList({
        question_id: this.id,
        page_size: this.pageSize,
        page: this.page,
        type: this.type
      }).then((res) => {
        if (res.code != 200) {
          this.loading = false
          return
        }
        const { my_try_cnt, all_view_cnt, all_try_cnt, pager } = res.data

        this.count = {
          done: my_try_cnt,
          view: all_view_cnt,
          all: all_try_cnt,
          page: pager.page_cnt
        }
        this.onTotal(this.count)

        let list = res.data.list
        if (['MCS', 'MCM', 'HCS', 'SMW'].includes(this.typeInfo.type)) {
          list = list.map((item) => {
            return {
              ...item,
              contents: this.getSelectAnswer(item.contents)
            }
          })
        }
        if (['RO'].includes(this.typeInfo.type)) {
          list = list.map((item) => {
            return {
              ...item,
              contents: this.getNumberAnswer(item.contents)
            }
          })
        }
        let foldList = list
        const endItem = this.page !== 1 ? [this.list[this.list.length - 1]] : []
        if (this.type !== 1) {
          foldList = list.reduce((pre, next) => {
            const preItem = pre.length ? pre[pre.length - 1] : {}
            const time = new Date(next.created_at).getTime()
            if (
              preItem.uid === next.uid &&
              (!preItem.subList || preItem.subList.length) &&
              new Date(preItem.created_at).getTime() - time <= 1800000
            ) {
              const addNextItem = { ...next, subList: [] }
              const addItem = preItem.subList
                ? { ...preItem, subList: [...preItem.subList, addNextItem] }
                : { ...preItem, subList: [addNextItem] }
              return [...pre.slice(0, pre.length - 1), addItem]
            } else {
              return [...pre, next]
            }
          }, endItem)
        }
        this.list =
          this.page === 1
            ? foldList
            : [...this.list.slice(0, this.list.length - 1), ...foldList]
        this.loading = false
      })
    },
    showAll(index) {
      this.list.splice(index + 1, 0, ...this.list[index].subList)
      this.list[index].subList = []
    },

    handleShowAudio(item) {
      if (item.aiCore) {
        this.$refs.aiCore.open(item)
      }
    },

    // ai
    showAiCore(data) {
      this.$refs.aiCore.open(data)
    },
    showAiProgress(toggle) {
      if (toggle === false) {
        this.$refs.aiProgress.close()
      } else {
        this.$refs.aiProgress.open()
      }
    },
    showAiVip() {
      return this.$refs.aiVip.open()
    },

    handleShare(item) {
      this.$refs.share.open(item)
    }
  }
}
</script>

<style scoped lang="scss">
.exercises-tabs-everyone-list {
  .detail-list {
    color: #a4afb7;
    font-size: 12px;
  }
  .wrapper {
    height: calc(100% - 50px);
    overflow-y: auto;
  }
}
</style>

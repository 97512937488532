<template>
  <div class="progressContainer">
    <div
      class="progress"
      :style="{
        width: `${percentage === '-' ? 100 : percentage}%`,
        background:
          percentage === '-'
            ? '#ddd'
            : type === 'warning'
            ? '#FFC06A'
            : '#3b86ff'
      }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    percentage: {
      type: Number | String,
      default: 0
    },
    type: {
      type: String,
      default: ''
    },
    nodata: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
div.progressContainer {
  height: 10px;
  width: 70%;
  border-radius: 10px;
  margin-top: 3%;
  margin-left: 12%;
}
div.progress {
  border-radius: 10px;
  height: 10px;
  line-height: 10px;
}
</style>

<template>
  <div class="com-auto-play" @click="handleChange" v-if="isShow">
    <div class="btn" :class="{ active: userInfo.is_auto_play == 1 }">
      <i class="iconfont icon-autoPlay"></i>
    </div>

    <div class="tip">
      <div class="arrow"></div>
      {{ userInfo.is_auto_play == 1 ? openMsg : hideMsg }}
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { apiSetAutoPlay } from '@/api/userInfo'
export default {
  props: {
    openMsg: {
      type: String,
      default: '自动播放音频已打开'
    },
    hideMsg: {
      type: String,
      default: '自动播放音频已关闭'
    }
  },
  data() {
    return {
      isShow: false
    }
  },
  created() {
    this.isShow = document.querySelectorAll('.com-auto-play').length == 0
  },
  computed: {
    ...mapState('app', {
      userInfo: (state) => state.userInfo
    })
  },
  methods: {
    ...mapActions('app', ['GetUserInfo']),
    async handleChange() {
      await apiSetAutoPlay({
        is_auto_play: this.userInfo.is_auto_play == 1 ? 2 : 1
      })

      this.GetUserInfo()
    }
  }
}
</script>

<style scoped lang="scss">
.com-auto-play {
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 58px;
  height: 24px;
  background-color: #e9e9f0;
  border-radius: 24px;
  cursor: pointer;
  &:hover {
    .tip {
      display: block;
    }
  }
  .btn {
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 36px;
    border-radius: 24px;
    background-color: #bfbfbf;
    transition: all 0.3s ease-in-out;
    &.active {
      left: calc(100% - 36px);
      background-color: #3ec2a3;
    }
    > i {
      color: #fff;
    }
  }
  .tip {
    display: none;
    position: absolute;
    top: -50px;
    left: -50px;
    border-radius: 4px;
    width: 130px;
    padding: 8px;
    background-color: rgba(58, 58, 58, 0.8);
    font-size: 14px;
    color: #fff;
    transition: all 0.3s ease-in-out;
    .arrow {
      position: absolute;
      bottom: -6px;
      left: calc(50% - 3px);
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid rgba(58, 58, 58, 0.8);
    }
  }
}
</style>

const aiPronDesc = [
  {
    score: [81, 90],
    text:
      '所有元音和辅音发音准确，非常接近于英语母语使用者的发音方式。同时正确使用了同化音以及弱化音来做到正确的连读。所有单词重音以及句子重音皆为正确的位置。解释：发音准确，无明显口音，同化音和弱化音使用准确，所有重音准确。'
  },
  {
    score: [66, 80],
    text:
      '元音和辅音发音清晰，所有单词清晰易懂，但是出现了少数不影响清晰度的元音或辅音发音不正确的情况，所有常用词的重音位置无误，且句子重音合理。'
  },
  {
    score: [46, 65],
    text:
      '大多数元音辅音发音正常，出现了一些导致单词清晰度下降的错误，或者一些辅音轻微失真，遗漏或发音错误。部分重音可能出现了元音弱化。'
  },
  {
    score: [31, 45],
    text:
      '一些元音和辅音持续性的发音错误，语音至少2/3可以理解，但是可以听出一定的口音。出现了漏掉部分辅音的情况，且辅音顺序可能出现了简化。单词重音放置不正确或不清晰。'
  },
  {
    score: [11, 30],
    text:
      '许多元音和辅音的发音都是错误的，导致发音听起来有很重的口音。其中约1/3的内容无法理解，出现了许多辅音失真或省略的情况。单词重音完全不正确，出现了添加/遗漏音节的现象。'
  },
  {
    score: [10, 10],
    text:
      '发音完全是另一种语言，大量的元音和辅音发音错误，音节遗漏或缺失。超过一半的内容无法理解，重音和非重音以非英语的方式呈现，音节数量出错。'
  }
]
const aiFluencyDesc = [
  {
    score: [81, 90],
    text:
      '呈现出了流利的节奏和意群感，没有出现犹豫，重复，开头错误以及非母语方式的语音简化。'
  },
  {
    score: [66, 80],
    text:
      '呈现出不错的节奏和适当的意群感以及表达重点。出现了不超过一个犹豫，重复，开头错误，且无明显的非母语方式的语音简化。'
  },
  {
    score: [46, 65],
    text:
      '语速适当，但可能不稳定。出现了不止一次犹豫，但大多数内容是以连续的方式呈现出来的。出现了几次重复和开头错误，没有出现长停顿或断断续续发音。'
  },
  {
    score: [31, 45],
    text:
      '语音可能断断续续，超过六个单词的段落至少有一个平滑的三个连续单词发音，并且没出现两到三次犹豫，重复或者开头错误。可以出现一个长的停顿。'
  },
  {
    score: [11, 30],
    text:
      '语音呈现不规则的节奏和意群，意群感非常糟糕，发音断断续续，多次犹豫，重复，开头错误，导致口语呈现出明显的不均匀或不连续，可能出现一到两个长停顿，且句子层面单词强调不佳。'
  },
  {
    score: [10, 10],
    text:
      '语音听起来缓慢且吃力，没有明显的意群，多次犹豫，停顿，开头错误以及非常明显的错误的语音简化。大多数单词是独立出现的，没有出现连读，出现不止一个长停顿。'
  }
]

const rsAiPronDesc = [
  {
    score: [81, 90],
    text:
      '所有元音和辅音发音准确，非常接近于英语母语使用者的发音方式。同时正确使用了同化音以及弱化音来做到正确的连读。所有单词重音以及句子重音皆为正确的位置。解释：发音准确，无明显口音，同化音和弱化音使用准确，所有重音准确。'
  },
  {
    score: [66, 80],
    text:
      '元音和辅音发音清晰，所有单词清晰易懂，但是出现了少数不影响清晰度的元音或辅音发音不正确的情况，所有常用词的重音位置无误，且句子重音合理。'
  },
  {
    score: [46, 65],
    text:
      '大多数元音辅音发音正常，出现了一些导致单词清晰度下降的错误，或者一些辅音轻微失真，遗漏或发音错误。部分重音可能出现了元音弱化。'
  },
  {
    score: [31, 45],
    text:
      '一些元音和辅音持续性的发音错误，语音至少2/3可以理解，但是可以听出一定的口音。出现了漏掉部分辅音的情况，且辅音顺序可能出现了简化。单词重音放置不正确或不清晰。'
  },
  {
    score: [11, 30],
    text:
      '许多元音和辅音的发音都是错误的，导致发音听起来有很重的口音。其中约1/3的内容无法理解，出现了许多辅音失真或省略的情况。单词重音完全不正确，出现了添加/遗漏音节的现象。'
  },
  {
    score: [10, 10],
    text:
      '发音完全是另一种语言，大量的元音和辅音发音错误，音节遗漏或缺失。超过一半的内容无法理解，重音和非重音以非英语的方式呈现，音节数量出错。'
  }
]
const rsFluencyDesc = [
  {
    score: [81, 90],
    text:
      '呈现出了流利的节奏和意群感，没有出现犹豫，重复，开头错误以及非母语方式的语音简化。'
  },
  {
    score: [66, 80],
    text:
      '呈现出不错的节奏和适当的意群感以及表达重点。出现了不超过一个犹豫，重复，开头错误，且无明显的非母语方式的语音简化。'
  },
  {
    score: [46, 65],
    text:
      '语速适当，但可能不稳定。出现了不止一次犹豫，但大多数内容是以连续的方式呈现出来的。出现了几次重复和开头错误，没有出现长停顿或断断续续发音。'
  },
  {
    score: [31, 45],
    text:
      '语音可能断断续续，超过六个单词的段落至少有一个平滑的三个连续单词发音，并且没出现两到三次犹豫，重复或者开头错误。可以出现一个长的停顿。'
  },
  {
    score: [11, 30],
    text:
      '语音呈现不规则的节奏和意群，意群感非常糟糕，发音断断续续，多次犹豫，重复，开头错误，导致口语呈现出明显的不均匀或不连续，可能出现一到两个长停顿，且句子层面单词强调不佳。'
  },
  {
    score: [10, 10],
    text:
      '语音听起来缓慢且吃力，没有明显的意群，多次犹豫，停顿，开头错误以及非常明显的错误的语音简化。大多数单词是独立出现的，没有出现连读，出现不止一个长停顿。'
  }
]

const diAiPronDesc = [
  {
    score: [81, 90],
    text:
      '所有元音和辅音发音准确，非常接近于英语母语使用者的发音方式。同时正确使用了同化音以及弱化音来做到正确的连读。所有单词重音以及句子重音皆为正确的位置。解释：发音准确，无明显口音，同化音和弱化音使用准确，所有重音准确。'
  },
  {
    score: [66, 80],
    text:
      '元音和辅音发音清晰，所有单词清晰易懂，但是出现了少数不影响清晰度的元音或辅音发音不正确的情况，所有常用词的重音位置无误，且句子重音合理。'
  },
  {
    score: [46, 65],
    text:
      '大多数元音辅音发音正常，出现了一些导致单词清晰度下降的错误，或者一些辅音轻微失真，遗漏或发音错误。部分重音可能出现了元音弱化。'
  },
  {
    score: [31, 45],
    text:
      '一些元音和辅音持续性的发音错误，语音至少2/3可以理解，但是可以听出一定的口音。出现了漏掉部分辅音的情况，且辅音顺序可能出现了简化。单词重音放置不正确或不清晰。'
  },
  {
    score: [11, 30],
    text:
      '许多元音和辅音的发音都是错误的，导致发音听起来有很重的口音。其中约1/3的内容无法理解，出现了许多辅音失真或省略的情况。单词重音完全不正确，出现了添加/遗漏音节的现象。'
  },
  {
    score: [10, 10],
    text:
      '发音完全是另一种语言，大量的元音和辅音发音错误，音节遗漏或缺失。超过一半的内容无法理解，重音和非重音以非英语的方式呈现，音节数量出错。'
  }
]
const diAiFluencyDesc = [
  {
    score: [81, 90],
    text:
      '呈现出了流利的节奏和意群感，没有出现犹豫，重复，开头错误以及非母语方式的语音简化。'
  },
  {
    score: [66, 80],
    text:
      '呈现出不错的节奏和适当的意群感以及表达重点。出现了不超过一个犹豫，重复，开头错误，且无明显的非母语方式的语音简化。'
  },
  {
    score: [46, 65],
    text:
      '语速适当，但可能不稳定。出现了不止一次犹豫，但大多数内容是以连续的方式呈现出来的。出现了几次重复和开头错误，没有出现长停顿或断断续续发音。'
  },
  {
    score: [31, 45],
    text:
      '语音可能断断续续，超过六个单词的段落至少有一个平滑的三个连续单词发音，并且没出现两到三次犹豫，重复或者开头错误。可以出现一个长的停顿。'
  },
  {
    score: [11, 30],
    text:
      '语音呈现不规则的节奏和意群，意群感非常糟糕，发音断断续续，多次犹豫，重复，开头错误，导致口语呈现出明显的不均匀或不连续，可能出现一到两个长停顿，且句子层面单词强调不佳。'
  },
  {
    score: [10, 10],
    text:
      '语音听起来缓慢且吃力，没有明显的意群，多次犹豫，停顿，开头错误以及非常明显的错误的语音简化。大多数单词是独立出现的，没有出现连读，出现不止一个长停顿。'
  }
]

const rlAiPronDesc = [
  {
    score: [81, 90],
    text:
      '所有元音和辅音发音准确，非常接近于英语母语使用者的发音方式。同时正确使用了同化音以及弱化音来做到正确的连读。所有单词重音以及句子重音皆为正确的位置。解释：发音准确，无明显口音，同化音和弱化音使用准确，所有重音准确。'
  },
  {
    score: [66, 80],
    text:
      '元音和辅音发音清晰，所有单词清晰易懂，但是出现了少数不影响清晰度的元音或辅音发音不正确的情况，所有常用词的重音位置无误，且句子重音合理。'
  },
  {
    score: [46, 65],
    text:
      '大多数元音辅音发音正常，出现了一些导致单词清晰度下降的错误，或者一些辅音轻微失真，遗漏或发音错误。部分重音可能出现了元音弱化。'
  },
  {
    score: [31, 45],
    text:
      '一些元音和辅音持续性的发音错误，语音至少2/3可以理解，但是可以听出一定的口音。出现了漏掉部分辅音的情况，且辅音顺序可能出现了简化。单词重音放置不正确或不清晰。'
  },
  {
    score: [11, 30],
    text:
      '许多元音和辅音的发音都是错误的，导致发音听起来有很重的口音。其中约1/3的内容无法理解，出现了许多辅音失真或省略的情况。单词重音完全不正确，出现了添加/遗漏音节的现象。'
  },
  {
    score: [10, 10],
    text:
      '发音完全是另一种语言，大量的元音和辅音发音错误，音节遗漏或缺失。超过一半的内容无法理解，重音和非重音以非英语的方式呈现，音节数量出错。'
  }
]
const rlAiFluencyDesc = [
  {
    score: [81, 90],
    text:
      '呈现出了流利的节奏和意群感，没有出现犹豫，重复，开头错误以及非母语方式的语音简化。'
  },
  {
    score: [66, 80],
    text:
      '呈现出不错的节奏和适当的意群感以及表达重点。出现了不超过一个犹豫，重复，开头错误，且无明显的非母语方式的语音简化。'
  },
  {
    score: [46, 65],
    text:
      '语速适当，但可能不稳定。出现了不止一次犹豫，但大多数内容是以连续的方式呈现出来的。出现了几次重复和开头错误，没有出现长停顿或断断续续发音。'
  },
  {
    score: [31, 45],
    text:
      '语音可能断断续续，超过六个单词的段落至少有一个平滑的三个连续单词发音，并且没出现两到三次犹豫，重复或者开头错误。可以出现一个长的停顿。'
  },
  {
    score: [11, 30],
    text:
      '语音呈现不规则的节奏和意群，意群感非常糟糕，发音断断续续，多次犹豫，重复，开头错误，导致口语呈现出明显的不均匀或不连续，可能出现一到两个长停顿，且句子层面单词强调不佳。'
  },
  {
    score: [10, 10],
    text:
      '语音听起来缓慢且吃力，没有明显的意群，多次犹豫，停顿，开头错误以及非常明显的错误的语音简化。大多数单词是独立出现的，没有出现连读，出现不止一个长停顿。'
  }
]

export const getPronText = (score, type) => {
  const map = {
    RA: aiPronDesc,
    RS: rsAiPronDesc,
    DI: diAiPronDesc,
    RL: rlAiPronDesc
  }
  const item =
    (map[type] || []).find(
      (item) => score >= item.score[0] && score <= item.score[1]
    ) || {}
  return item.text || ''
}

export const getFluencyText = (score, type) => {
  const map = {
    RA: aiFluencyDesc,
    RS: rsFluencyDesc,
    DI: diAiFluencyDesc,
    RL: rlAiFluencyDesc
  }
  const item =
    (map[type] || []).find(
      (item) => score >= item.score[0] && score <= item.score[1]
    ) || {}
  return item.text || ''
}

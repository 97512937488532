<template>
  <div class="transfer-select" :class="{ disabled: answerDetail }">
    <div class="list transfer-select-left">
      <h3>Source</h3>
      <draggable
        class="left-list"
        :element="'ul'"
        :list="leftList"
        :group="{ name: 'people', pull: 'clone', put: true }"
        @end="endLeft"
        @change="logleft"
        :chosenClass="'active'"
      >
        <li
          @click="selectLeft(item.index)"
          v-for="(item, index) of leftList"
          class="left-item"
          :class="{
            active: leftSelected.includes(item.index),
            disabled: isDisable(item.index)
          }"
          :key="index"
        >
          <span class="index">{{ item.index + 1 }}</span
          >{{ item.text }}
        </li>
      </draggable>
    </div>
    <div class="opt opt-center">
      <i
        class="f-icon icon-left"
        :class="{ active: rightSelected.length > 0 }"
        @click="addLeft"
      />
      <i
        class="f-icon icon-Right"
        :class="{ active: leftSelected.length > 0 }"
        @click="addRight"
      />
    </div>
    <div class="list transfer-select-left">
      <h3>Target</h3>
      <draggable
        class="right-list"
        :element="'ul'"
        :list="localList"
        group="people"
        :chosenClass="'active'"
        @change="logright"
      >
        <li
          @click="selectRight(item.index)"
          v-for="(item, index) of localList"
          :class="[
            { active: rightSelected.includes(item.index) },
            getColor(item, index)
          ]"
          :key="index"
        >
          <span class="index">{{ item.index + 1 }}</span
          >{{ item.text }}
        </li>
      </draggable>
    </div>
    <div class="opt opt-right">
      <i
        class="f-icon icon-top1"
        :class="{ active: showTopBtn }"
        @click="sort(-1, showTopBtn)"
      />
      <i
        class="f-icon icon-bottom"
        :class="{ active: showBottomBtn }"
        @click="sort(1, showBottomBtn)"
      />
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { cloneDeep } from 'lodash'

export default {
  components: {
    draggable
  },
  props: {
    list: Array,
    saveDragList: Array,
    answerDetail: Object,
    sortList: Array
  },
  data() {
    return {
      leftSelected: [],
      rightSelected: [],
      localList: [],
      leftList: [],
      selectDraggableIndex: -1
    }
  },
  watch: {
    selectList(val) {
      this.$emit('update:sortList', val)
    }
  },
  computed: {
    selectList() {
      return this.localList.map((item) => item.index)
    },
    showTopBtn() {
      return (
        this.rightSelected.length === 1 &&
        this.selectList.length > 1 &&
        this.getIndex(this.rightSelected[0])
      )
    },
    showBottomBtn() {
      return (
        this.rightSelected.length === 1 &&
        this.selectList.length > 1 &&
        this.getIndex(this.rightSelected[0]) + 1 < this.selectList.length
      )
    }
  },
  created() {
    this.leftList = cloneDeep(this.list)
  },
  methods: {
    addList(list, index) {
      if (list.includes(index)) {
        return list.filter((item) => item !== index)
      } else {
        return [...list, index]
      }
    },
    selectLeft(index) {
      this.leftSelected = this.addList(this.leftSelected, index)
    },
    selectRight(index) {
      this.rightSelected = this.addList(this.rightSelected, index)
    },
    endLeft(w) {
      this.selectDraggableIndex = -1
    },
    logleft(evt) {
      if (evt.moved) {
        this.leftSelected = [evt.moved.element.index]
      }
      if (evt.moved) {
        this.leftList = cloneDeep(this.list)
      }
      window.console.log('logleft', evt)
    },
    logright(evt) {
      if (evt.added) {
        this.rightSelected = [evt.added.element.index]
      }
      if (evt.moved) {
        this.rightSelected = [evt.moved.element.index]
      }
      if (evt.removed) {
        this.leftList = cloneDeep(this.list)
        this.leftSelected = [evt.removed.element.index]
      }
      window.console.log('logright', evt)
    },
    isDisable(index) {
      return this.selectList.includes(index)
    },
    getIndex(index) {
      return this.selectList.findIndex((item) => item === index)
    },
    addRight() {
      if (this.leftSelected.length === 0) {
        return
      }
      const itemList = this.leftSelected.map((item) => this.leftList[item])
      this.localList.push(...itemList)
      this.rightSelected = this.leftSelected
      this.leftSelected = []
    },
    addLeft() {
      if (this.rightSelected.length === 0) {
        return
      }
      this.localList = this.localList.filter(
        (item) => !this.rightSelected.includes(item.index)
      )
      this.leftSelected = this.rightSelected
      this.rightSelected = []
    },
    sort(type, falg) {
      if (this.rightSelected.length === 0 || !falg) {
        return
      }
      this.rightSelected.forEach((item) => {
        const index = this.getIndex(item)
        const itemValue = this.localList[index]
        this.localList.splice(index, 1)
        this.localList.splice(index + type, 0, itemValue)
      })
    },
    getColor(item, index) {
      const list = this.answerDetail && this.answerDetail.question_answer
      if (!list) {
        return ''
      }
      if (item.randomIndex + 1 === list[index]) {
        return 'green'
      } else {
        return 'red'
      }
    }
  }
}
</script>

<style lang="less">
.transfer-select {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
  }
  h3 {
    position: absolute;
    width: 100%;
    top: -40px;
    margin: 10px 0;
    text-align: center;
  }
  .list {
    position: relative;
    border: 1px solid rgba(188, 188, 203, 1);
    .left-list,
    .right-list {
      padding: 3px 0;
      width: 364px;
      &.right-list {
        height: 100%;
        box-sizing: border-box;
      }
      li {
        position: relative;
        margin: 10px 16px;
        border: 1px solid #bcbccb;
        border-radius: 20px;
        background: #fff;
        cursor: pointer;
        line-height: 18px;
        padding: 9px 20px 9px 30px;
        font-size: 14px;
        &.active {
          padding: 8px 19px 8px 29px;
          border: 2px solid #3b86ff;
          .index {
            left: -15px;
            color: #3b86ff;
            border-color: #3b86ff;
          }
        }
        &.red {
          color: #ff1a2e;
        }
        &.green {
          color: #36c626;
        }
        &.disabled {
          visibility: hidden;
        }
        .index {
          position: absolute;
          width: 26px;
          height: 26px;
          line-height: 26px;
          text-align: center;
          color: #c7cade;
          border: 1px solid #d3d3dd;
          border-radius: 50%;
          background: #fff;
          left: -14px;
          top: 50%;
          margin-top: -13px;
        }
      }
    }
  }
  .opt {
    display: flex;
    align-items: center;
    i {
      width: 24px;
      height: 24px;
      color: #fff;
      background: rgba(199, 202, 222, 1);
      border-radius: 50%;
      text-align: center;
      line-height: 24px;
      &.active {
        background: #3b86ff;
      }
    }
    &.opt-center {
      i {
        margin: 0 5px;
        font-size: 12px;
      }
      .icon-left {
        margin-left: 16px;
      }
      .icon-Right {
        margin-right: 16px;
      }
    }
    &.opt-right {
      flex-direction: column;
      justify-content: center;
      i {
        margin: 5px 0 5px 16px;
      }
    }
  }
}
</style>

import { INTRODUCE_INFO } from '@/utils/introduce-list'
import VueVideo from "@/components/vue-video.vue";

export default {
  components: {
    VueVideo
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    id: null,
  },
  data() {
    return {
      localVisible: this.visible,
      activeName: 'video',
      showDialog: true,
    }
  },
  watch: {
    showDialog(val) {
      this.localVisible = val
    }
  },
  computed: {
    info() {
      return INTRODUCE_INFO[this.id]
    },
    i18n() {
      return this.$t('messages')
    },
  },
  methods: {
    handleShowDialog(flag) {
      this.showDialog = flag
    },
    handleClose(){
      this.$emit('closeDialog')
		}
  }
}
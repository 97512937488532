import Everyone from '@/components/ExercisesTabs/components/everyone/everyone.vue'
import Teacher from '@/components/ExercisesTabs/components/teacher/teacher.vue'
import Answer from '@/components/ExercisesTabs/components/answer/answer.vue'
import AskQuestions from '@/components/ExercisesTabs/components/ask-questions/ask-questions.vue'
import Job from './components/job/index'
import Me from './components/me'
import i18n from '@/i18n/i18n'

import {
  apiGreCommentList,
  apiUserAskList,
  apiQuestionCommentList,
  apiQuestionTryList
} from '@/api/api.js'

import { smartNumStr } from '@/utils/num'

const TABS = (everyone, forum, teacher, isJobType) => {
  if (isJobType) {
    return [
      {
        key: 'Job',
        name: i18n.locale === 'ch' ? '练习记录' : 'Practice record',
        count: smartNumStr(0)
      }
    ]
  }

  return [
    {
      key: 'Everyone',
      name: i18n.locale === 'ch' ? '大家' : 'All',
      count: smartNumStr(everyone)
    },
    {
      key: 'Me',
      name: i18n.locale === 'ch' ? `我的` : 'Me',
      count: smartNumStr(forum)
    }
  ]
    .concat(
      i18n.locale === 'en'
        ? []
        : [
            {
              key: 'Teacher',
              name: i18n.locale === 'ch' ? `老师` : 'Teacher',
              count: smartNumStr(teacher)
            }
          ]
    )
    .concat([
      {
        key: 'Answer',
        name: i18n.locale === 'ch' ? '答案解析' : 'Reference'
      }
    ])
}

export default {
  components: {
    Everyone,
    Me,
    Teacher,
    Answer,
    AskQuestions,
    Job
  },
  props: {
    id: {
      type: [Number, String]
    },
    isPractice: {
      type: Boolean
    },
    detail: {
      type: Object,
      default: () => {}
    },
    typeInfo: {
      type: Object,
      default: () => {}
    },
    greId: {
      type: [Number, String]
    },
    isJobType: {
      type: Boolean
    }
  },
  data() {
    return {
      activeName: '',
      teacherTotal: 0,
      everyoneTotal: 0,
      meTotal: 0
    }
  },
  computed: {
    tabsList() {
      return TABS(
        this.everyoneTotal,
        this.meTotal,
        this.teacherTotal,
        this.isJobType
      )
    }
  },
  watch: {
    tabsList() {
      if (!this.tabsList.find((m) => m.key === this.activeName)) {
        this.activeName = this.tabsList[0].key
      }
    }
  },
  created() {
    this.fetchCount()
  },
  methods: {
    fetchCount() {
      if (this.isPractice) {
        apiUserAskList({
          question_id: this.id,
          page_size: 10,
          page: 1
        }).then((res) => {
          if (res.code != 200) {
            return
          }
          this.teacherTotal = res.data.total
        })

        this.everyoneTotal = 0
        apiQuestionTryList({
          question_id: this.id,
          page_size: 1,
          page: 1,
          type: 2
        }).then((res) => {
          const { all_try_cnt, my_try_cnt } = res.data
          this.everyoneTotal += all_try_cnt || 0
          this.meTotal = my_try_cnt || 0
        })
        apiQuestionCommentList({
          question_id: this.id,
          page_size: 1,
          page: 1
        }).then((res) => {
          this.everyoneTotal += res.data.total || 0
        })
      } else {
        apiGreCommentList({
          gre_id: this.id,
          page_size: 10,
          page: 1
        }).then((res) => {
          if (res.code != 200) {
            return
          }
          this.commentTotal = res.data.total
        })
      }
    },
    updateTotal(type, total) {
      this[type] = total
    },
    refreshData() {
      this.activeName = ''
      this.$nextTick(() => {
        this.activeName = 'Me'
        this.fetchCount()
      })
    }
  }
}

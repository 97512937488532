import TranslateWord from '@/components/TranslateWord'
import { wordSpaceCalc } from '@/utils/parctice'

export default {
  components: {
    TranslateWord
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    detail: {
      type: Object,
      default: () => {}
    },
    typeInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      list: [
        {
          time: '00:00',
          value: 'It seems to me that architecture'
        },
        {
          time: '00:11',
          value: 'It seems to me that architecture'
        },
        {
          time: '00:20',
          value: 'It seems to me that architecture'
        }
      ]
    }
  },
  methods: {
    close() {
      this.$emit('closeDialog')
    },
    wordSpace(greRecorder, item, index) {
      return wordSpaceCalc(greRecorder, item, index)
    }
  }
}

<template>
  <div class="exercises-tabs-item exercises-tabs-forum">
    <div class="forum-header">
      <img :src="userInfo.headimgurl" />
      <div class="isVip" v-if="userInfo.vip && userInfo.vip.code != 0">
        <img :src="require('@/assets/vip-icon.png')" alt />
      </div>
      <el-input
        type="textarea"
        :rows="3"
        v-model="input"
        :placeholder="i18n.Pra.text108"
        class="forum-input"
      ></el-input>
      <el-button
        class="sbumit-btn"
        type="primary"
        size="mini"
        @click="commentAdd"
        ><div>{{ i18n.Pra.publish }}</div>
        <div>{{ i18n.Pra.discuss }}</div></el-button
      >
    </div>
    <div v-loading="loading && page === 1">
      <div class="infinite-list-wrapper" v-if="list.length">
        <ul class="forum-list">
          <li
            class="forum-list-item"
            v-for="(item, index) of list"
            :key="item.id"
          >
            <img class="forum-list-user" :src="item.avatar" />
            <div>
              <div>
                <span class="name">{{ item.name }}</span>
                <span class="text">{{ item.user_goal }}</span>
              </div>
              <div class="comment">
                <span v-if="item.is_status === 1">
                  <pre>{{ item.content }}</pre></span
                ><span v-else class="del-tips">{{ i18n.Pra.text109 }}</span>
              </div>
              <div class="bottom">
                <span class="time">{{ item.time }}</span>
                <span class="favour"
                  ><i
                    class="f-icon"
                    @click="handleLike(item, index)"
                    :class="[item.liked ? 'icon-likes' : 'icon-like']"
                  />{{ item.likeNum }}</span
                >
                <span class="reply" @click="reply(item, index)">{{
                  i18n.Pra.reply
                }}</span>
                <span
                  v-if="item.can_del"
                  class="f-icon icon-del del-btn"
                  @click="delCommentReply(item)"
                />
              </div>
            </div>
            <ul class="reply-list">
              <li
                class="reply-list-item"
                v-for="(comment, commentIndex) of item.comments"
                :key="comment.id"
              >
                <img
                  :src="comment.people_avatar_1 || comment.people_one_avatar"
                />
                <div>
                  <div class="header">
                    <span class="name">{{ comment.peopleOne }}</span>
                    <span class="text">{{ comment.people_one_goal }}</span>
                    <span class="comment">
                      <pre>{{ comment.say }}</pre>
                    </span>
                  </div>
                  <div class="bottom">
                    <span class="time">{{ comment.add_time }}</span>
                    <span class="favour"
                      ><i
                        class="f-icon"
                        @click="
                          handleLike(comment, index, commentIndex, 'comments')
                        "
                        :class="[comment.is_like ? 'icon-likes' : 'icon-like']"
                      />{{ comment.like_cnt }}</span
                    >
                    <span
                      class="reply"
                      @click="reply(comment, index, comment.peopleOne)"
                      >{{ i18n.Pra.reply }}</span
                    >
                    <span
                      v-if="comment.can_del"
                      class="f-icon icon-del del-btn"
                      @click="delReply(comment)"
                    />
                  </div>
                </div>
              </li>
              <template v-if="item.showMore">
                <li
                  class="reply-list-item"
                  v-for="(more, commentIndex) of item.moreComments"
                  :key="more.id"
                >
                  <img :src="more.people_avatar_1 || more.people_one_avatar" />
                  <div>
                    <div class="header">
                      <span class="name">{{ more.peopleOne }}</span>
                      <span class="text">{{ more.people_one_goal }}</span>
                      <span class="comment">{{ more.say }}</span>
                    </div>
                    <div class="bottom">
                      <span class="time">{{ more.add_time }}</span>
                      <span class="favour"
                        ><i
                          class="f-icon"
                          @click="
                            handleLike(
                              more,
                              index,
                              commentIndex,
                              'moreComments'
                            )
                          "
                          :class="[more.is_like ? 'icon-likes' : 'icon-like']"
                        />{{ more.like_cnt }}</span
                      >
                      <span
                        class="reply"
                        @click="reply(more, index, more.peopleOne)"
                        >{{ i18n.Pra.reply }}</span
                      >
                      <span
                        v-if="more.can_del"
                        class="f-icon icon-del del-btn"
                        @click="delReply(more)"
                      />
                    </div>
                  </div>
                </li>
              </template>
            </ul>
            <div
              class="reply-total"
              v-if="
                item.moreComments && item.moreComments.length && !item.showMore
              "
            >
              {{ i18n.Pra.common }}{{ item.commentsTotal
              }}{{ i18n.Pra.reply }},<el-button
                type="text"
                class="reply-view"
                @click="showMoreComments(index)"
                >{{ i18n.Forum.Click }}</el-button
              >
            </div>
            <div
              class="reply-input"
              v-if="replyItem && item.index === replyItem.index"
            >
              <img :src="userInfo.headimgurl" />
              <div class="isVip" v-if="userInfo.vip && userInfo.vip.code != 0">
                <img :src="require('@/assets/vip-icon.png')" alt />
              </div>
              <el-input
                type="textarea"
                :rows="3"
                v-model="content"
                :placeholder="`${i18n.Pra.reply} @${replyItem.name}：`"
                class="forum-input"
              ></el-input>
              <el-button
                class="sbumit-btn"
                type="primary"
                size="mini"
                @click="replyAdd"
                ><div>{{ i18n.Pra.publish }}</div>
                <div>{{ i18n.Pra.discuss }}</div></el-button
              >
            </div>
          </li>
        </ul>

        <More
          :page="page"
          :pageSize="pageSize"
          :loading="loading"
          :totalPage="Math.ceil(total / pageSize)"
          @load="load"
        />
      </div>
    </div>
    <Empty v-if="!list.length && !loading" isTabs />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Empty from '@/components/ExercisesEmpty.vue'
import {
  apiDelQuestionReplyComment,
  apiDelGreReplyComment,
  apiGreCommentList,
  apiQuestionCommentList,
  apiCommentAdd,
  apiCommentLike,
  apiReplayAdd,
  apiGreCommentAdd,
  apiDelQuestionComment,
  apiDelGreComment,
} from '@/api/api.js'
import Audio from '@/components/Audio/Audio.vue'
import More from '@/components/more'
import i18n from '@/i18n/i18n'

const MAX_NUM = 4

export default {
  components: {
    Empty,
    Audio,
    More,
  },
  props: {
    id: {
      type: [Number, String],
    },
    isPractice: {
      type: Boolean,
    },
    onTotal: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      input: '',
      page: 1,
      pageSize: 10,
      loading: false,
      list: [],
      replyItem: null,
      total: 0,
      likeLoading: false,
      commentLoading: false,
      replyLoading: false,
      content: '',
    }
  },
  created() {
    this.fetchList(1)
  },
  computed: {
    ...mapState('app', {
      userInfo: (state) => state.userInfo,
    }),
    disabled() {
      return this.loading
    },
    i18n() {
      return this.$t('messages')
    },
  },
  methods: {
    load(page) {
      this.fetchList(page)
    },
    delReply(item) {
      this.$confirm(
        `<div><div style="position: absolute; top: -26px; color: #A4AFB7;">${
          i18n.locale === 'ch' ? '删除评论' : 'Delete comment'
        } </div><p style="color: #1F1F40; padding-top: 10px; border-top: 1px solid #EBEBF2;">${
          i18n.locale === 'ch'
            ? '确定要删除这条评论吗？'
            : 'Are you sure you want to delete this comment?'
        } </p></div>`,
        '',
        {
          customClass: 'del-reply-dialog',
          dangerouslyUseHTMLString: true,
          confirmButtonText: i18n.locale === 'ch' ? '确定' : 'Determine',
          cancelButtonText: i18n.locale === 'ch' ? '取消' : 'Cancel',
          callback: (action) => {
            this.showPayDialog = false
            if (action === 'confirm') {
              if (this.isPractice) {
                apiDelQuestionReplyComment({
                  id: item.id,
                }).then((res) => {
                  if (res.code != 200) {
                    this.$layer.msg(res.msg)
                    return
                  }
                  this.$layer.msg(
                    i18n.locale === 'ch' ? '删除成功' : 'Delete succeeded'
                  )
                  this.fetchList(1)
                })
              } else {
                apiDelGreReplyComment({
                  id: item.id,
                }).then((res) => {
                  if (res.code != 200) {
                    this.$layer.msg(res.msg)
                    return
                  }
                  this.$layer.msg(
                    i18n.locale === 'ch' ? '删除成功' : 'Delete succeeded'
                  )
                  this.fetchList(1)
                })
              }
            }
          },
        }
      )
    },
    delCommentReply(item) {
      this.$confirm(
        `<div><div style="position: absolute; top: -26px; color: #A4AFB7;"> ${
          i18n.locale === 'ch' ? '删除评论' : 'Delete comment'
        }</div><p style="color: #1F1F40; padding-top: 10px; border-top: 1px solid #EBEBF2;"${
          i18n.locale === 'ch'
            ? '确定要删除这条评论吗？'
            : 'Are you sure you want to delete this comment?'
        }</p></div>`,
        '',
        {
          customClass: 'del-reply-dialog',
          dangerouslyUseHTMLString: true,
          confirmButtonText: i18n.locale === 'ch' ? '确定' : 'Determine',
          cancelButtonText: i18n.locale === 'ch' ? '取消' : 'Cancel',
          callback: (action) => {
            this.showPayDialog = false
            if (action === 'confirm') {
              if (this.isPractice) {
                apiDelQuestionComment({
                  id: item.id,
                }).then((res) => {
                  if (res.code != 200) {
                    this.$layer.msg(res.msg)
                    return
                  }
                  this.$layer.msg(
                    i18n.locale === 'ch' ? '删除成功' : 'Delete succeeded'
                  )
                  this.fetchList(1)
                })
              } else {
                apiDelGreComment({
                  id: item.id,
                }).then((res) => {
                  if (res.code != 200) {
                    this.$layer.msg(res.msg)
                    return
                  }
                  this.$layer.msg(
                    i18n.locale === 'ch' ? '删除成功' : 'Delete succeeded'
                  )
                  this.fetchList(1)
                })
              }
            }
          },
        }
      )
    },
    commentAdd() {
      if (!this.input) {
        this.$layer.msg(
          `"<div class='error-icon'></div><div>${
            i18n.locale === 'ch' ? '你还没有评论' : "You haven't commented yet"
          } </div>"`
        )
        return
      }
      if (this.commentLoading) {
        return
      }
      this.commentLoading = true
      if (this.isPractice) {
        apiCommentAdd({
          contents: this.input,
          id: this.id,
        })
          .then((res) => {
            if (res.code != 200) {
              return
            }
            this.$message.success(
              i18n.locale === 'ch'
                ? '发布评论成功!'
                : 'Comments posted successfully!'
            )
            this.input = ''
            this.fetchList(1)
          })
          .finally(() => {
            this.commentLoading = false
          })
      } else {
        apiGreCommentAdd({
          contents: this.input,
          id: this.id,
        })
          .then((res) => {
            if (res.code != 200) {
              return
            }
            this.$message.success(
              i18n.locale === 'ch'
                ? '发布评论成功!'
                : 'Comments posted successfully!'
            )

            this.input = ''
            this.fetchList(1)
          })
          .finally(() => {
            this.commentLoading = false
          })
      }
    },
    replyAdd() {
      if (!this.content) {
        this.$layer.msg(
          `"<div class='error-icon'></div><div>${
            i18n.locale === 'ch' ? '你还没有评论' : "You haven't commented yet"
          }</div>"`
        )
        return
      }
      if (this.replyLoading) {
        return
      }
      this.replyLoading = true
      apiReplayAdd({
        contents: this.content,
        replyid: this.replyItem.commentid ? this.replyItem.id : null,
        commentid: this.replyItem.commentid || this.replyItem.id,
      })
        .then((res) => {
          if (res.code != 200) {
            return
          }
          this.$message.success(
            i18n.locale === 'ch'
              ? '发布评论成功!'
              : 'Comments posted successfully !'
          )
          this.fetchList(1)
          this.replyItem = null
          this.content = ''
        })
        .finally(() => {
          this.replyLoading = false
        })
    },
    reply(item, index, name) {
      this.replyItem = null
      this.content = ''
      this.replyItem = { index, name, ...item }
    },
    showMoreComments(index) {
      this.list[index].showMore = true
    },
    handleLike(item, index, subIndex, type) {
      if (this.likeLoading) {
        return
      }
      this.likeLoading = true
      const replayType = type ? 'replay' : 'comments'
      apiCommentLike({
        aid: item.id,
        type: replayType,
      })
        .then((res) => {
          if (res.code != 200) {
            return
          }
          this.$layer.msg(res.msg)
          if (type) {
            const add = item.is_like ? -1 : 1
            this.list[index][type][subIndex].is_like = add > 0 ? 1 : 0
            this.list[index][type][subIndex].like_cnt += add
          } else {
            const add = item.liked ? -1 : 1
            this.list[index].liked = add > 0 ? 1 : 0
            this.list[index].likeNum += add
          }
        })
        .finally(() => {
          this.likeLoading = false
        })
    },
    formatData(data) {
      const list = data.list.map((item, index) => {
        let addList = {}
        if (item.comments && item.comments.length) {
          addList = {
            commentsTotal: item.comments.length,
            comments: item.comments.slice(0, MAX_NUM),
            moreComments: item.comments.slice(MAX_NUM),
            showMore: false,
          }
        }
        return {
          ...item,
          comments: [],
          ...addList,
          index: index + (this.page - 1) * this.pageSize,
        }
      })
      this.list = this.page === 1 ? list : [...this.list, ...list]
      this.total = data.total
      this.$emit('update-total', 'commentTotal', data.total)
    },
    fetchList(page) {
      if (this.loading) {
        return
      }
      this.loading = true
      if (page) {
        this.page = page
      }
      if (this.isPractice) {
        apiQuestionCommentList({
          question_id: this.id,
          page_size: this.pageSize,
          page: this.page,
        })
          .then((res) => {
            if (res.code != 200) {
              return
            }
            this.formatData(res.data)

            this.onTotal(res.data.total)
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        apiGreCommentList({
          gre_id: this.id,
          page_size: this.pageSize,
          page: this.page,
        })
          .then((res) => {
            if (res.code != 200) {
              return
            }
            this.formatData(res.data)

            this.onTotal(res.data.total)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
  },
}
</script>
<style lang="less">
.exercises-tabs-forum {
  margin-top: 15px;
  padding: 0 20px;
  .del-tips {
    color: #a4afb7;
  }
  .infinite-list-wrapper {
    /*border-bottom: 1px solid #ebebf2;*/
  }
  .name {
    flex-shrink: 0;
  }
  .el-button--primary {
    line-height: 17px;
    padding: 3px 8px;
  }
  .forum-header {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
    position: relative;
    .isVip {
      position: absolute;
      left: 24px;
      top: 28px;
      > img {
        width: 17px;
        height: 17px;
      }
    }
  }
  .reply-input {
    display: flex;
    align-items: center;
    margin: 10px;
    position: relative;
    .isVip {
      position: absolute;
      left: 24px;
      top: 28px;
      > img {
        width: 17px;
        height: 17px;
      }
    }
  }
  img {
    flex-shrink: 0;
  }
  .forum-input {
    margin-right: 7px;
    /deep/ .el-textarea__inner {
      height: 62px;
    }
  }
  .sbumit-btn {
    height: 62px;
    width: 84px;
    font-size: 16px;
    line-height: 20px;
  }
  .forum-list {
    color: #a4afb7;
    font-size: 12px;
    .forum-list-item {
      position: relative;
      padding: 13px 0 9px;
      margin-left: 54px;
      border-top: 1px solid #ebebf2;
      &:last-child {
        border-bottom: 1px solid #ebebf2;
      }
    }
    .forum-list-user {
      position: absolute;
      left: -54px;
      top: 13px;
    }
    .comment {
      margin: 7px 0;
      color: #1f1f40;
      font-size: 14px;
      pre {
        color: #1f1f40;
        font-size: 14px;
      }
    }
    .reply-list {
      .reply-list-item {
        display: flex;
        align-content: flex-start;
        margin-top: 15px;
      }
      img {
        width: 28px;
        height: 28px;
      }
      .header {
        display: flex;
        line-height: 20px;
        margin-bottom: 10px;
      }
      .comment {
        margin: 0 12px 0;
      }
    }
    .del-btn {
      margin-left: 23px;
      vertical-align: bottom;
      cursor: pointer;
      &:hover {
        color: #3b86ff;
      }
    }
    .reply-total {
      .reply-view {
        font-size: 12px;
      }
    }
  }
}
.del-reply-dialog {
  .el-message-box__header {
    padding-top: 30px;
  }
  .el-message-box__content {
    padding: 0 15px;
  }
  .el-button:not(.el-button--primary) {
    color: #7c8084;
    padding: 4px 18px;
    border-radius: 5px;
    .f-icon {
      color: #7c8084;
    }
    &.active,
    &:hover {
      color: #3b86ff;
      background: #ecf3ff;
      border-color: #3b86ff;
      .f-icon {
        color: #3b86ff;
      }
    }
    &:focus {
      &.active,
      &:hover {
        color: #3b86ff;
        background: #ecf3ff;
        border-color: #3b86ff;
        .f-icon {
          color: #3b86ff;
        }
      }
      color: #7c8084;
      background: #fff;
      border: 1px solid #dcdfe6;
    }
  }
}
</style>

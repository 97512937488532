export const TAG_COLOR_MAP = {
  详解: {
    bgColor: '#C1E6FE',
    color: '#345D7F'
  },
  机经题: {
    bgColor: '#C1E6FE',
    color: '#345D7F'
  },
  练习题: {
    bgColor: '#AAF9E6',
    color: '#006D5D'
  },
  超高频: {
    bgColor: '#FFD2DD',
    color: '#AD1625'
  },
  高频: {
    bgColor: '#FFD2DD',
    color: '#AD1625'
  },
  新增高频: {
    bgColor: '#FFD2DD',
    color: '#AD1625'
  },
  本月高频: {
    bgColor: '#FFD2DD',
    color: '#AD1625'
  },
  老题新考: {
    bgColor: '#FFF3DF',
    color: '#F86E21'
  },
  新题: {
    bgColor: '#DDF9B5',
    color: '#51773E'
  },
  真题: {
    bgColor: '#C1E6FE',
    color: '#345D7F'
  },
  模拟题: {
    bgColor: '#AAF9E6',
    color: '#006D5D'
  },
  原音频: {
    bgColor: '#c4ede3',
    color: '#58caaf'
  },
  近似音频: {
    bgColor: '#ffe0b2',
    color: '#ffa623'
  }
}

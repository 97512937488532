<template>
  <el-dialog
    :visible.sync="visible"
    :title="i18n.Pra.add"
    width="340px"
    class="collect-dialog"
    :close-on-click-modal="false"
  >
    <el-checkbox-group v-model="checkList">
      <ul class="list">
        <li v-for="item of categoryList" :key="item.id">
          <el-checkbox :label="item.id">{{ item.name }}</el-checkbox>
          <span>{{ item.cate_cnt }}</span>
        </li>
      </ul>
    </el-checkbox-group>

    <div class="add-group">
      <el-input
        v-if="categoryList.length < 6"
        maxlength="6"
        v-model="addIpt"
        :prefix-icon="activeAdd ? '' : 'iconfont icon-guanbi'"
        :placeholder="activeAdd ? i18n.Pra.text : i18n.Pra.text2"
        @focus="activeAdd = true"
        @blur="handleIptBlur"
      >
        <el-button
          v-if="activeAdd"
          slot="append"
          type="primary"
          @click="handleSaveGroup"
          style="
            height: 40px !important;
            background-color: #c9dbfd;
            color: #596dfa;
          "
        >
          {{ i18n.Pra.confirm }}
        </el-button>
      </el-input>
      <div class="line"></div>
    </div>

    <div class="btn">
      <el-button type="primary" @click="handleSave">
        {{ i18n.Pra.confirm }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  apiMyCollectCategory,
  apiAddCollect,
  apiWebDelCollect,
  apiWebCollect
} from '@/api/api'
import i18n from '@/i18n/i18n'

export default {
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    currentId: {
      type: Number,
      default: 0
    },
    isPractice: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false,
      categoryList: [],
      checkList: [],

      addIpt: '',
      activeAdd: false
    }
  },
  computed: {
    checkedIds() {
      return Object.keys(this.detail.collect_cate || []).map(Number)
    },
    i18n() {
      return this.$t('messages')
    }
  },
  mounted() {
    this.fetchCategory()
  },
  methods: {
    open() {
      this.visible = true

      this.checkList = [].concat(this.checkedIds)
    },

    async fetchCategory() {
      const { data: list } = await apiMyCollectCategory()
      this.categoryList = list
    },

    handleIptBlur() {
      setTimeout(() => {
        this.activeAdd = false
      }, 500)
    },
    async handleSaveGroup() {
      const { msg } = await apiAddCollect({
        name: this.addIpt
      })
      this.$message.success(msg)
      this.addIpt = ''

      this.fetchCategory()
    },

    delCollect(cateId) {
      return apiWebDelCollect({
        aid: this.currentId,
        paramerid: this.currentId,
        cate_id: cateId,
        type: this.isPractice ? 'practice' : 'gremat'
      })
    },
    addCollect(cateId) {
      return apiWebCollect({
        aid: this.currentId,
        paramerid: this.currentId,
        cate_id: cateId,
        type: this.isPractice ? 'practice' : 'gremat'
      })
    },
    async handleSave() {
      await Promise.all(
        this.checkedIds
          .filter((id) => !this.checkList.includes(id))
          .map((id) => this.addCollect(id))
      )

      await Promise.all(
        this.checkList
          .filter((id) => !this.checkedIds.includes(id))
          .map((id) => this.addCollect(id))
      )

      this.$message.success(
        i18n.locale === 'ch' ? '收藏保存成功' : 'Collection saved successfully'
      )

      this.visible = false

      this.$emit('onRefresh')
    }
  }
}
</script>

<style scoped lang="scss">
.collect-dialog {
  .list {
    > li {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      > span {
        font-size: 14px;
      }
    }
  }
  .add-group {
    margin-top: 80px;
    .line {
      height: 1px;
      width: 100%;
      background-color: #ebebf2;
      margin-top: 16px;
    }
  }
  .btn {
    margin-top: 15px;
    text-align: right;
  }
}
</style>

import TabsBtn from '@/components/tabs/btn'
import List from './list'
import Forum from '../forum/forum'
import Practice from '../practice-performance/index'
import i18n from '@/i18n/i18n'

const TYPE_LIST = [
  { name: () => i18n.locale=='ch'?'练习':'Practice', value: 'exercises' },
  { name: ({ comment }) => `${i18n.locale=='ch'?'讨论':'Discuss'}(${comment})`, value: 'comment' }
]

export default {
  components: {
    TabsBtn,
    List,
    Forum,
    Practice
  },
  props: {
    id: {
      type: [Number, String]
    },
    isPractice: {
      type: Boolean
    },
    typeInfo: {
      type: Object,
      default: () => { }
    },
    detail: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      currType: TYPE_LIST[0].value,
      count: {
        done: 0,
        view: 0,
        all: 0,
        page: 0,
        comment: 0
      },
      readAndListenList: [
        'MCM',
        'MCS',
        'RO',
        'RFIB',
        'FIBW',
        'FIB',
        'HCS',
        'SMW',
        'HIW',
        'WFD'
      ]
    }
  },
  computed: {
    typeList() {
      return TYPE_LIST.map((item) => ({
        ...item,
        name: item.name(this.count)
      }))
    }
  },
  methods: {
    handleType({ value }) {
      this.currType = value
    },
    handleCommentTotal(count) {
      this.count = {
        ...this.count,
        comment: count
      }
    },
    handleExerciseTotal(count) {
      this.count = {
        ...this.count,
        ...count
      }
    }
  }
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{staticClass:"exercise-ai-content",attrs:{"visible":_vm.visible,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.i18n.Pra.text92))]),(_vm.contentStr)?_c('div',{staticClass:"block"},[_c('div',{staticClass:"block-title"},[_vm._v(_vm._s(_vm.i18n.Pra.text93))]),_c('div',{staticClass:"block-content"},[_vm._v(" "+_vm._s(_vm.contentStr)+" ")])]):_vm._e(),_c('div',{staticClass:"block-audio"},[_c('span',[_vm._v(_vm._s(_vm.i18n.Pra.text94))]),_c('AudioToolMini',{attrs:{"audioUrl":_vm.recorderUrl}})],1),_c('div',{staticClass:"block-ai-content"},[_c('div',{staticClass:"block-header"},[_c('div',{staticClass:"block-header-title"},[_c('span',[_vm._v("AI")]),_vm._v(_vm._s(_vm.i18n.Pra.text95)+" ")]),_c('div',{staticClass:"mark-tag"},[_c('div',{staticClass:"mark success"},[_vm._v(_vm._s(_vm.i18n.Pra.text96))]),_c('div',{staticClass:"mark fail"},[_vm._v(_vm._s(_vm.i18n.Pra.Misreading))])])]),_c('div',{staticClass:"block-ai-body"},[(
          _vm.recorderArticle &&
            _vm.recorderArticle.length === 1 &&
            !_vm.recorderArticle[0].text
        )?[_c('span',{staticClass:"ai-tips"},[_vm._v(_vm._s(_vm.i18n.Pra.text97))])]:_vm._l((_vm.recorderArticle),function(item,index){return _c('span',{key:`${item.text}_${index}`,class:item.isWrong && !['DI', 'RL'].includes(_vm.typeInfo.type)
              ? 'wrong'
              : ''},[_vm._v(_vm._s(item.text)+" ")])})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
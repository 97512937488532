<template>
  <div class="recorder-content">
    <h3 v-if="hasAudio">Audio Recorder</h3>
    <h3 v-else>Audio Recorder</h3>
    <div class="state">
      <h3>Current status:</h3>
      <div v-if="count && state === 'count' && canTimer">
        <span>Beginning in</span>
        <CountDown
          :count="count"
          :showSeconds="true"
          @endCountDown="endCountDown"
        ></CountDown>
        <span>seconds</span>
      </div>
      <p v-if="state === 'recording' || recording">Recording</p>
      <p v-if="state === 'end'">Completed</p>
    </div>
    <el-progress
      :percentage="percent"
      :color="'#3B86FF'"
      :show-text="false"
      :stroke-width="10"
    ></el-progress>
    <div class="recorder-btn" @click="record">
      <i class="f-icon" :class="recording ? 'icon-Erase' : 'icon-Microphone'" />
    </div>
    <audio :src="require('@/assets/ding.mp3')" ref="audio" />
    <AutoPlay
      style="background-color: #fff"
      openMsg="自动录音已打开"
      hideMsg="自动录音已关闭"
    />
  </div>
</template>
<script>
import CountDown from '@/components/CountDown/CountDown.vue'
import Recorder, { ENCODE_TYPE } from 'recorderx'
import AutoPlay from '@/components/AudioToolDefault/autoPlay'
// encodeWAV
export default {
  components: {
    CountDown,
    AutoPlay
  },
  props: {
    hasAudio: {
      type: Boolean,
      default: false
    },
    count: 0,
    canTimer: false,
    showTips: false,
    type: ''
  },
  data() {
    return {
      rc: 0,
      recording: false,
      time: 0,
      timeView: 0,
      timeCount: null,
      audioValue: null,
      state: 'count',
      supportRecorder: true
    }
  },
  computed: {
    percent() {
      const add = this.state === 'recording' ? 2 : 0
      return ((this.time + add) / (this.maxTime + add)) * 100
    },
    maxTime() {
      return this.type === 'RS' ? 15 : this.type === 'ASQ' ? 10 : 40
    }
  },
  created() {
    if (!navigator.mediaDevices.getUserMedia) {
      this.supportRecorder = false
      this.$layer.msg("<div class='error-icon'></div><div>当前不支持录音</div>")
      return
    }
  },
  methods: {
    onError() {
      this.$layer.msg(
        "<div class='error-icon'></div><div>您的麦克风权限未开启，开启后才能正常录音。</div>"
      )
    },
    canRecorder(onSuccess) {
      if (!this.supportRecorder) {
        return
      }
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((...argus) => onSuccess(argus))
        .catch((...argus) => this.onError(argus))
    },
    endCountDown() {
      this.canRecorder(this.palyTips)
    },
    palyTips() {
      if (this.showTips) {
        this.$refs.audio && this.$refs.audio.play()
        setTimeout(() => {
          this.startRecorder()
        }, 1000)
      } else {
        this.startRecorder()
      }
    },
    record() {
      this.$emit('record')
      if (this.recording) {
        this.pauseRecorder()
      } else {
        this.canRecorder(this.palyTips)
      }
    },
    // 开始录音
    startRecorder() {
      if (this.recording) {
        return
      }
      this.$emit('stertRecord')
      let _this = this
      _this.rc = new Recorder()
      this.start()
      _this.$nextTick(function() {
        _this.rc
          .start()
          .then(() => {
            console.log('start recording')
          })
          .catch((error) => {
            console.log('Recording failed.', error)
          })
        _this.recording = true
        _this.state = 'recording'
      })
    },
    // 暂停音频
    pauseRecorder() {
      if (this.time <= 1) {
        this.$layer.msg("<div class='error-icon'></div><div>录音时间过短</div>")
        this.stop()
        this.recording = false
        this.state = 'pause'
        return
      }
      this.rc.pause()
      this.recording = false
      this.state = 'end'
      // //  重置父组件音频播放start
      // this.audioValue = null;
      // this.sendMsg();
      // //  重置父组件音频播放end
      // setTimeout(() => {
      this.getRecorder()
      this.stop()
      // }, 100);
    },
    // 获得音频
    getRecorder() {
      var wav = this.rc.getRecord({
        encodeTo: ENCODE_TYPE.WAV
        // compressible: true
      })
      this.audioValue = wav
      this.sendMsg()
      // });
    },
    sendMsg() {
      //func: 是父组件指定的传数据绑定的函数，this.msg:子组件给父组件传递的数据
      this.$emit('func', this.audioValue)
    },
    // 计时
    timer() {
      if (this.time >= this.maxTime) {
        this.pauseRecorder()
        return
      }
      this.time += 1
      let min, sec
      min = sec = 0
      min = parseInt(this.time / 60)
      sec = parseInt(this.time % 60)
      if (sec < 10) {
        sec = '0' + sec
      }
      if (min < 10) {
        min = '0' + min
      }
      this.timeView = min + ':' + sec
    },
    start() {
      //开始计时
      this.timeCount = setInterval(this.timer, 1000)
    },

    stop() {
      //停止计时
      clearInterval(this.timeCount)
      this.time = 0
      this.timeView = '00:00'
    }
  }
}
</script>
<style scoped lang="less">
.recorder-content {
  position: relative;
  width: 298px;
  height: 157px;
  padding: 13px 20px;
  margin: 0 auto;
  text-align: center;
  color: #1f1f40;
  background: #e8f1ff;
  border: 2px solid #a4afb7;
  box-sizing: border-box;
  border-radius: 5px;
  .recorder-btn {
    width: 31px;
    height: 31px;
    line-height: 31px;
    background: #3b86ff;
    border-radius: 50%;
    color: #ffffff;
    text-align: center;
    margin: 15px auto 0;
  }
  .f-icon {
    font-size: 20px;
    &.icon-Erase {
      font-size: 14px;
    }
  }
  h3 {
    font-size: 14px;
  }
  .state {
    margin: 10px 0 5px;
    height: 40px;
    text-align: left;
    line-height: 20px;
  }
  /deep/.el-progress-bar__outer {
    background-color: #c7cade;
    border-radius: 2px;
  }
  /deep/.el-progress-bar__inner {
    border-radius: 2px;
  }
}
</style>

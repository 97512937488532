<template>
  <el-dialog
    :width="'786px'"
    :visible.sync="localVisible"
    :before-close="handleClose"
    class="exercises-introduce-advise"
  >
    <div class="title">Read aloud{{ i18n.Pra.text62 }}</div>
    <el-tabs v-model="activeName">
      <el-tab-pane :label="i18n.Pra.text63" :name="'video'">
        <VueVideo @showDialog="handleShowDialog" :src="videoUrl" />
      </el-tab-pane>
      <el-tab-pane :label="i18n.Pra.text64" :name="'text'" class="text-content">
        <div>
          <h3>{{ i18n.Pra.text65 }}</h3>
          <p>
            <br />
            {{ i18n.Pra.text66 }}
            <br />
            {{ i18n.Pra.text67 }}
            <br />
            {{ i18n.Pra.text68 }}
            <br />例：
            <span style="margin-left: 100px">{{ i18n.Pra.text53 }}</span>
            <span style="margin-left: 50px">{{ i18n.Pra.text53 }}</span>
            <span style="margin-left: 70px">{{ i18n.Pra.Ventilate }}</span>
            <span style="margin-left: 120px">{{ i18n.Pra.Ventilate }}</span>
            <br />
            <span>
              He lives with his wife ↘ on a farm ↘ in the village // and lives a
              happy life. //
            </span>
            <br />
            {{ i18n.Pra.text69 }}
          </p>
        </div>
        <div>
          <h3>{{ i18n.Pra.text70 }}</h3>
          <p>
            1.{{ i18n.Pra.text71 }} <br />例：<br />recent article shows <br />a
            number of<br />interesting experiments <br /><br />2.{{
              i18n.Pra.text72
            }}
            <br />例：A recent article shows ↘ a number of interesting
            experiments ↘ <br />3.{{ i18n.Pra.text73 }} <br />{{
              i18n.Pra.text74
            }}
            <br /><br />{{ i18n.Pra.text75 }}
          </p>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
import VueVideo from '@/components/vue-video.vue'

const videoUrl =
  'https://ptegofeifan.oss-accelerate.aliyuncs.com/go/admin_file_1613241813379962450885.mp4'

export default {
  components: {
    VueVideo,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localVisible: this.visible,
      activeName: 'video',
      showDialog: true,
      videoUrl,
    }
  },
  watch: {
    showDialog(val) {
      this.localVisible = val
    },
  },
  computed: {
    i18n() {
      return this.$t('messages')
    },
  },
  methods: {
    handleShowDialog(flag) {
      this.showDialog = flag
    },
    handleClose() {
      this.$emit('closeDialog')
    },
  },
}
</script>

<style lang="less">
.exercises-introduce-advise {
  .el-dialog__body {
    margin-top: -30px;
  }
  .el-dialog {
    border-radius: 10px;
    height: 550px;
    .el-dialog__title {
      display: none;
    }
  }
  .title {
    padding-bottom: 8px;
    color: #a4afb7;
    font-size: 14px;
    border-bottom: 1px solid #ebebf2;
  }
  video {
    width: 100%;
  }
  h3 {
    color: #1f1f40;
    font-size: 14px;
  }
  p {
    color: #a4afb7;
    padding: 0 0 15px;
  }
  .bar {
    margin-left: 30px;
  }
  .one,
  .three,
  .two {
    text-align: center;
  }
  .one,
  .three {
    width: 94px;
  }
  .three {
    width: 120px;
  }
  .two {
    width: 140px;
  }
  span {
    display: inline-block;
  }
  .text-content {
    height: 400px;
    overflow: auto;
  }
}
</style>

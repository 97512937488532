<template>
  <span class="component-submit-progress"> {{ text }}({{ progress }}%) </span>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    time: {
      type: Number, // 毫秒
      default: ''
    },
    delay: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      progress: 0
    }
  },
  mounted() {
    setTimeout(() => this.runTime(), this.delay)
    clearInterval(this.timeId)
  },
  beforeDestroy() {
    clearInterval(this.timeId)
  },
  methods: {
    runTime() {
      const runDelay = 100
      const onceStep = 100 / (this.time / runDelay)
      this.timeId = setInterval(() => {
        this.progress = Math.round(this.progress + onceStep)
        if (this.progress >= 99) {
          clearInterval(this.timeId)
          this.progress = 99
        }
      }, runDelay)
    },
    done() {
      clearInterval(this.timeId)
      this.progress = 100
    }
  }
}
</script>

<style scoped lang="scss">
.component-submit-progress {
}
</style>

import AskQuestions from '@/components/ExercisesTabs/components/ask-questions/ask-questions.vue'
import VideoDialog from '@/components/VideoDialog/VideoDialog.vue'
import Audio from '@/components/Audio/Audio.vue'
import Empty from '@/components/ExercisesEmpty.vue'
import More from '@/components/more'
import {
  apiWebGetDemonstration,
  apiUserAskList,
  apiUserAskView,
  apiUserInfo
} from '@/api/api.js'

export default {
  components: {
    AskQuestions,
    VideoDialog,
    Audio,
    Empty,
    More
  },
  props: {
    id: {
      type: [Number, String]
    },
    isPractice: {
      type: Boolean
    },
    detail: {
      type: Object,
      default: () => { }
    },
    typeInfo: {
      type: Object,
      default: () => { }
    },
    greId: {
      type: [Number, String]
    }
  },
  data() {
    return {
      showAskDialog: false,
      showVideoDialog: false,
      info: null,
      page: 1,
      pageSize: 10,
      loading: false,
      list: [],
      userInfo: {},
      currentId: null,
      showPayDialog: false
    }
  },
  computed: {
    hadTeacher() {
      return 'RA'.indexOf(this.typeInfo.type) >= 0
    },
    disabled() {
      return this.loading || this.noMore
    },
    noMore() {
      return this.page >= this.total / this.pageSize
    },
    i18n() {
      return this.$t('messages')
    },
  },
  created() {
    if (!this.isPractice) {
      return
    }
    if (this.hadTeacher) {
      this.fetchInfo()
    }
    this.fetchList(1)
  },
  methods: {
    palyAudio(id) {
      this.currentId = id
    },
    load(page) {
      this.fetchList(page)
    },
    fetchInfo() {
      apiWebGetDemonstration({
        id: this.id
      }).then((res) => {
        if (res.code != 200) {
          return
        }
        this.info = res.data
      })
    },
    fetchList(page) {
      if (this.loading) {
        return
      }
      this.loading = true
      if (page) {
        this.page = page
      }
      apiUserAskList({
        question_id: this.id,
        page_size: this.pageSize,
        page: this.page
      })
        .then((res) => {
          if (res.code != 200) {
            return
          }
          const list = res.data.list
          this.list = this.page === 1 ? list : [...this.list, ...list]
          this.total = res.data.total
          this.$emit('update-total', 'teacherTotal', res.data.total)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleAskDialog() {
      this.showAskDialog = !this.showAskDialog
    },
    handleVideoDialog() {
      this.showVideoDialog = !this.showVideoDialog
    },
    handleReply(id, index) {
      console.log('handleReply')
      if (this.showPayDialog) {
        return
      }
      this.showPayDialog = true
      apiUserInfo().then((res) => {
        this.userInfo = res.data
        this.$alert(
          `<div><p style="color: #A4AFB7; text-align: center; padding-top: 45px; border-top: 1px solid #EBEBF2;">查看回答将消耗 5 点券，是否确认查看？</p><p style="color: #A4AFB7; text-align: center; padding-bottom: 30px;">目前您的点券余额为：${this.userInfo.credit}</p></div>`,
          '',
          {
            customClass: 'teacher-dialog',
            dangerouslyUseHTMLString: true,
            callback: (action) => {
              this.showPayDialog = false
              if (action === 'confirm') {
                apiUserAskView({
                  question_id: this.id,
                  view_id: id
                }).then((res) => {
                  if (res.code === 4001) {
                    this.noCreditTip(res.msg)
                    return
                  }
                  if (res.code != 200) {
                    this.$layer.msg(res.msg)
                    return
                  }
                  this.list[index].can_view = 1
                })
              }
            }
          }
        )
      })
    },
    noCreditTip(credit) {
      this.$alert(
        `<div><p style="color: #A4AFB7; text-align: center; padding-top: 45px; border-top: 1px solid #EBEBF2;">抱歉您的点券余额不足，无法进行查看。</p><p style="color: #A4AFB7; text-align: center; padding-bottom: 30px;">目前您的点券余额为：${credit}</p></div>`,
        '',
        {
          customClass: 'teacher-dialog',
          dangerouslyUseHTMLString: true
        }
      )
    }
  }
}

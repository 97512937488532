<template>
  <div class="tabs-jobs">
    <div class="tip">
      {{ i18n.Pra.Yours
      }}<b>{{i18n.Pra.text111}}</b>{{i18n.Pra.text112}}
    </div>

    <Course
      :id="id"
      :isPractice="isPractice"
      :typeInfo="typeInfo"
      :detail="detail"
      :type="1"
    />
  </div>
</template>

<script>
import Course from './course'
export default {
  components: {
    Course,
  },
  computed: {
    i18n() {
      return this.$t('messages')
    },
  },
  props: {
    id: {
      type: [Number, String],
    },
    isPractice: {
      type: Boolean,
    },
    typeInfo: {
      type: Object,
      default: () => {},
    },
    detail: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style scoped lang="scss">
.tabs-jobs {
  .tip {
    background-color: #f4f4f4;
    padding: 6px 10px;
    color: #9c9c9c;
    border-radius: 5px;
    font-size: 12px;
    > b {
      color: #ffc06a;
      font-weight: normal;
    }
  }
}
</style>

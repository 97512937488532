<template>
  <el-dialog class="ai-vip-dialog" :visible.sync="visible" width="790px">
    <div class="title">{{ i18n.Pra.text17 }}</div>
    <div class="msg">
      {{ i18n.Pra.text18 }}
    </div>
    <img :src="img" alt="" />
    <div class="btn">
      <el-button type="primary" size="normal" @click="handleNavVip">
        {{ i18n.Pra.text19 }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import img from '@/assets/aiVipImg.jpg'

export default {
  data() {
    return {
      visible: false,
    }
  },
  computed: {
    img() {
      return img
    },
    i18n() {
      return this.$t('messages')
    },
  },
  methods: {
    open() {
      this.visible = true
    },

    handleNavVip() {
      this.$router.push({
        name: 'vip',
      })
    },
  },
}
</script>

<style scoped lang="scss">
.ai-vip-dialog {
  ::v-deep .el-dialog {
    border-radius: 10px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
    .el-dialog__title {
      display: none;
    }
    .el-dialog__headerbtn {
      top: 20px;
    }
  }
  .title {
    color: #a4afb7;
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 6px;
  }
  .msg {
    padding: 26px 0 0 0;
    color: #1f1f40;
    font-size: 14px;
  }
  img {
    width: 100%;
    height: auto;
    border-radius: 0;
  }
  .btn {
    padding: 20px 0;
    text-align: center;
    ::v-deep .el-button {
      width: 160px;
      height: 32px;
    }
  }
}
</style>

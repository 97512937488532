export const INTRODUCE_INFO = {
  36: {
    type: 'RA',
    classify: 'speaking',
    videoUrl:
      'https://ptegofeifan.oss-accelerate.aliyuncs.com/upload/descvideo/1-S%20Read%20aloud.mp4',
    h1: 'Read aloud',
    text: [
      '该题型考查口语和阅读。',
      '该题型是PTE口语部分的第一个题型。每次考试会考查6-7个RA。考生将看到一段文本，有30-40秒时间准备（具体时间依文本长短而定）。在听到一声短提示音“嘟”后，系统将开启麦克风并录音。此时考生需要立刻开始清晰地朗读文本。考生仅有一次录音机会。',
      '技巧简述：充分利用30-40秒的预读时间，利用标点符号，尝试寻找意群和断句。正式朗读中，单词做到辅音清晰，元音饱满。通过对文章的理解，用自然 的重弱读体现句子意思。尽量保证自然语速以及英语的连读，遇到生词不要卡顿。',
      '该题型真题命中率50%~60%左右，短期需要出分，可适当准备高频部分。但口语高分，正确的发音方式和技巧运用是高分的关键。'
    ],
    enText: [
      'For this item type you need to read a written text aloud.',
      'The recording status box displays a countdown until the microphone opens. You have 30-40 seconds to prepare, depending on the length of the passage. You hear a short tone. After the tone, start speaking immediately. Do not start speaking before the microphone opens because your voice will not be recorded.',
      'You should speak clearly. There is no need to rush.',
      'Finish speaking before the progress bar reaches the end. The word “Recording” changes to “Completed”.',
      'You are only able to record your response once.'
    ]
  },
  37: {
    type: 'RS',
    classify: 'speaking',
    videoUrl:
      'https://ptegofeifan.oss-accelerate.aliyuncs.com/upload/descvideo/2-S%20Repeat%20sentence.mp4',
    h1: 'Repeat sentence',
    text: [
      '该题型考查口语和听力。',
      '该题型是PTE口语部分的第二个题型。每次考试会考查10-12个RS。考生将听到一个短句子录音。在录音结束之后约2-3秒，系统将开启麦克风并录音。此时考生需要立刻开始按照原句复述听到的句子。考生仅有一次听录音和复述的机会。',
      '技巧简述：做好心理暗示，提示自己尝试理解句子意思。通过对于录音的意群分割来提升对于录音的记忆力。可紧急简化原句内容防止卡顿，或者舍弃部分意群来提升流利度。注意，尽量不要卡顿，尽量冷静清晰的模仿原文的语音语调。',
      '该题型真题命中率50%~60%左右，短期需要出分，可适当准备高频部分。但口语高分，正确的发音方式和技巧运用是高分的关键。'
    ],
    enText: [
      'For this item type you need to repeat the sentence you hear.',
      'The audio begins to play automatically. When the audio finishes, the microphone opens and the recording status box shows “Recording”. Speak into the microphone immediately (there is no short tone) and repeat exactly what you heard.',
      'You should speak clearly. There is no need to rush.',
      'Finish speaking before the progress bar reaches the end. The word “Recording” changes to “Completed”.',
      'You are not able to replay the audio. You are only able to record your response once.'
    ]
  },
  38: {
    type: 'DI',
    classify: 'speaking',
    videoUrl:
      'https://ptegofeifan.oss-accelerate.aliyuncs.com/upload/descvideo/3-S%20Describe%20image.mp4',
    h1: 'Describe image',
    text: [
      '该题型是PTE口语部分的第三个题型。每次考试会考查6-7个DI。考生将看到一幅图片，有25秒时间准备。在听到一声短提示音“嘟”后，系统将开启麦克风并录音。此时考生需要立刻开始清晰的描述图片。考生仅有一次录音机会。',
      '技巧简述：在25秒内整理好该图的内容点，尝试朗读图中的内容词汇。在保持自然清晰且有重音与节奏的同时，优先说出文章中的重要信息（极值，对比关系，位置和颜色等）。图中关键信息点的内容词一定要保证清晰，可被机器识别。不要过度纠结图的内容，不要临时更改模板或者选择复杂表述导致卡顿出现。',
      '该题型真题命中率60%左右，短期需要出分，可适当准备高频部分。但口语高分，正确的发音方式和技巧运用是高分的关键。'
    ],
    enText: [
      'For this item type you need to describe an image.',
      'The recording status box displays a countdown until the microphone opens. You have 25 seconds to study the image and prepare your response. You hear a short tone. After the tone, start speaking immediately. Do not start speaking before the microphone opens because your voice will not be recorded.',
      'You should speak clearly. There is no need to rush.',
      'Finish speaking before the progress bar reaches the end. The word “Recording” changes to “Completed’’.',
      'You are only able to record your response once.'
    ]
  },
  39: {
    type: 'RL',
    classify: 'speaking',
    videoUrl:
      'https://ptegofeifan.oss-accelerate.aliyuncs.com/upload/descvideo/4-S%20Re-tell%20lecture.mp4',
    h1: 'Re-tell lecture',
    text: [
      '该题型考查口语和听力。',
      '该题型是PTE口语部分的第四个题型。每次考试会考查3-4个RL。考生将听到一段讲座录音。在录音结束后，考生有10秒钟时间快速总结和准备。在听到一声短提示音“嘟”后，系统将开启麦克风并录音。此时考生需要立刻开始清晰的复述刚刚听到的讲座。注意，考生需要尽量总结并表述讲座的主要观点和内容。',
      '技巧简述：录音播放时，尽量优先记录主旨或关键信息，不宜过长或者过细，一般4-5个关键信息即可。尽量保证笔记的完整与清晰。录音过程中保持自然语速，确保发音清晰，有句子重音与节奏。',
      '该题型真题命中率70%左右，短期需要出分，可适当准备高频部分。但口语高分，正确的发音方式和技巧运用是高分的关键。'
    ],
    enText: [
      'For this item type you need to re-tell what you heard.',
      'The audio begins to play automatically. You may also see an image related to the audio. After the audio finishes, you have 10 seconds to prepare.',
      'The recording status box displays a countdown until the microphone opens. You hear a short tone. After the tone, start speaking immediately. Do not start speaking before the microphone opens because your voice will not be recorded.',
      'You should speak clearly. There is no need to rush.',
      'Finish speaking before the progress bar reaches the end. The word “Recording” changes to “Completed’’.',
      'You are only able to record your response once.',
      'Note: While the audio is playing, you can take notes on the erasable noteboard provided.'
    ]
  },
  40: {
    type: 'ASQ',
    classify: 'speaking',
    videoUrl:
      'https://ptegofeifan.oss-accelerate.aliyuncs.com/upload/descvideo/5-S%20Answer%20short%20question.mp4',
    h1: 'Answer short question',
    text: [
      '该题型考查口语和听力。',
      '该题型是PTE口语部分的第五个，也是最后一个题型。每次考试会考查10-12个ASQ。考生将听到一个常识性问题，在录音结束后，系统将开启麦克风并录音。此时考生需要用一个或少量几个单词回答问题即可。',
      '技巧简述：仔细听原题录音，用“常理”来推断答案。如果说错了答案可以修改自己的答案。发音保证清晰可识别。用单词回答即可，不需要说句子。',
      '该题型真题命中率80%~90%左右，短期需要出分，可适当准备高频部分。由于分值较小，考虑放在最后准备，即使没有时间全部准备也不会很大程度上影响成绩。'
    ],
    enText: [
      'For this item type you need to reply to the question in one or a few words.',
      'The audio begins to play automatically. You may also see an image.',
      'When the audio finishes, the microphone opens and the recording status box shows “Recording”. Speak into the microphone immediately (there is no short tone) and answer the question with one or a few words.',
      'You should speak clearly. There is no need to rush.',
      'Finish speaking before the progress bar reaches the end. The word “Recording” changes to “Completed”.',
      'You are not able to replay the audio. You are only able to record your response once.'
    ]
  },
  28: {
    type: 'SST',
    classify: 'listening',
    videoUrl:
      'https://ptegofeifan.oss-accelerate.aliyuncs.com/upload/descvideo/13-L%20Summarise%20spoken%20text.mp4',
    h1: 'Summarize spoken text',
    text: [
      '该题型考查听力和写作。',
      '该题型是PTE听力部分的第一个题型，每次考试会考查2-3个SST。考生将听到一段录音，然后根据听到的内容进行总结。考生有10分钟时间作答，字数要求在50-70字。',
      '技巧简述：SST占分比例高，需认真复习。在录音播放时，尽可能记录原文词汇和搭配，区分主要观点和细节。总结时，优先挑选文章主旨句和主要观点句型。写作时多采用原文词句和搭配，适当省略细节。字数控制在50-70内，注意检查拼写和语法。',
      '该题型真题命中率接近100%，短期需要出分，请务必准备高频部分。'
    ],
    enText: [
      'For this item type you hear an audio recording and need to write a 50-70 word summary on what you heard.',
      'You have 10 minutes to write your summary.',
      'The audio begins to play automatically. You are only able to listen to the audio recording once.',
      'The Word Count at the bottom of the screen counts the number of words you write. Make sure to write a minimum of 50 words, but no more than 70 words.',
      'There are also cut, copy and paste buttons which you may choose to use while constructing your summary.',
      'Cut: Select text from your answer that you wish to remove and left-click “Cut”.',
      'Copy: Select text from your answer that you wish to copy and left-click “Copy”.',
      'Paste: Place the cursor where you wish to paste the cut/copied text and left-click “Paste”.'
    ]
  },
  29: {
    type: 'MCM',
    classify: 'listening',
    videoUrl:
      'https://ptegofeifan.oss-accelerate.aliyuncs.com/upload/descvideo/14-L%20Choose%20multiple%20answers.mp4',
    h1: 'Multiple-choice, choose multiple answers',
    text: [
      '该题型是PTE听力部分的第二个题型，每次考试会考查2-3个听力多选题。考生将听到一段录音，然后根据问题进行选择。注意，多选题中，答案数量一定是大于一个的。',
      '技巧简述：听力选择题占分比例低。目标在65分或以下的同学不必花精力在该题型上。目标79的同学练习时注意审题，根据题目的要求重点听并提取答案。一般正确选项是原文同义，但不同表达方式改写。'
    ],
    enText: [
      'For this item type you need to listen to the recording and answer the multiple-choice question. There is more than one correct response.',
      'The audio begins to play automatically. You are only able to listen to the audio recording once.',
      'You need to select all the response options that you think are correct from the list of possible options.',
      'To select an option click on it using the left button on your mouse. If you change your mind, left-click on the option again to deselect it.',
      'The options you select are highlighted in yellow.'
    ]
  },
  30: {
    type: 'FIB',
    classify: 'listening',
    videoUrl:
      'https://ptegofeifan.oss-accelerate.aliyuncs.com/upload/descvideo/15-L%20Fill%20in%20the%20blanks.mp4',
    h1: 'Fill in the blanks',
    text: [
      '该题型考查听力和写作。',
      '该题型是PTE听力部分的第三个题型，每次考试会考查2-3个填空题。考生将看到一段文本，其中有若干个空是缺失的。同时，考生将听到一段录音。考生需要根据听到的录音，填写空白处的单词。注意，一个空仅表示一个单词或数字的缺失。',
      '技巧简述：听力填空题占分值较高，同时正确率低。目标在65分或以上的同学需要花精力和时间进行大量的练习和提高。注意，FIB最大的错误原因主要是在单词的发音识别，拼写和单词变形（语法和前后缀）上。而不是大家所担心的生词或者难词。'
    ],
    enText: [
      'For this item type you are presented with a transcript of the audio recording, however some words are missing. Restore the transcript by typing in the missing words.',
      'The audio plays automatically. You are only able to listen to the audio recording once.',
      'Left-click on each gap and type in the missing word. You can also use the Tab button on the keyboard to move between gaps.',
      'Note: While the audio is playing, you can take notes on the erasable noteboard provided and then fill in the gaps.'
    ]
  },
  31: {
    type: 'HCS',
    classify: 'listening',
    videoUrl:
      'https://ptegofeifan.oss-accelerate.aliyuncs.com/upload/descvideo/16-L%20Highlight%20correct%20summary.mp4',
    h1: 'Highlight correct summary',
    text: [
      '该题型考查听力和阅读。',
      '该题型是PTE听力部分的第四个题型，每次考试会考查2-3个HCS。考生将听到一段录音。考生需要根据听到录音，选择一个最恰当的总结选项。',
      '技巧简述：听力选择题占分比例低。目标在65分或以下的同学不必花精力在该题型上。目标79的同学练习时注意通过主旨句抓取重点信息。一般正确选项是主旨句同义，但不同表达方式改写。'
    ],
    enText: [
      'For this item type you need to select the summary that best matches the recording.',
      'The audio begins to play automatically. You are only able to listen to the audio recording once.',
      'There are several possible response options but only one is correct.',
      'To select an option click on it using the left button on your mouse. If you change your mind, left-click on the option again to deselect it, or left-click on a different option.',
      'The option you select is highlighted in yellow.',
      'Note: It may be difficult to read and listen at the same time. It is recommended that you listen first, make notes on the erasable noteboard and then read the summaries.'
    ]
  },
  32: {
    type: 'MCS',
    classify: 'listening',
    videoUrl:
      'https://ptegofeifan.oss-accelerate.aliyuncs.com/upload/descvideo/17-L%20Choose%20single%20answer.mp4',
    h1: 'Multiple-choice, choose single answer',
    text: [
      '该题型是PTE听力部分的第五个题型，每次考试会考查2-3个听力单选。考生将听到一段录音，然后根据问题进行选择。注意，单选有且仅有一个正确答案。',
      '技巧简述：听力选择题占分比例低。目标在65分或以下的同学不必花精力在该题型上。目标79的同学练习时注意审题，根据题目的要求重点听并提取答案。一般正确选项是原文同义，但不同表达方式改写。'
    ],
    enText: [
      'For this item type you need to listen to the recording and answer the multiple-choice question.',
      'The audio begins to play automatically. You are only able to listen to the audio recording once.',
      'There are several possible response options but only one is correct.',
      'To select an option click on it using the left button on your mouse. If you change your mind, left-click on the option again to deselect it, or left-click on a different option.',
      'The option you select is highlighted in yellow.'
    ]
  },
  33: {
    type: 'SMW',
    classify: 'listening',
    videoUrl:
      'https://ptegofeifan.oss-accelerate.aliyuncs.com/upload/descvideo/18-L%20Select%20missing%20word.mp4',
    h1: 'Select missing words',
    text: [
      '该题型是PTE听力部分的第六个题型，每次考试会考查2-3个SMW。考生将听到一段录音。在录音的结尾处，一个或者一组单词将被“哔”声所替换。考生需要根据听到的内容，选择最合适的选项将录音补充完整。',
      '技巧简述：听力选择题占分比例低。目标在65分或以下的同学不必花精力在该题型上。目标79的同学练习时注意最后一句话的精听练习，根据最后一句话和全文大意找到逻辑线索，进行句子补足。'
    ],
    enText: [
      'For this item type the last word or group of words in the recording has been replaced by a beep. Select the most appropriate option to complete the recording.',
      'The audio begins to play automatically. You are only able to listen to the audio recording once.',
      'There are several possible response options but only one is correct.',
      'To select an option click on it using the left button on your mouse. If you change your mind, left-click on the option again to deselect it, or left-click on a different option.',
      'The option you select is highlighted in yellow.'
    ]
  },
  34: {
    type: 'HIW',
    classify: 'listening',
    videoUrl:
      'https://ptegofeifan.oss-accelerate.aliyuncs.com/upload/descvideo/19-L%20Highlight%20incorrect%20word.mp4',
    h1: 'Highlight incorrect words',
    text: [
      '该题型考查听力和阅读。',
      '该题型是PTE听力部分的第七个题型，每次考试会考查2-3个HIW。考生将看到一段文本，该文本中故意设置了若干个错误单词。同时，考生会听到一段录音，需要将文本中和录音不同的单词识别并标记出来。用鼠标左键单击单词，即可标出。',
      '技巧简述：标错词这道题占分比值较高，需要尽量保证全对。该题型可以算是整个PTE考试里最简单的题型之一，练习时注意精力100%集中，跟读文本时不要分心走神。通过唇部跟读，判断错误单词。'
    ],
    enText: [
      'For this item type you are presented with a transcript of the audio recording, however the transcript contains some errors. While listening and reading, you need to select the words in the text that differ from what the speaker says.',
      'The audio begins to plays automatically. You are only able to listen to the audio recording once.',
      'To select a word click on it using the left button on your mouse. If you change your mind, left-click on the word again to deselect it.',
      'The words you select are highlighted in yellow.'
    ]
  },
  35: {
    type: 'WFD',
    classify: 'listening',
    videoUrl:
      'https://ptegofeifan.oss-accelerate.aliyuncs.com/upload/descvideo/20-L%20Write%20from%20dictation.mp4',
    h1: 'Write from dictation',
    text: [
      '该题型考查听力和写作。',
      '该题型是PTE听力部分的第八个题型，也是最后一个题型。每次考试会考查3-4个WFD。同时也是整场PTE考试的最后一个题型。考生将听到一个短句录音。考生需要将听到的句子完整地输入空白处。注意检查单词拼写。',
      '技巧简述：听写句子是听力部分占分比值最高的题型。首先初次考试的同学一定要注意时间把控，留足至少5分钟左右的时间给听写句子的3-4个WFD。平均每题1分30秒左右的做题时间。因为该题是按照单词给分，所以需要大家严格检查单词拼写，尽量不要遗漏或者错写单词。',
      '该题型真题命中率接近100%，短期需要出分，请务必准备高频部分。'
    ],
    enText: [
      'For this item type you hear a short sentence. Type the sentence into the response box at the bottom of the screen.',
      'The audio begins to play automatically. You are only able to listen to the audio recording once.',
      'Note: While the audio is playing, you can take notes on the erasable noteboard provided.',
      'Remember to check your spelling.'
    ]
  },
  42: {
    type: 'MCM',
    classify: 'reading',
    videoUrl:
      'https://ptegofeifan.oss-accelerate.aliyuncs.com/upload/descvideo/09-R%20Choose%20multiple%20answers.mp4',
    h1: 'Multiple-choice, choose multiple answers',
    text: [
      '该题型是PTE阅读部分的第二个题型，每次考试会考查2-3个多选题。考生将看到一篇文章段落。根据问题，选择多个正确答案。注意，多选题的正确答案一定是大于一个的。技巧简述：阅读的选择题占分比例低。目标在65分或以下的同学不必花精力在该题型上。目标79的同学练习时注意审题，根据题目的要求回原文定位答案。一般正确选项是原文同义，但不同表达方式改写。'
    ],
    enText: [
      'For this item type you need to read the passage and answer the multiple-choice question. There is more than one correct response.',
      'You need to select all the response options that you think are correct from the list of possible options.',
      'To select an option, click on it using the left button on your mouse. If you change your mind, left-click on the option again to deselect it.',
      'The options you select are highlighted in yellow.'
    ]
  },
  43: {
    type: 'MCS',
    classify: 'reading',
    videoUrl:
      'https://ptegofeifan.oss-accelerate.aliyuncs.com/upload/descvideo/08-R%20Choose%20single%20answer.mp4',
    h1: 'Multiple-choice, choose single answer',
    text: [
      '该题型是PTE阅读部分的第一个题型，每次考试会考查2-3个单选题。考生将看到一个文章段落。根据问题，选择一个正确的答案。',
      '技巧简述：阅读的选择题占分比例低。目标在65分或以下的同学不必花精力在该题型上。目标79的同学练习时注意审题，根据题目的要求回原文定位答案。一般正确选项是原文同义，但不同表达方式改写。'
    ],
    enText: [
      'For this item type you need to read the passage and answer the multiple-choice question.',
      'There are several possible response options but only one is correct.',
      'To select an option click on it using the left button on your mouse. If you change your mind, left-click on the option again to deselect it, or left-click on a different option.',
      'The option you select is highlighted in yellow.'
    ]
  },
  44: {
    type: 'RO',
    classify: 'reading',
    videoUrl:
      'https://ptegofeifan.oss-accelerate.aliyuncs.com/upload/descvideo/10-R%20Re-order%20paragraph.mp4',
    h1: 'Re-order paragraphs',
    text: [
      '该题型是PTE阅读部分的第三个题型，每次考试会考查2-3个段落排序。考生将看到4个或者5个被打乱顺序的文字框。考生需要通过阅读和理解，将文字框还原成原本的顺序。技巧简述：排序题对于同学们的逻辑和英语阅读水平较高。可以通过先找到明显有先后关联的段落来破题。通过文章中的指代词，逻辑转折词，年份等关键信息词，将段落进行排序。也可以通过判断首尾段进行排序。',
      '该题型真题命中率50%左右，短期需要出分，请务必准备高频部分。'
    ],
    enText: [
      'For this item type you need to restore the original order of the text by selecting text boxes and dragging them across the screen.',
      'There are two ways you can move the text:',
      '1. Left-click on a box to select it (it will be outlined in blue), hold the left mouse button down and drag it to the desired location.',
      '2. Left-click on a box to select it, and then left-click on the left and right arrow buttons to move it across. On the right panel, you can also use the up and down arrow buttons to re-order the boxes.',
      'To deselect a box, left-click elsewhere on the screen.'
    ]
  },
  52: {
    type: 'RFIB',
    classify: 'reading',
    videoUrl:
      'https://ptegofeifan.oss-accelerate.aliyuncs.com/upload/descvideo/11-R%20Fill%20in%20the%20blanks.mp4',
    h1: 'Fill in the blanks',
    text: [
      '该题型是PTE阅读部分的第四个题型，每次考试会考查4-5个选词填空。考生将看到一个文章段落，其中有若干个空缺。同时考生将看到一组单词，通常单词数量会多余空缺位置。所以考生不会用到所有的单词。',
      '技巧简述：选词填空占分比例高，需认真复习。选词填空需要大家对于空的词性（名词，形容词，动词和副词等）进行初步判断。在确定正确的词性后，再快速略读全文，根据空前后上下文的逻辑，选择最合适的单词。不确定的空可以放到最后。',
      '该题型真题命中率60~70%，短期需要出分，请务必准备高频部分。'
    ],
    enText: [
      'For this item type you need to drag and drop words across the screen to correctly fill in the gaps in the text.',
      'There is a passage with some missing words. You have a list of words in the blue box that you can use to fill the gaps in the text. There are more words than gaps so you will not use all the words provided.',
      'Left-click on a word to select it; keep the left mouse button held down and drag the word to the gap where you want to place it. You can also drag words between gaps. To remove a word from a gap, drag it back to the blue box.'
    ]
  },
  53: {
    type: 'RWFIB',
    classify: 'reading',
    videoUrl:
      'https://ptegofeifan.oss-accelerate.aliyuncs.com/upload/descvideo/12-R_W%20Fill%20in%20the%20blanks.mp4',
    h1: 'R&W: Fill in the blanks',
    text: [
      '该题型考查阅读和写作。',
      '该题型是PTE阅读部分的第五个题型，每次考试会考查5-6个完型填空。考生将看到一个文章段落，其中有若干个空缺。每个空缺旁会有一个下拉菜单按钮。考生点击按钮后会看到一个下拉菜单，其中含有该空的备选项单词。考生需要根据阅读和理解选择最合适的单词进行填空。技巧简述：完形填空占分比例高，需认真复习。需要考生有较强的词汇分辨能力。快速略读全文，对于空所在的句子进行细致拆分和语义分析。根据已有的逻辑提示词，选择逻辑和词义都最符合的单词或词组。',
      '该题型真题命中率60~70%，短期需要出分，请务必准备高频部分。'
    ],
    enText: [
      'For this item type you need select the most appropriate words from a drop-down list to restore the text.',
      'There is a passage with some missing words. Beside each gap, there is a button with a drop-down list. Left-click on this button to reveal the drop-down list of options for that gap. Select the option you think best fills the gap.',
      'To change your mind, left-click on a different option.'
    ]
  },
  45: {
    type: 'SWT',
    classify: 'writing',
    videoUrl:
      'https://ptegofeifan.oss-accelerate.aliyuncs.com/upload/descvideo/06-W%20Summarise%20written%20text.mp4',
    h1: 'Summarize written text',
    text: [
      '该题型考查写作和阅读。',
      '该题型是PTE写作部分的第一个题型。每次考试会考查2-3个SWT。考生将看到一段文本，需要根据文本，用一个完整的句子进行总结。注意，总结必须是一句话，也就是只能有一个句号。同时，考生的作答长度是5-75字。作答时间为10分钟。',
      '技巧简述：2-3分钟之内泛读全文，不纠结细节，快速找到文章逻辑并确定核心内容句。用复合句（and/because/but等连接词）或者复杂句（从句）来连接这些内容句。保证语法词汇与拼写准确度，可以参考原文用词与语法，无需过度修改。确保预留至少2分钟做检查。',
      '该题型真题命中率几乎100%，短期需要出分，请务必准备高频部分。'
    ],
    enText: [
      'For this item type you need to write a summary of the text in one sentence.',
      'You have 10 minutes to write your summary. Make sure to include the main points of the reading passage in a full, single sentence of no more than 75 words.',
      'The Word Count at the bottom of the screen counts the number of words you write. There are also cut, copy and paste buttons which you may use while constructing your summary.',
      'Cut: Select text from your answer that you wish to remove and left-click “Cut”.',
      'Copy: Select text from your answer that you wish to copy and left-click “Copy”.',
      'Paste: Place the cursor where you wish to paste the cut/copied text and left-click “Paste”.'
    ]
  },
  46: {
    type: 'WE',
    classify: 'writing',
    videoUrl:
      'https://ptegofeifan.oss-accelerate.aliyuncs.com/upload/descvideo/07-W%20Write%20essay.mp4',
    h1: 'Write essay',
    text: [
      '该题型是PTE写作的第二个题型。每次考试会考查1-2篇大作文。考生将根据一个题目，写出一篇议论文。文章长度是200-300字。作答时间为20分钟。',
      '技巧简述：可以使用模板来固定文章框架与提升语法词汇的复杂度。考前整理归纳高频题论点论据方向，牢记关键内容词汇。根据题目要求，将尽可能学术的内容词汇填入模板即可。预留2-3分钟检查，确保文章字数200-300之内，语法词汇与拼写完全正确。',
      '该题型真题命中率几乎100%，短期需要出分，请务必准备高频部分。'
    ],
    enText: [
      'For this item type you need to write a 200-300 word argumentative essay in response to a prompt.',
      'You have 20 minutes to write your essay.',
      'The Word Count at the bottom of the screen counts the number of words you write. Make sure to write a minimum of 200 words, but no more than 300 words.',
      'There are also cut, copy and paste buttons which you may choose to use while constructing your response.',
      'Cut: Select text from your answer that you wish to remove and left-click “Cut”.',
      'Copy: Select text from your answer that you wish to copy and left-click “Copy”.',
      'Paste: Place the cursor where you wish to paste the cut/copied text and left-click “Paste”.'
    ]
  }
}

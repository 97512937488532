<template>
  <el-dialog
    :title="i18n.Pra.text20"
    class="ai-progress-dialog"
    :visible.sync="visible"
    width="700px"
  >
    <div class="title">{{ i18n.Pra.text20 }}</div>
    <div class="body-title">{{ i18n.Pra.text20 }}...</div>
    <div class="progress">
      <div
        class="item"
        v-for="(item, index) of progressList"
        :key="item.name"
        :class="{ active: index < 2 }"
      >
        <div class="cricle">
          <span>{{ item.name }}</span>
        </div>
        <div class="bar"></div>
      </div>
    </div>

    <div class="desc">
      <div>{{ i18n.Pra.text21 }}</div>
      <div>{{ i18n.Pra.text22 }}</div>
    </div>
  </el-dialog>
</template>

<script>
import i18n from '@/i18n/i18n'

const progressList = [
  {
    name: i18n.locale === 'ch' ? '已提交' : 'Submitted',
  },
  {
    name: i18n.locale === 'ch' ? 'AI识别' : 'AI recognition',
  },
  {
    name: i18n.locale === 'ch' ? 'AI评分' : 'AI score',
  },
  {
    name: i18n.locale === 'ch' ? '评分展示' : 'Scoring display',
  },
]
export default {
  data() {
    return {
      visible: false,
    }
  },
  computed: {
    progressList() {
      return progressList
    },
    i18n() {
      return this.$t('messages')
    },
  },
  methods: {
    open() {
      this.visible = true
    },
    close() {
      this.visible = false
    },
  },
}
</script>

<style scoped lang="scss">
.ai-progress-dialog {
  ::v-deep .el-dialog {
    border-radius: 10px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
    .el-dialog__title {
      display: none;
    }
    .el-dialog__headerbtn {
      top: 20px;
    }
  }
  .title {
    color: #a4afb7;
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 6px;
  }
  .body-title {
    font-size: 24px;
    color: #1f1f40;
    font-weight: bold;
    text-align: center;
    padding: 46px 0;
  }
  .progress {
    padding: 0 20px 40px 20px;
    display: flex;
    .item {
      flex: 1;
      display: flex;
      align-items: center;
      &.active {
        .cricle {
          background-color: #3b86ff;
        }
        .bar {
          background-color: #3b86ff;
        }
      }
      &:last-child {
        flex: none;
        width: 12px;
        .bar {
          display: none;
        }
      }
      .cricle {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #bfbfbf;
        position: relative;
        > span {
          position: absolute;
          top: 22px;
          left: 50%;
          transform: translateX(-50%);
          width: 100px;
          text-align: center;
        }
      }
      .bar {
        flex: 1;
        height: 4px;
        border-radius: 10px;
        background-color: #bfbfbf;
        margin: 0 5px;
      }
    }
  }
  .desc {
    padding: 60px 0;
    text-align: center;
    color: #9c9c9c;
  }
}
</style>

<template>
  <div class="exercises-checkbox" v-if="['RS', 'RA', 'SWT'].includes(title)">
    <template v-if="title == 'RS'">
      <el-popover
        placement="top"
        trigger="hover"
        popper-class="practice-tip-message-wrap"
      >
        <el-checkbox slot="reference" v-model="recorderTextView">{{
          i18n.Pra.text53
        }}</el-checkbox>
        <div class="practice-tip-message rs-tip-message">
          <div class="line"><span /><span /></div>
          <div>{{ i18n.Pra.text54 }}</div>
          <div class="btn-text" @click="handleIntroduceDialog">
            {{ i18n.Pra.text55 }}
          </div>
        </div>
      </el-popover>
    </template>
    <template v-if="title == 'RA'">
      <el-popover
        placement="top"
        trigger="hover"
        popper-class="practice-tip-message-wrap"
      >
        <el-checkbox slot="reference" v-model="btnLoud">{{
          i18n.Pra.stress
        }}</el-checkbox>
        <div class="practice-tip-message">
          <div><i class="i-circle" />{{ i18n.Pra.text56 }}</div>
          <div class="btn-text" @click="handleIntroduceAccentDialog">
            {{ i18n.Pra.text57 }}
          </div>
        </div>
      </el-popover>

      <el-popover
        placement="top"
        trigger="hover"
        popper-class="practice-tip-message-wrap"
      >
        <el-checkbox
          slot="reference"
          v-model="btnPunctuation"
          style="margin-left: 10px"
          >{{ $t('Pra.pause') }}</el-checkbox
        >

        <div class="practice-tip-message">
          <div><i class="iconfont icon-paus" />{{ i18n.Pra.text58 }}</div>
          <div><span class="text-icon">//</span>{{ i18n.Pra.text59 }}</div>
          <div class="btn-text" @click="handleIntroducePausDialog">
            {{ i18n.Pra.text60 }}
          </div>
        </div>
      </el-popover>
    </template>
    <template v-if="title == 'SWT'">
      <el-checkbox v-model="btnShowGist" @change="btnShowTranslate = false">{{
        i18n.Pra.text61
      }}</el-checkbox>
      <el-checkbox v-model="btnShowTranslate" @change="btnShowGist = false">{{
        i18n.Pra.translate
      }}</el-checkbox>
    </template>

    <ExercisesIntroduceAdvise
      v-if="showIntroduceDialog"
      :visible="showIntroduceDialog"
      @closeDialog="handleIntroduceDialog"
    />
    <IntroduceAccent
      v-if="showIntroduceAccentDialog"
      :visible="showIntroduceAccentDialog"
      @closeDialog="handleIntroduceAccentDialog"
    />
    <IntroducePaus
      v-if="showIntroducePausDialog"
      :visible="showIntroducePausDialog"
      @closeDialog="handleIntroducePausDialog"
    />
  </div>
</template>

<script>
import ExercisesIntroduceAdvise from '@/components/ExercisesIntroduceAdvise/ExercisesIntroduceAdvise'
import IntroduceAccent from './ExercisesIntroduceAccent'
import IntroducePaus from './ExercisesIntroducePaus'

export default {
  components: {
    ExercisesIntroduceAdvise,
    IntroduceAccent,
    IntroducePaus
  },
  computed: {
    i18n() {
      return this.$t('messages')
    }
  },
  props: {
    title: String,
    onChange: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      // 切换按钮
      btnShowGist: false, //显示主旨
      btnShowTranslate: false, //显示翻译
      btnPunctuation: false, //建议断句
      recorderTextView: false, //建议意群开关控制
      btnLoud: false, //建议重读

      showIntroduceDialog: false,
      showIntroduceAccentDialog: false,
      showIntroducePausDialog: false
    }
  },
  watch: {
    btnShowGist() {
      this.onChange('btnShowGist', this.btnShowGist)
    },
    btnShowTranslate() {
      this.onChange('btnShowTranslate', this.btnShowTranslate)
    },
    btnPunctuation() {
      this.onChange('btnPunctuation', this.btnPunctuation)
    },
    recorderTextView() {
      this.onChange('recorderTextView', this.recorderTextView)
    },
    btnLoud() {
      this.onChange('btnLoud', this.btnLoud)
    }
  },
  methods: {
    handleIntroduceDialog() {
      this.showIntroduceDialog = !this.showIntroduceDialog
    },
    handleIntroduceAccentDialog() {
      this.showIntroduceAccentDialog = !this.showIntroduceAccentDialog
    },
    handleIntroducePausDialog() {
      this.showIntroducePausDialog = !this.showIntroducePausDialog
    }
  }
}
</script>

<style scoped lang="scss">
.exercises-checkbox {
  padding: 5px 12px 4px 12px;
  background-color: #f5f5f5;
  border-radius: 5px;
  .el-checkbox {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
  .text-tips {
    position: relative;
    &:hover {
      .text-tips-icon {
        color: #3b86ff;
      }
      .text-tips-text {
        visibility: visible;
      }
    }
    .text-tips-icon {
      vertical-align: middle;
      cursor: pointer;
      color: #a4afb7;
    }
    .text-tips-text {
      visibility: hidden;
      top: -41px;
      left: -76px;
      display: inline-block;
      position: absolute;
      word-break: keep-all;
      padding: 0 20px;
      height: 32px;
      line-height: 32px;
      background: #ffffff;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
      border-radius: 5px;
    }
  }
}
</style>

import ExercisesFilters from '@/components/ExercisesFilters/ExercisesFilters.vue'
import ExercisesTabs from '@/components/ExercisesTabs/ExercisesTabs.vue'
import ExercisesListDialog from '@/components/ExercisesListDialog/ExercisesListDialog.vue'
import ExercisesListDialogWalkman from '@/components/ExercisesListDialog/ExercisesListDialog-walkman.vue'
import ExercisesIntroduce from '@/components/ExercisesIntroduce/ExercisesIntroduce.vue'
// import ExercisesContent from '@/components/ExercisesContent/ExercisesContent.vue'
import WalkmanContent from '@/components/ExercisesContent/WalkmanContent.vue'
import Empty from '@/components/ExercisesEmpty.vue'
import { TYPE_INFO } from '@/utils/type-list'
import i18n from '@/i18n/i18n'

import {
  apiGetWebQuestion,
  apiWebGreDetail,
  apiWebListStat,
  apiWebQuestionSave,
  apiPracticeJobNext
} from '@/api/api.js'
import { apiChapterList } from '@/api/course'
import { cloneDeep } from 'lodash'

export default {
  components: {
    ExercisesFilters,
    ExercisesTabs,
    ExercisesListDialog,
    ExercisesListDialogWalkman,
    // ExercisesContent,
    WalkmanContent,
    ExercisesIntroduce,
    Empty
  },
  data() {
    return {
      showFilters: false,
      showListDialog: false,
      showIntroduceDialog: false,
      number: {
        select: 0,
        done: 0,
        total: 0,
        pass: 0,
        percent: 0,
        percents: 0
      },
      idList: {
        pre: '',
        next: '',
        current: ''
      },
      isGreList: {
        pre: '',
        next: '',
        current: ''
      },
      filters: {},
      realFilters: {},
      typeInfo: {},
      list: [],
      detail: null,
      loading: false,
      tagList: [],
      cateTagList: [],
      statLoading: false,
      isDetail: null,
      bookDetailsId: null,
      chapterId: null,
      greId: 0,
      fromSearch: false,
      formMessage: false,
      isPracticeDetail: false,
      fromCollect: false,
      activeID: 0,
      curGreId: 0, //用来定位页数

      currentIndex: 0,
      cateId: 0,
      filterText: '',

      fromCourse: false,
      courseTryList: [],
      courseTryListIndex: 0
    }
  },
  computed: {
    isPractice() {
      return (
        this.isDetail === 'practice' ||
        this.filters.is_gre === 2 ||
        this.isPracticeDetail
      )
    },
    i18n() {
      return this.$t('messages')
    },
    isJobType() {
      const { from } = this.$route.query
      return ['course'].includes(from)
    },
    tagFilterList() {
      return [
        {
          id: 0,
          name: i18n.locale === 'ch' ? '全部真题' : 'All true questions',
          oname: i18n.locale === 'ch' ? '机经题' : 'Machine Sutra questions'
        },
        {
          id: 0,
          name: `${new Date().getMonth() + 1}${
            i18n.locale === 'ch' ? '月高频' : 'Monthly high frequency'
          }`,
          oname: i18n.locale === 'ch' ? '高频' : 'High frequency'
        },
        {
          id: 0,
          name: i18n.locale === 'ch' ? '新题' : 'New topic',
          oname: i18n.locale === 'ch' ? '新题' : 'New topic'
        },
        {
          id: 0,
          name: i18n.locale === 'ch' ? '模拟题' : 'Simulation problem',
          oname: i18n.locale === 'ch' ? '模拟题' : 'Simulation problem'
        }
      ].map((item) => {
        const tagItem =
          (this.tagList || []).find((m) => m.name === item.oname) || {}
        return {
          ...item,
          id: tagItem.id || item.id
        }
      })
    }
  },
  watch: {
    $route() {
      this.initData()
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData(query = this.$route.query) {
      const { clid, claid, greId, chapterId, practiceId, from } = query
      this.cateId = clid
      this.typeInfo = TYPE_INFO[clid]
      this.fromSearch = from === 'search'
      this.fromCollect = from === 'collect'
      this.fromCourse = from === 'course'
      this.formMessage = from === 'message'

      if (greId) {
        this.isDetail = 'gre'
        this.idList.current = greId
        this.fetchDetail()
      } else if (practiceId) {
        this.isDetail = 'practice'
        this.idList.current = practiceId
        this.bookDetailsId = claid
        this.chapterId = chapterId
        this.fetchDetail()
      } else {
        this.fetchListStat({ is_history: 1 })
      }

      if (this.fromCourse) {
        this.fetchChapterList()
      }
    },
    updatePractice() {
      if (this.$refs.tabs) {
        this.$refs.tabs.refreshData()
      }
    },
    stopAudio() {
      // TODO: 将当前录音作为提问的默认选择的录音
      if (
        this.$refs.tabs &&
        this.$refs.tabs.$children[1] &&
        this.$refs.tabs.$children[1].currentId
      ) {
        this.$refs.tabs.$children[1].currentId = null
      }
    },
    prev() {
      this.$router.go(-1)
    },
    changeFilters() {
      this.showFilters = !this.showFilters
    },
    fetchListStat({ filters, is_history = 2, is_next = 0, onSuccess } = {}) {
      if (this.statLoading) {
        return
      }
      this.statLoading = true
      const resultFilters = filters
        ? filters
        : is_next
        ? this.filters
        : this.realFilters
      apiWebListStat({
        ...resultFilters,
        cate_id: this.cateId,
        is_history,
        curr_gre_id: this.greId,
        is_next
      })
        .then(({ code, data }) => {
          if (code != 200) {
            return
          }
          this.filters = data.search_list
          const {
            cate_tag_list,
            question_id_current,
            question_id_next,
            question_id_prev,
            gre_id_current,
            gre_id_next,
            gre_id_prev,
            had_done,
            list_count,
            list_selected,
            had_exam,
            search_label_list,
            is_gre_current,
            is_gre_next,
            is_gre_prev
          } = data
          this.isPracticeDetail = is_gre_current === 2
          this.isGreList = {
            pre: is_gre_prev,
            next: is_gre_next,
            current: is_gre_current
          }
          if (this.isPractice) {
            this.idList = {
              pre: question_id_prev,
              next: question_id_next,
              current: question_id_current
            }
          } else {
            this.idList = {
              pre: gre_id_prev,
              next: gre_id_next,
              current: gre_id_current
            }
          }
          this.greId = gre_id_current
          this.tagList = search_label_list
          this.cateTagList = cate_tag_list
          this.number = {
            select: list_selected,
            done: had_done,
            total: list_count,
            pass: had_exam,
            percent: list_selected ? (had_done / list_selected) * 100 : 0
          }
          this.number.percents = Math.floor(this.number.percent * 100) / 100
          this.realFilters = cloneDeep(data.search_list)
          if (onSuccess) {
            onSuccess()
            this.showFilters = false
          }
          if (filters || !this.idList.current) {
            return
          }
          this.fetchDetail()
        })
        .finally(() => {
          this.statLoading = false
        })
    },
    fetchDetail() {
      this.loading = true
      if (this.isPractice) {
        apiGetWebQuestion({
          id: this.idList.current
        })
          .then((res) => {
            if (res.code != 200) {
              return
            }
            // 当前问题详情编号 #xxx
            this.greId = res.data.ggid
            this.detail = res.data
            // 获取当前问题详情编号，传到已选题目的组件 ExercisesListDialog 中
            this.activeID = res.data.ggid
            this.curGreId = res.data.ggid

            if (this.$refs.listDialog) {
              this.$refs.listDialog.refresh(this.curGreId)
            }
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        apiWebGreDetail({
          greid: this.idList.current
        })
          .then((res) => {
            if (res.code != 200) {
              return
            }
            this.greId = this.idList.current
            this.detail = res.data

            // 获取当前问题详情编号，传到已选题目的组件 ExercisesListDialog 中
            this.activeID = res.data.id
            this.curGreId = res.data.id

            if (this.$refs.listDialog) {
              this.$refs.listDialog.refresh(this.curGreId)
            }
            if (this.$refs.filters) {
              this.$refs.filters.filterText(this.realFilters)
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    fetchQuestionResult(id) {
      var formData = new window.FormData()
      formData.append('qid', id)
      apiWebQuestionSave(formData).then((res) => {
        this.detail = {
          ...this.detail,
          subtitles: res.data.subtitles,
          answer: res.data.answer
        }
      })
    },
    handleFilters(filters, onSuccess) {
      this.fetchListStat({ filters, onSuccess })
    },
    handleFilterCancel() {
      this.showFilters = false
    },
    handleFilterText(text) {
      this.filterText = text
    },
    handleListDialog() {
      this.showListDialog = !this.showListDialog
    },
    handleListResult({ index }) {
      this.currentIndex = index || this.currentIndex
    },
    handleIntroduceDialog() {
      this.showIntroduceDialog = !this.showIntroduceDialog
    },
    selectItem(type, item) {
      const { clid, claid, greId, practiceId, from } = this.$route.query

      if (type === 'next') {
        if (this.fromCourse) {
          const item = this.getTryItem(1)
          this.$router.replace({
            name: 'Exercises',
            query: {
              from: 'course',
              clid: item.gre_cate,
              practiceId: item.question_id,
              chapterId: item.chapter_id,
              courseId: item.course_id,
              tryId: item.id
            }
          })
        } else if (this.bookDetailsId) {
          apiPracticeJobNext({
            curr_id: this.idList.current,
            job_id: claid
          }).then(({ data }) => {
            if (data.next_id.id > 0) {
              this.$router.replace({
                name: 'Exercises',
                query: {
                  clid: data.next_id.clid,
                  practiceId: data.next_id.id,
                  claid: claid
                }
              })
            }
          })
        } else {
          this.isPracticeDetail = this.isGreList.next === 2
          this.fetchListStat({ is_next: 1, is_history: 1 })
        }
      }
      if (type === 'pre') {
        if (this.fromCourse) {
          const item = this.getTryItem(-1)
          this.$router.replace({
            name: 'Exercises',
            query: {
              from: 'course',
              clid: item.gre_cate,
              practiceId: item.question_id,
              chapterId: item.chapter_id,
              courseId: item.course_id,
              tryId: item.id
            }
          })
        } else if (this.bookDetailsId) {
          apiPracticeJobNext({
            curr_id: this.idList.current,
            job_id: claid
          }).then(({ data }) => {
            if (data.prev_id.id > 0) {
              this.$router.replace({
                name: 'Exercises',
                query: {
                  clid: data.prev_id.clid,
                  practiceId: data.prev_id.id,
                  claid: claid
                }
              })
            }
          })
        } else {
          this.isPracticeDetail = this.isGreList.pre === 2
          this.fetchListStat({ is_next: 2, is_history: 1 })
        }
      }
      if (type === 'reset') {
        this.fetchDetail()
      }
      if (type === 'select') {
        this.isPracticeDetail = item.is_gre === 2
        this.idList.current = this.isPractice ? item.question_id : item.gre_id
        this.greId = item.gre_id
        this.fetchDetail()
      }
    },

    handleOrigin() {
      const { clid, practiceId } = this.$route.query
      window.open(
        this.$router.resolve({
          name: 'Exercises',
          query: {
            clid: clid
          }
        }).href,
        '_black'
      )
    },
    async fetchChapterList() {
      const { courseId, tryId } = this.$route.query
      const { data } = await apiChapterList({
        course_id: courseId
      })
      this.courseTryList = data.reduce(
        (list, item) => list.concat(item.try_list),
        []
      )

      this.courseTryListIndex = Math.max(
        0,
        this.courseTryList.findIndex((m) => Number(m.id) === Number(tryId))
      )
    },
    getTryItem(step) {
      const max = this.courseTryList.length - 1
      let newIndex = this.courseTryListIndex + step
      newIndex = newIndex < 0 ? max : newIndex > max ? 0 : newIndex
      console.log(newIndex)
      return this.courseTryList[newIndex]
    }
  }
}

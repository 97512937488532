import { apiGreList } from '@/api/api.js'
import { debounce } from 'lodash'
import i18n from '@/i18n/i18n'

const FILTERS_LIST = [
  {
    type: 'view_cnt',
    name: i18n.locale === 'ch' ? '练习次数' : 'Number of exercises',
    list: [
      { name: i18n.locale === 'ch' ? '不限' : 'Unlimited', id: 0 },
      { name: i18n.locale === 'ch' ? '未练习' : 'Not practiced', id: 1 },
      { name: i18n.locale === 'ch' ? '低于2次' : 'Less than 2 times', id: 2 },
      { name: i18n.locale === 'ch' ? '低于5次' : 'Less than 5 times', id: 5 }
    ]
  },
  {
    type: 'is_gre',
    name: i18n.locale === 'ch' ? '是否可练' : 'Can I practice',
    list: [
      { name: i18n.locale === 'ch' ? '不限' : 'Unlimited', id: 0 },
      { name: i18n.locale === 'ch' ? '可练题目' : 'Exercisable topic', id: 2 },
      { name: i18n.locale === 'ch' ? '文本回忆' : "Textual recall", id: 1 }
    ]
  },
  {
    type: 'cate_tag',
    name: i18n.locale === 'ch' ? '题目类型' : 'Topic type',
    list: []
  },
  {
    type: 'label_id_2',
    name: i18n.locale === 'ch' ? '其他' : 'Other',
    list: [{ name: i18n.locale === 'ch' ? '不限' : "Unlimited", id: 0 }],
    excludes: [i18n.locale === 'ch' ? '高频' : 'High frequency', i18n.locale === 'ch' ? '机经题' : 'Machine Sutra questions', i18n.locale === 'ch' ? '新题' : 'New topic', i18n.locale === 'ch' ? '模拟题' : 'Simulation problem']
  },
  {
    type: 'is_like',
    name: i18n.locale === 'ch' ? '收藏' : 'Collection',
    list: [
      { name: i18n.locale === 'ch' ? '不限' : 'Unlimited', id: 0 },
      { name: i18n.locale === 'ch' ? '已收藏' : 'Collected', id: 1 },
      { name: i18n.locale === 'ch' ? '未收藏' : 'Not collected', id: 2 }
    ]
  }
]

const DEFAULT_TIP = i18n.locale === 'ch' ? '首次复习请优先准备本月预测题哦。' : 'For the first review, please give priority to preparing the prediction questions of this month.'

export default {
  props: {
    filters: {
      type: Object,
      default: () => { }
    },
    visible: {
      type: Boolean,
      default: false
    },
    search: {
      type: Function,
      default: () => { }
    },
    tagList: {
      type: Array,
      default: []
    },
    cateTagList: {
      type: Array,
      default: []
    },
    tagFilterList: {
      type: Array,
      default: []
    },
    onCancel: {
      type: Function,
      default: () => { }
    },
    cateId: {
      type: [String, Number]
    }
  },
  data() {
    return {
      isChange: false,
      total: 0,

      cFilter: {},

      countLoading: false,

      tipText: DEFAULT_TIP
    }
  },
  computed: {
    i18n() {
      return this.$t('messages')
    },
    filtersList() {
      return FILTERS_LIST.map((item) => {
        if (item.type === 'label_id_2') {
          return {
            ...item,
            list: item.list.concat(
              this.tagList.filter((m) => !item.excludes.includes(m.name))
            )
          }
        }
        if (item.type === 'cate_tag') {
          return {
            ...item,
            list: [...item.list, ...this.cateTagList]
          }
        }
        return {
          ...item
        }
      })
    }
  },
  watch: {
    visible: {
      immediate: true,
      handler() {
        if (this.visible) {
          this.cFilter = {
            ...this.filters
          }
          this.tipText = DEFAULT_TIP
        }
      }
    },
    cFilter: {
      deep: true,
      immediate: true,
      handler() {
        this.fetchList()
      }
    }
  },
  methods: {
    handleSearch() {
      this.$emit('update:filters', {
        ...this.cFilter
      })
      this.search(undefined, this.onSuccess)

      this.filterText(this.cFilter)
    },
    handleCancel() {
      this.isChange = false

      this.onCancel()
    },
    onSuccess() {
      this.isChange = false

      this.$layer.msg("<div class='success-icon'></div><div>已更新列表</div>")
    },
    selectItem(type, id) {
      this.isChange = true

      this.cFilter = {
        ...this.cFilter,
        [type]: id
      }

      // 提示显示逻辑
      switch (type) {
        case 'is_gre':
          {
            if (id === 2) {
              this.tipText = '"文本回忆"也属于真题，也是备考必看哦！'
            } else {
              this.tipText = DEFAULT_TIP
            }
          }
          break
        case 'label_id_2':
          {
            const tagItem = this.tagList.find((m) => m.id === id)
            if (tagItem.name === '新增高频') {
              this.tipText = '"新增高频"是相较上月预测新增的高频题目。'
            } else if (tagItem.name === '老题新考') {
              this.tipText =
                '"老题新考"是原真题题库中重新成为本月预测的高频题目。'
            } else {
              this.tipText = DEFAULT_TIP
            }
          }
          break
        default: {
          this.tipText = DEFAULT_TIP
        }
      }
    },
    fetchList: debounce(function () {
      if (!this.cFilter.cate_id) {
        return
      }

      this.countLoading = true
      apiGreList({
        cate_id: this.cateId,
        page: 1,
        page_size: 1,
        ...this.cFilter
      })
        .then((res) => {
          this.total = res.data.list_selected
        })
        .finally(() => {
          this.countLoading = false
        })
    }, 500),
    filterText(cFilter = this.cFilter) {
      const filteText = [{ type: 'label_id', list: this.tagFilterList }]
        .concat(this.filtersList)
        .filter((m) => cFilter[m.type] > 0)
        .map(
          (m) => m.list.find((n) => n.id === cFilter[m.type]) || { name: '' }
        )
        .map((m) => m.name)
        .filter((m) => m)
        .join('、')

      this.$emit('filterText', filteText)
    }
  }
}

<template>
  <el-tooltip
    placement="top"
    effect="dark"
    :content="`${i18n.Pra.AI}${count}${i18n.Pra.second}`"
    v-if="!userInfo.vip || userInfo.vip.code == 0"
  >
    <div class="vip-count">
      <span class="ai">AI</span>
      <span>x{{ count }}</span>
    </div>
  </el-tooltip>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('app', {
      userInfo: (state) => state.userInfo,
    }),
    count() {
      return Math.max(0, 5 - this.userInfo.ai_today_used_cnt)
    },
    i18n() {
      return this.$t('messages')
    },
  },
}
</script>

<style scoped lang="scss">
.vip-count {
  padding: 5px 12px;
  margin-right: 30px;
  background-color: #f5f5f5;
  border-radius: 5px;
  cursor: pointer;
  .ai {
    line-height: 20px;
    text-align: center;
    display: inline-block;
    width: 18px;
    height: 18px;
    background: #bfbfbf;
    border-radius: 5px;
    color: #fff;
    font-size: 12px;
    margin-right: 4px;
  }
  > span {
    font-size: 12px;
    color: #bfbfbf;
  }
}
</style>

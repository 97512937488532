import { apiErrorReport } from '@/api/api.js'
import i18n from '@/i18n/i18n'


const LIST = [
  {
    name: i18n.locale === 'ch' ? '无法提交' : 'Unable to submit',
    value: 2,
  },
  {
    name: i18n.locale === 'ch' ? '答案错误' : 'Wrong answer',
    value: 3,
  },
  {
    name: i18n.locale === 'ch' ? '拼写错误' : 'Misspelling',
    value: 4,
  },
  {
    name: i18n.locale === 'ch' ? '文本与音频不符' : 'Text does not match audio',
    value: 5,
  },
  {
    name: i18n.locale === 'ch' ? '其他' : 'other',
    value: 1,
  }
]
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [Number, String]
    }
  },
  data() {
    return {
      errorType: '',
      remark: '',
      list: LIST,
    }
  },
  computed: {
    i18n() {
      return this.$t('messages')
    },
  },
  methods: {
    handleClose() {
      this.$emit('closeDialog')
    },
    submit() {
      if (!this.remark && !this.errorType) {
        return
      }
      apiErrorReport({
        gre_id: this.id,
        error_type: this.errorType,
        remark: this.remark
      }).then((res) => {
        if (res.code != 200) {
          return
        }
        this.$layer.msg(`"<div class='success-icon'></div><div>${ i18n.locale === 'ch' ?'提交成功':'Submitted successfully' }</div>"`)
        this.handleClose()
      })
    }
  }
}
import {
  apiWebQuestionSave,
  apiAiText,
  apiWebTranslate,
  apiWebRecordWord,
  apiAiCore
} from '@/api/api.js'
import draggable from 'vuedraggable'
import canAutoPlay from 'can-autoplay'
import AudioTool from '@/components/AudioTool/AudioTool.vue'
import AudioToolMini from '@/components/AudioToolMini/AudioToolMini.vue'
import AudioToolDefault from '@/components/AudioToolDefault/AudioToolDefault.vue'
import Recorder from '@/components/ExercisesContent/components/Recorder/Recorder.vue'
import AudioPlayer from '@/components/AudioPlayer/AudioPlayer.vue'
import TransferSelect from '@/components/ExercisesContent/components/transfer-select.vue'
import AudioError from '@/components/ExercisesContent/components/audio-error.vue'
import CountDown from '@/components/CountDown/CountDown.vue'
import AiProgress from '../aiProgress'
import AiCore from '../aiCore'
import AiVip from '../aiVip'

import { mapState, mapActions } from 'vuex'
import { cloneDeep } from 'lodash'
import SubmitProgress from '@/components/submitProgress'
import TranslateWord from '@/components/TranslateWord'
import { wordSpaceCalc, symbolsFix } from '@/utils/parctice'
import AiContent from './aiContent'
import WordDiff from '@/components/wordDiff'
import WordMark from '@/components/wordDiff/markWordMore'
import emitter from '@/utils/emitter'

import * as upload from '@/utils/upload'

let idGlobal = 8

const randomList = (list) => {
  return (list || [])
    .map((item, index) => ({
      ...(typeof item !== 'object'
        ? { text: item }
        : Array.isArray(item)
        ? { text: item }
        : item),
      randomIndex: index
    }))
    .sort(() => Math.random() - 0.5)
}

export default {
  components: {
    draggable,
    AudioTool,
    Recorder,
    AudioPlayer,
    TransferSelect,
    CountDown,
    AudioToolMini,
    AudioToolDefault,
    AudioError,
    TranslateWord,
    AiContent,
    WordDiff,
    WordMark,
    SubmitProgress,
    AiProgress,
    AiCore,
    AiVip
  },
  props: {
    title: String,
    questionId: null,
    status: null,
    claId: null,
    chapterId: null,
    detail: {},
    typeInfo: {},
    saveSuccess: {},
    startRecorder: {}
  },
  data() {
    return {
      // 模块显示
      greTitleView: false,
      greTextView: false,
      greAudioView: false,
      greSelectView: false,
      greRadioSelectView: false,
      greMyPracticeView: false,
      greSubmitView: false,
      greDragView: false,
      greFillView: false,
      greFillInputView: false,
      greRecorderView: false,
      greRecorderViews: false,
      tipsViews: true,
      questionAnswer: false,

      // 机经详情
      greTitle: '',
      greSelectList: [], //选择题数据
      greSelectTitle: '', //选择题问题
      greSelectedList: [],
      greSelected: '', //已选择的数据
      greRadioTitle: '',
      greRadioSelectList: [], //选择题数据
      greRadioSelectTitle: '', //选择题问题
      greMcsSelectTitle: '',
      greRadioSelectedList: [],
      greRadioSelected: '', //已选择的数据
      greText: [], //题目内容
      greTip: '', //提示信息
      greDragList: [], //拖拽数据
      grePracticeText: '', //我的练习内容
      grePracticeTextNum: 0, //练习字数统计
      grePracticeTextIpt: '',
      greAudio: '', //音频数据
      greRecorder: '', //录音数据
      greCommonData: [], //公用数组
      greImgSrc: null, //图片链接
      recorderUrl: '', //录音链接
      recorderArticle: null, //录音识别数据
      recorderCore: null,
      isStartGenCore: false,

      // 填空题
      greFill: [],
      greFillOption: [],
      greFillResultOption: [],
      greFillSelected: [],

      // 切换按钮
      btnShowGist: false, //显示主旨
      btnShowTranslate: false, //显示翻译
      btnPunctuation: false, //建议断句
      btnLoud: false, //建议重读
      recorderTextView: false, //建议意群开关控制

      // 加载
      loading: false,

      // 传给父组件的值
      toDadyData: {
        count: null,
        collected: false,
        data: null
      },
      checkList: [],
      uploadLoading: false,
      startRecorderTimer: false,
      list1: [],
      controlOnStart: true,
      answerDetail: null,
      selectDraggableIndex: -1,
      timerTime: 0,
      progress: 0,
      audioEnded: false,
      done: false,
      reg: /\"|\“/,
      regNumber: /[0-9]/,
      bigImgSrc: '',
      showBigImg: false,
      cReg: /[\u4e00-\u9fa5]/,
      wordContent: { index: -1 },
      sortList: [],
      loadingTranslate: false,
      palyTranslateAudio: false,

      submitQuesData: null
    }
  },
  computed: {
    ...mapState('app', {
      userInfo: (state) => state.userInfo
    }),
    i18n() {
      return this.$t('messages')
    },
    fillSelected() {
      return this.greFillSelected.map((item) => item[0])
    },
    titleInContent() {
      return (
        this.typeInfo.classify === 'reading' &&
        ['MCS', 'MCM'].includes(this.typeInfo.type)
      )
    },
    textContentList() {
      if (!this.detail.content_text) {
        return []
      }

      const content = symbolsFix(this.detail.content_text)
      if (this.title === 'RA') {
        return content
          .replace(/([^\/])(\/)/g, '$1 $2')
          .replace(/(\/)([^\/])/g, '$1 $2')
          .replace(/(\S)(\/\/)/g, '$1 $2')
          .replace(/(\/\/)(\S)/g, '$1 $2')
          .replace(/(\|)(\S)/g, '$1 $2')
          .replace(/(\S)(\|)/g, '$1 $2')
          .replace(/\r.?\n/g, '\n')
          .split(/[^\S\r\n]/g)
          .map((text) => {
            const stressIndexs = text
              .split('')
              .reduce(
                (indexs, str, index) =>
                  indexs.concat(str === '(' ? [index - indexs.length] : []),
                []
              )
            const isInterval = /\/|\|/g.test(text)
            return {
              interval: isInterval,
              lang: isInterval ? 'default' : 'en',
              stress: stressIndexs,
              text: text === '|' ? '//' : text.replace(/\(/g, '')
            }
          })
          .filter((m) => m.text)
      }
      if (this.title === 'SWT') {
        let isRed = false
        return content
          .replace(/\r.?\n/g, '\n')
          .split(/[^\S\r\n]/g)
          .map((text) => {
            let currentIsRed = isRed
            if (/\[/g.test(text)) {
              currentIsRed = isRed = true
            }
            if (/\]/g.test(text)) {
              currentIsRed = true
              isRed = false
            }

            return {
              color: currentIsRed ? 'red' : 'black',
              lang: 'en',
              text: text.replace(/[\[\]]/g, '')
            }
          })
      }
      if (this.title === 'WE') {
        return content
          .replace(/\r.?\n/g, '\n')
          .split(/[^\S\r\n]/g)
          .map((text) => {
            return {
              lang: 'en',
              text: text,
              underline: ''
            }
          })
      }
      if (this.title === 'RFIB') {
        const answerList = (this.answerDetail
          ? this.answerDetail.answer.article
          : []
        ).filter((m) => /_/g.test(m.text))
        let optsCount = -1
        return content
          .replace(/\r.?\n/g, '\n')
          .split(/[^\S\r\n]/g)
          .map((text) => {
            const isLine = /_/g.test(text)
            if (isLine) {
              optsCount += 1
            }
            const answerData = answerList[optsCount] || {}
            return {
              is_underline: isLine,
              lang: isLine ? 'default' : 'en',
              text: text,
              ...(isLine ? { count: optsCount } : {}),
              ...(isLine ? answerData : {})
            }
          })
      }
      if (this.title === 'RWFIB') {
        const matchResult = content.match(/\[[^\]]+\]/g)
        const opts = matchResult ? matchResult.map((str) =>
          str
            .replace(/[\[\]]/g, '')
            .split(',')
            .map((m) => m.trim())
        ) : []
        const answerList = (this.answerDetail
          ? this.answerDetail.answer.article
          : []
        ).filter((m) => /_/g.test(m.text))
        let optCount = -1
        return content
          .replace(/\[[^\]]+\]/g, '_')
          .replace(/\r.?\n/g, '\n')
          .split(/[^\S\r\n]/g)
          .map((text) => {
            const isSelect = /_/g.test(text)
            if (isSelect) {
              optCount += 1
            }
            const answerData = answerList[optCount] || {}
            return {
              choose: randomList(isSelect ? opts[optCount] || [] : []),
              lang: 'en',
              text: text,
              ...(isSelect ? { count: optCount } : {}),
              ...(isSelect ? answerData : {})
            }
          })
      }
      if (this.title === 'FIB') {
        const answerList = (this.answerDetail
          ? this.answerDetail.answer.article
          : []
        ).filter((m) => /_/g.test(m.text))
        let optCount = -1
        return content
          .replace(/\r.?\n/g, '\n')
          .split(/[^\S\r\n]/g)
          .map((text) => {
            const isIpt = /_/g.test(text)
            if (isIpt) {
              optCount += 1
            }
            const answerData = answerList[optCount] || {}
            return {
              text: text,
              lang: isIpt ? 'default' : 'en',
              isEmpty: isIpt,
              ...(isIpt ? { count: optCount } : {}),
              ...(isIpt ? answerData : {})
            }
          })
      }
      if (this.title === 'HIW') {
        // TODO: 等答案结果
        const answerList = this.answerDetail
          ? this.answerDetail.answer.article
          : []
        return content.split(/[^\S\r\n]/g).map((text, index) => {
          return {
            lang: 'en',
            text: text,
            index
          }
        })
      }
    }
  },
  watch: {
    greSubmitView(val) {
      this.$emit('chang-submit', val)
    },
    loading(val) {
      this.$emit('loading-submit', val)
    },
    greSelectedList(val) {
      this.greSubmitView = val.length > 0
    },
    greRadioSelectedList(val) {
      this.greSubmitView = val.length > 0
    },
    greFillSelected(val) {
      if (this.typeInfo.type === 'RFIB') {
        this.greSubmitView = val.filter((item) => item[0]).length > 0
      } else {
        this.greSubmitView = val.filter((item) => item).length > 0
      }
    },
    grePracticeText(val) {
      this.greSubmitView = !!val && !this.done
    },
    sortList(val) {
      this.greSubmitView = val.length > 0
    }
  },
  mounted() {
    this.getQuestion()
    this.startRecorderTimer = ['RA', 'DI'].includes(this.typeInfo.type)
  },
  methods: {
    ...mapActions('app', ['GetUserInfo']),
    wordSpace(greRecorder, item, index) {
      return wordSpaceCalc(greRecorder, item, index)
    },
    hidePopover() {
      this.palyTranslateAudio = false
      if (this.wordContent.word) {
        this.wordContent = { index: -1 }
      }
    },
    addWord(word) {
      apiWebRecordWord({
        words: word
      }).then((res) => {
        if (res.code !== 200) {
          return
        }
        this.wordContent.added = 1
        this.$layer.msg(
          `<div class='success-icon'></div><div>已加入生词本</div>`
        )
      })
    },
    palyTranslate(auto) {
      this.$nextTick(() => {
        if (this.$refs.audio && this.$refs.audio[0]) {
          this.$refs.audio[0].play()
          if (!auto) {
            this.palyTranslateAudio = true
            setTimeout(() => {
              this.palyTranslateAudio = false
            }, 1000)
          }
        }
      })
    },
    translateWord(word, index) {
      this.wordContent = { index: -1 }
      const reg = /^[a-z0-9\']+$/i
      const isWord = reg.test(word)
      if (!isWord && word) {
        return
      }
      this.wordContent.index = index
      this.loadingTranslate = true
      apiWebTranslate({ contents: word })
        .then((res) => {
          this.wordContent = {
            ...this.wordContent,
            ...res.data
          }
          this.palyTranslate(true)
        })
        .finally(() => {
          this.loadingTranslate = false
        })
    },
    handleBigImg() {
      this.showBigImg = true
    },
    audioEnd() {
      this.audioEnded = true
      this.$emit("audioEnd","next")
    },
    clear(index) {
      this.greFillSelected = this.greFillSelected.map((m, i) =>
        i === index ? [] : m
      )

      this.handleSelected(null)
    },
    end() {
      this.selectDraggableIndex = -1
    },
    choose(w) {
      this.selectDraggableIndex = w.oldIndex
    },
    log: function(evt) {
      window.console.log(evt)
    },
    start({ originalEvent }) {
      console.log(originalEvent)
      this.controlOnStart = originalEvent.ctrlKey
    },
    getQuestion() {
      if (this.questionId == null) {
        return
      }
      let paramsBase = {
        id: this.questionId
      }
      this.greSelectedList = []
      // 传给父组件的值赋值
      this.toDadyData.count = this.detail.top.time
      this.toDadyData.collected = this.detail.top.collected
      this.toDadyData.data = this.detail
      this.toDady()
      // 添加关键字段到session
      let questionDetail = {
        id: this.detail.id,
        title: this.detail.title,
        collected: this.detail.top.collected,
        time: this.detail.top.time,
        previousID: this.detail.previousID,
        nextId: this.detail.nextId,
        all: this.detail.top.all,
        crt: this.detail.top.crt,
        subtitles: this.detail.subtitles,
        content: this.detail.content
      }
      // console.log(this.detail.top.collected)
      // console.log('传给session的题目的值', questionDetail)
      // sessionStorage.setItem(
      //   "questionDetail",
      //   JSON.stringify(questionDetail)
      // );

      let optionCount = 1 //选项计数
      // 公共赋值
      this.greTitle = this.detail.title
      this.greTitleView = true
      this.greFill = this.greFillSelected = []

      if (
        'MCM'.indexOf(this.title) >= 0 &&
        this.typeInfo.classify === 'reading'
      ) {
        this.greTitle = this.detail.check.title
        this.greSelectTitle = this.detail.check.stem
        this.greSelectList = randomList(this.detail.check.options)

        this.greSelectView = true
        // if (this.status == null) {
        //   this.greSubmitView = true;
        // }
      }
      if ('RO'.indexOf(this.title) >= 0) {
        this.greDragList = randomList(this.detail.check.items).map(
          (item, index) => ({
            ...item,
            index
          })
        )
        this.greDragView = true
        // if (this.status == null) {
        //   this.greSubmitView = true;
        // }
      }
      if ('RFIB'.indexOf(this.title) >= 0 && this.title != 'FIB') {
        // 填空题构建
        this.greFillOption = randomList(this.detail.content.choose)
        this.greFillResultOption = cloneDeep(this.greFillOption)
        this.greFill = this.detail.content.article
        optionCount = 0 //选项计数
        for (let i = 0; i < this.greFill.length; i++) {
          if (this.greFill[i].text == '_') {
            this.greFillSelected.push([])
            this.greFill[i].count = optionCount
            optionCount = optionCount + 1
          }
        }
        this.greFillView = true
        // if (this.status == null) {
        //   this.greSubmitView = true;
        // }
      }
      if ('RWFIB'.indexOf(this.title) >= 0 && this.title != 'FIB') {
        this.greFill = this.detail.content.article.map((item) => ({
          ...item,
          choose: randomList(item.choose)
        }))
        this.greFillView = true
        // if (this.status == null) {
        //   this.greSubmitView = true;
        // }
        optionCount = 0 //选项计数
        for (let i = 0; i < this.greFill.length; i++) {
          if (this.greFill[i].text == '_') {
            this.greFillSelected.push('')
            this.greFill[i].count = optionCount
            optionCount = optionCount + 1
          }
        }
        // console.log(this.greFill)
      }
      if ('SWT,WE'.indexOf(this.title) >= 0) {
        this.greText = this.detail
        this.greTextView = true
        if (this.status == null) {
          // this.greSubmitView = true;
          this.greMyPracticeView = true
        }
      }
      if ('SST,WFD'.indexOf(this.title) >= 0) {
        this.greAudio = this.detail.audio
        this.greAudioView = true
        if (this.status == null) {
          // this.greSubmitView = true;
          this.greMyPracticeView = true
        }
      }
      if ('WFD'.indexOf(this.title) >= 0) {
        this.tipsViews = false
      }
      if ('MCM'.indexOf(this.title) >= 0) {
        if (this.typeInfo.classify === 'reading') {
          this.greSelectTitle = this.detail.check.stem
        } else {
          this.greSelectTitle = this.detail.check.title
        }
        this.greSelectList = randomList(this.detail.check.options)

        this.greAudio = this.detail.audio
        this.greAudioView = this.greSelectView = true
        if (this.status == null) {
          // this.greSubmitView = true;
        }

        window.console.log('greAudio:', this.greAudio, this.greAudioView)
      }
      if ('MCS,SMW,HCS'.indexOf(this.title) >= 0) {
        this.greAudio = this.detail.audio
        this.greAudioView = this.greSelectView = true
        this.greRadioTitle = this.detail.check.title
        this.greRadioSelectTitle = this.detail.check.title
        this.greMcsSelectTitle = this.detail.check.stem
        this.greRadioSelectList = randomList(this.detail.check.options)

        this.greRadioSelectView = true
        if (this.status == null) {
          // this.greSubmitView = true;
        }
      }
      if ('FIB'.indexOf(this.title) >= 0) {
        this.greAudio = this.detail.audio
        this.greFill = this.detail.content.article
        optionCount = 0 //input计数
        for (let i = 0; i < this.greFill.length; i++) {
          if (this.greFill[i].text == '_') {
            this.greFillSelected.push('')
            this.greFill[i].count = optionCount
            optionCount = optionCount + 1
          }
        }
        this.greAudioView = this.greFillInputView = true
        if (this.status == null) {
          // this.greSubmitView = true;
        }
      }
      if ('HIW'.indexOf(this.title) >= 0) {
        this.greAudio = this.detail.audio
        this.greText = this.detail.content.article.map((item, index) => {
          return {
            ...item,
            index
          }
        })
        for (let i = 0; i < this.greText.length; i++) {
          this.greText[i].active = false
        }
        console.log(this.greText)
        this.greAudioView = this.greTextView = true
        if (this.status == null) {
          // this.greSubmitView = true;
        }
      }
      if ('RA'.indexOf(this.title) >= 0) {
        this.greRecorder = this.detail.content.article
        this.greRecorderView = true
        if (this.status == null) {
          this.greRecorderViews = true
        }
      }
      if ('RS'.indexOf(this.title) >= 0) {
        this.greRecorder = this.detail.subtitles.article
        this.greAudio = this.detail.audio
        this.greCommonData = this.detail.sense_group.content
        this.greAudioView = true
        this.greRecorderView = true
        if (this.status == null) {
          this.greRecorderViews = true
        }
        this.btnLoud = this.btnPunctuation = true
      }
      if ('DI'.indexOf(this.title) >= 0) {
        this.greImgSrc = this.detail.img.url
        this.greRecorderView = true
        if (this.status == null) {
          this.greRecorderViews = true
        }
      }
      if ('RL'.indexOf(this.title) >= 0) {
        this.greRecorder = this.detail.subtitles.article
        this.greAudio = this.detail.audio
        this.greAudioView = true
        this.greRecorderView = true
        if (this.status == null) {
          this.greRecorderViews = true
        }
      }
      if ('ASQ'.indexOf(this.title) >= 0) {
        if (this.detail.audio.url.length > 0) {
          this.greAudio = this.detail.audio
          this.greAudioView = true
        } else {
          this.greAudioView = false
        }
        this.greImgSrc = this.detail.img_url
        this.greRecorderView = true
        if (this.status == null) {
          this.greRecorderViews = true
        }
      }

      if (this.greRecorderView) {
        this.recorderUrl = null
        this.recorderArticle = null
        this.recorderCore = null
      }
      this.$nextTick(() => {
        this.isSupportAutoPlay().then(({ result }) => {
          if (result === true) {
            // Can auto-play
          } else {
            this.$emit('record')
            this.$emit('unSupportAutoPlay')
          }
        })
      })
    },
    // 点击单词
    tapWord(e) {
      if (this.done) {
        return
      }
      if (e.active) {
        e.active = false
        this.greFillSelected.forEach((item, index) => {
          if (item == e.index) {
            this.greFillSelected.splice(index, 1)
          }
        })
      } else {
        e.active = true
        this.greFillSelected.push(e.index)
      }
      this.$forceUpdate()
    },
    // 给父组件传值
    toDady() {
      this.$emit('getMySon', this.toDadyData)
    },
    // 给父组件传递音频状态
    getAudio(data, timerTime) {
      this.timerTime = timerTime
      this.startRecorderTimer = true
      this.$emit('audioPlay', data)
    },

    waitUploadLoading() {
      return new Promise((resolve) => {
        const timeId = setInterval(() => {
          if (!this.uploadLoading) {
            clearInterval(timeId)
            resolve()
          }
        }, 200)
      })
    },
    // 提交问题
    async submitQuestion() {
      if (this.loading) {
        return
      }

      if (!this.greSubmitView) return
      if (this.greMyPracticeView && !this.grePracticeText) {
        this.$layer.msg("<div class='error-icon'></div><div>请输入内容</div>")
        return
      }
      this.loading = true

      await this.waitUploadLoading()

      let contents = []
      var formData = new window.FormData()
      formData.append('qid', this.questionId)
      formData.append('claid', this.claId)
      formData.append('chapter_id', this.chapterId)
      /*
                  if (this.greSelected.length != '') {
                      // 暂无数据
                      formData.append("contents[0]", this.greSelected);
                  }*/

      if (this.greSelectedList.length > 0) {
        // 暂无数据
        this.greSelectedList.forEach((item, index) => {
          formData.append(
            'contents[' + index + ']',
            this.greSelectList[item].randomIndex
          )
        })
      }

      if (this.greRadioSelectedList.length > 0) {
        formData.append(
          'contents[0]',
          this.greRadioSelectList.find((m) =>
            this.greRadioSelectedList.includes(m.text)
          ).randomIndex
        )
      }

      if (this.grePracticeText.length != '') {
        formData.append('contents[0]', this.grePracticeText)
      }
      const greDragList = this.$refs.sort && this.$refs.sort.localList
      if (greDragList && greDragList.length > 0) {
        greDragList.forEach((item, index) => {
          formData.append('contents[' + [index] + ']', item.randomIndex)
        })
      }
      if (this.greFillSelected.length > 0) {
        if (this.typeInfo.type === 'RFIB') {
          this.fillSelected.forEach((element, index) => {
            console.log(element, index)
            formData.append(
              'contents[' + [index] + ']',
              element ? element.text || '' : ''
            )
          })
        } else {
          this.greFillSelected.forEach((element, index) => {
            formData.append('contents[' + [index] + ']', element)
          })
        }
      }
      if (this.recorderUrl) {
        formData.append('oss_url', this.recorderUrl)
      }
      let paramsBase = formData

      // 表单数据传递到这个API下，在这个API里面计算正确率，答案，得分
      return apiWebQuestionSave(paramsBase)
        .then((res) => {
          if (res.code !== 200) {
            return
          }
          this.submitQuesData = res.data

          this.saveSuccess && this.saveSuccess()
          this.greSubmitView = false
          this.done = true
          // sessionStorage.setItem("claid", null);
          this.$layer.msg(
            `<div class='success-icon'></div><div>提交成功 (${res.data.my_try_cnt})</div>`
          )
          // sessionStorage.setItem("answerDetail", JSON.stringify(res.data));
          if (
            [
              'RO',
              'RWFIB',
              'MCS',
              'MCM',
              'FIB',
              'WFD',
              'HIW',
              'RFIB',
              'SMW',
              'HCS'
            ].includes(this.title) &&
            res.data &&
            res.data.answer
          ) {
            if (['RWFIB', 'RFIB', 'FIB'].includes(this.title)) {
              this.greFill = res.data.answer.article
            }
            if (['HIW'].includes(this.title)) {
              this.greText = res.data.answer.article
            }
            if (
              ['WFD'].includes(this.title) &&
              res.data.myAnswer &&
              res.data.myAnswer.article
            ) {
              this.grePracticeText = res.data.myAnswer.article
            }

            let params = {}
            paramsBase.forEach((value, key) => (params[key] = value))

            this.answerDetail = {
              ...res.data,
              params
            }

            console.log(this.answerDetail)
          }
          if (
            [
              'MCM',
              'MCS',
              'RO',
              'RFIB',
              'RWFIB',
              'HCS',
              'SMW',
              'HIW',
              'FIB',
              'WFD',
              'ASQ'
            ].includes(this.title) &&
            res.data.score_info
          ) {
            this.answerDetail.hasScore = true
          }
          // this.$parent.childSubmit();

          // this.genAI()

          setTimeout(() => {
            emitter.emit('SCROLL_TOP', this.$el.clientHeight)
          }, 1000)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getMsgFormSon(data) {
      var name = this.getMath() + this.getMath() + this.getMath() + '.wav'
      this.recorderUrl = data
      this.greSubmitView = true

      this.uploadLoading = true
      upload.file({
        file: data,
        fileName: name,
        onSuccess: ({ data }) => {
          this.recorderUrl = data.oss_url
          this.progress = 0

          apiAiText({
            oss_url: data.oss_url,
            question_id: this.questionId
          }).then((res) => {
            this.uploadLoading = false
            if (res.code !== 200) {
              return
            }
            if (res.data && res.data.length != 0) {
              this.recorderArticle = res.data
            }
          })
        },
        onProgress: (progress) => {
          this.progress = progress
        }
      })
    },
    // ai
    handleGenAI() {
      try {
        if (
          (!this.userInfo.vip || this.userInfo.vip.code == 0) &&
          this.userInfo.ai_today_used_cnt >= 5
        ) {
          return this.$refs.aiVip.open()
        }
      } catch (e) {}

      this.isStartGenCore = true
      Promise.all([
        apiAiCore({
          uqid: this.submitQuesData.uqid,
          ref_text: this.detail.question_answer,
          oss_url: this.recorderUrl,
          type: this.typeInfo.type
        })
        // apiAiText({
        //   oss_url: this.recorderUrl,
        //   question_id: this.questionId
        // })
      ]).then(([coreRes]) => {
        if (coreRes.code !== 200) {
          this.isStartGenCore = false
          return false
        }

        this.isStartGenCore = false

        // if (textRes.code !== 200) {
        //   return
        // }
        // if (textRes.data && textRes.data.length != 0) {
        //   this.recorderArticle = textRes.data
        // }

        this.recorderCore = coreRes.data

        this.saveSuccess && this.saveSuccess()
        this.$refs.aiCore.open({
          ...this.recorderCore,
          contents: this.recorderUrl
        })

        this.$refs.aiProgress.close()

        // 获取次数
        this.GetUserInfo()
      })
    },
    showAiCore() {
      this.$refs.aiCore.open({
        ...this.recorderCore,
        contents: this.recorderUrl
      })
    },
    //字数统计
    descInput() {
      var reg = /(\w)+/gi
      const val = this.grePracticeText.match(reg) || []
      var txtVal = val.length
      this.grePracticeTextNum = txtVal

      this.grePracticeTextIpt = this.grePracticeText
    },
    //生成随机数
    getMath() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    },

    clearValue() {
      this.grePracticeText = ''
    },

    playAudio() {
      if (
        this.greAudioView &&
        this.greAudio != '' &&
        this.greAudio != undefined
      ) {
        this.$refs.audioToolRef.playAudio()
      }
    },

    record() {
      this.$emit('record')
      this.recorderUrl = null
      this.recorderArticle = null
      this.recorderCore = null
    },

    isSupportAutoPlay() {
      return canAutoPlay.audio()
    },

    handlereCorderAgain() {
      this.recorderUrl = null

      this.$refs.recorder.record()
    },
    handleShowAiContent() {
      this.$refs.aiContent.open()
    },
    setFuncValue(type, value) {
      this[type] = value
    },

    showAiProgress() {
      this.$refs.aiProgress.open()
    },

    handleSelected(item) {
      this.greFillResultOption = this.greFillResultOption.map((m) => ({
        ...m,
        selected: m === item ? true : false
      }))
    },
    handleInputSelected(item) {
      const selected = this.greFillResultOption.find((m) => m.selected)
      if (selected) {
        this.greFillSelected = this.greFillSelected.map((m, index) => {
          if (index === item.count) {
            return [{ ...selected }]
          }
          return m
        })
      }

      this.handleSelected(null)
    }
  }
}

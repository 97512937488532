var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"progressContainer"},[_c('div',{staticClass:"progress",style:({
      width: `${_vm.percentage === '-' ? 100 : _vm.percentage}%`,
      background:
        _vm.percentage === '-'
          ? '#ddd'
          : _vm.type === 'warning'
          ? '#FFC06A'
          : '#3b86ff'
    })})])
}
var staticRenderFns = []

export { render, staticRenderFns }
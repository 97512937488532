import AskQuestions from '@/components/ExercisesTabs/components/ask-questions/ask-questions.vue'
import VideoDialog from '@/components/VideoDialog/VideoDialog.vue'
import Empty from '@/components/ExercisesEmpty.vue'
import TabsBtn from '@/components/tabs/btn'
import i18n from '@/i18n/i18n'
import TranslateWord from '@/components/TranslateWord'


const TYPE_LIST = [
  { name: i18n.locale === 'ch' ? '答案' : 'Answer', value: 'answer' },
  { name: i18n.locale === 'ch' ? '解析' : 'Analysis', value: 'parse' }
]

export default {
  components: {
    TabsBtn,
    AskQuestions,
    VideoDialog,
    Empty,
    TranslateWord
  },
  props: {
    id: {
      type: [Number, String]
    },
    isPractice: {
      type: Boolean
    },
    detail: {
      type: Object,
      default: () => { }
    },
    greId: {
      type: [Number, String]
    },
    typeInfo: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      showAskDialog: false,
      showVideoDialog: false,
      text: '',
      textList: [],

      currType: 'answer'
    }
  },
  computed: {
    typeList() {
      return TYPE_LIST.map((item) => ({
        ...item
      }))
    },
  },
  created() {
    const contents = this.detail.question_answer
    if (typeof contents === 'string') {
      if (['MCM', 'MCS', 'HCS', 'SMW'].includes(this.typeInfo.type)) {
        const list = contents.split('||')
        this.textList = list.map((item) => {
          const index = Number(item.trim())
          return {
            index: String.fromCharCode(65 + index),
            content: this.detail.check.options[index].text
          }
        })
      } else if (['RO'].includes(this.typeInfo.type)) {
        const list = contents.split('||')
        this.textList = list.map((item) => {
          const index = Number(item.trim())
          return {
            index: index + 1,
            content: this.detail.check.items[index].text
          }
        })
      } else if (['RFIB', 'RWFIB', 'FIB'].includes(this.typeInfo.type)) {
        const list = contents.split('||')
        const letterList = list.map((item) => item.trim())
        this.text = letterList.join(' ')
      } else {
        this.text = contents
      }
    }
  },
  methods: {
    handleType({ value }) {
      this.currType = value
    },
    handleAskDialog() {
      this.showAskDialog = !this.showAskDialog
    },
    handleVideoDialog() {
      this.showVideoDialog = !this.showVideoDialog
    }
  }
}

<template>
  <el-dialog
    title="分享练习"
    width="630px"
    :visible="visible"
    @close="visible = false"
  >
    <div class="content">
      <div class="link-wrap">
        <div>练习链接</div>
        <div class="ipt-wrap">
          <el-input readonly :value="h5Link" />
          <el-button type="primary" @click="handleCopy">复制</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import copyText from '@/utils/clipboard'

export default {
  data() {
    return {
      visible: false,
      pracitceData: 0
    }
  },
  computed: {
    h5Link() {
      const { id, qid } = this.pracitceData
      return `https://weixin.ptego.com/#/pages/exp/index?id=${qid}&pracitceId=${id}`
    }
  },
  methods: {
    open(item) {
      this.pracitceData = item
      this.visible = true
    },
    handleCopy() {
      copyText(this.h5Link)

      this.$message.success('复制成功')
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  color: #1f1f40;
  font-size: 14px;
  text-align: center;
  padding-bottom: 30px;

  .title {
    color: #1f1f40;
    font-size: 14px;
  }

  > img {
    width: 110px;
    height: 110px;
    margin-top: 18px;
  }

  .tip {
    color: #9e9e9e;
  }

  .link-wrap {
    text-align: left;
    margin-top: 20px;

    .ipt-wrap {
      display: flex;
      align-items: center;

      ::v-deep .el-button {
        margin-left: 10px;
        background-color: #3b86ff;
        border-color: #3b86ff;
        min-width: 100px;
      }
    }
  }
}
</style>

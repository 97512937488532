<template>
  <ul class="component-tabs-btn">
    <li
      v-for="(item, index) of list"
      :key="index"
      :class="{ active: activeIndex === index }"
      @click="handleClick(index)"
    >
      {{ item.name }}
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    defaultValue: {
      type: Number,
      default: 0
    },
    onClick: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      activeIndex: 0
    }
  },
  methods: {
    handleClick(index) {
      this.activeIndex = index

      this.onClick(this.list[index], index)
    }
  }
}
</script>

<style scoped lang="scss">
.component-tabs-btn {
  display: flex;
  align-items: center;
  background-color: #f4f4f4;
  border-radius: 5px;
  padding: 4px;
  > li {
    padding: 4px;
    min-width: 62px;
    font-size: 12px;
    font-weight: normal;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
    color: #1f1f40;
    &.active {
      background-color: #fff;
      color: #3b86ff;
    }
  }
}
</style>

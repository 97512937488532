<template>
  <el-tooltip
    v-model="tipVisible"
    placement="top"
    effect="dark"
    :content="tipMsg"
  >
    <div class="submit">
      <el-button
        type="primary"
        @click="handleClick"
        :disabled="tipMsg === tipMsgArray[4] || disabled"
        :loading="loading"
        :size="size"
        class="btn"
        :class="{ 'btn-active': loading }"
      >
        {{ btnText }}
      </el-button>
    </div>
  </el-tooltip>
</template>

<script>
import i18n from '@/i18n/i18n'

const TIP_MSG = [
  i18n.locale === 'ch'
    ? '请先做题，再提交'
    : 'Please make a question before submitting it',
  i18n.locale === 'ch' ? '提交中...' : 'Submitting',
  i18n.locale === 'ch'
    ? '提交中...若等待过久，请尝试切换网络后重试。'
    : 'Submitting... If you wait too long, please try switching the network and try again.',
  i18n.locale === 'ch' ? '点击提交' : 'Click Submit',
  i18n.locale === 'ch' ? '已提交' : 'Submitted',
]
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'mini',
    },
    claId: {
      type: Number | String,
    },
  },
  data() {
    return {
      progress: 0,
      tipMsg: TIP_MSG[0],
      tipVisible: false,
    }
  },
  computed: {
    btnText() {
      return this.loading
        ? `${i18n.locale === 'ch' ? '提交' : 'Submit'}(${this.progress}%)`
        : this.tipMsg === this.tipMsgArray[4] && this.disabled
        ? i18n.locale === 'ch'
          ? '已提交'
          : 'Submitted'
        : i18n.locale === 'ch'
        ? '提交'
        : 'Submit'
    },
    tipMsgArray() {
      const last = TIP_MSG.slice(-1)
      return [].concat(
        TIP_MSG.slice(0, -1),
        this.claId ? [ i18n.locale === 'ch'?'作业仅支持提交一次':'Jobs can only be submitted once'] : last
      )
    },
  },
  watch: {
    loading: {
      handler() {
        if (!this.loading) {
          this.initData()
          this.tipMsg = this.tipMsgArray[4]
        }
      },
    },
    disabled: {
      immediate: true,
      handler() {
        this.initData()
        if (!this.disabled) {
          this.tipMsg = this.tipMsgArray[3]
        }
      },
    },
  },
  mounted() {
    this.initData()
  },
  methods: {
    handleClick() {
      this.$emit('click')

      this.runTime()
    },
    initData() {
      clearInterval(this.timeId)

      this.progress = 0
      this.tipMsg = this.tipMsgArray[0]
      this.tipVisible = false
    },
    runTime() {
      this.progress = 0
      this.tipMsg = this.tipMsgArray[1]
      this.timeId = setInterval(() => {
        this.progress = Math.floor(
          Math.min(99, this.progress + 20 * Math.random())
        )
        if (this.progress === 99) {
          this.tipMsg = this.tipMsgArray[2]
          this.tipVisible = true

          clearInterval(this.timeId)
        }
      }, 150)
    },
  },
}
</script>

<style scoped lang="scss">
.submit {
  margin-left: 16px;
  .btn {
    background: #47c12f;
    border-color: #47c12f;
    width: 84px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    &.btn-active {
      background: #47c12f;
      border-color: #47c12f;
    }
  }
  ::v-deep .el-button {
    span {
      margin-left: 0;
    }
    &.el-button--primary {
      background-color: #47c12f;
      border-color: #47c12f;
    }
  }
}
</style>

<template>
  <el-dialog
    :visible.sync="visible"
    class="exercise-ai-content"
    :close-on-click-modal="false"
  >
    <div class="title">{{ i18n.Pra.text92 }}</div>
    <div class="block" v-if="contentStr">
      <div class="block-title">{{ i18n.Pra.text93 }}</div>
      <div class="block-content">
        {{ contentStr }}
      </div>
    </div>

    <div class="block-audio">
      <span>{{ i18n.Pra.text94 }}</span>
      <AudioToolMini :audioUrl="recorderUrl" />
    </div>

    <div class="block-ai-content">
      <div class="block-header">
        <div class="block-header-title">
          <span>AI</span>{{ i18n.Pra.text95 }}
        </div>
        <div class="mark-tag">
          <div class="mark success">{{ i18n.Pra.text96 }}</div>
          <div class="mark fail">{{ i18n.Pra.Misreading }}</div>
          <!--          <div class="mark miss">漏读</div>-->
          <!--          <div class="mark more">多读</div>-->
        </div>
      </div>

      <div class="block-ai-body">
        <template
          v-if="
            recorderArticle &&
              recorderArticle.length === 1 &&
              !recorderArticle[0].text
          "
        >
          <span class="ai-tips">{{ i18n.Pra.text97 }}</span>
        </template>
        <template v-else>
          <span
            v-for="(item, index) in recorderArticle"
            :class="
              item.isWrong && !['DI', 'RL'].includes(typeInfo.type)
                ? 'wrong'
                : ''
            "
            :key="`${item.text}_${index}`"
            >{{ item.text }}&nbsp;</span
          >
        </template>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import AudioToolMini from '@/components/AudioToolMini/AudioToolMini.vue'
import { wordSpaceCalc, symbolsFix } from '@/utils/parctice'

export default {
  components: {
    AudioToolMini
  },
  props: {
    greRecorder: {
      type: [Array, String]
    },
    recorderArticle: {
      type: Array
    },
    typeInfo: {
      type: Object
    },
    recorderUrl: {
      type: [String, Object, Blob]
    },
    detail: {
      type: Object
    }
  },
  data() {
    return {
      visible: false
    }
  },
  computed: {
    contentStr() {
      if (!this.detail.content_text) {
        return null
      }

      return symbolsFix(this.detail.content_text).replace(/[(|/]/g, '')
    },
    i18n() {
      return this.$t('messages')
    }
  },
  methods: {
    open() {
      this.visible = true
    },
    wordSpace(greRecorder, item, index) {
      return wordSpaceCalc(greRecorder, item, index)
    }
  }
}
</script>

<style scoped lang="scss">
.exercise-ai-content {
  ::v-deep .el-dialog {
    border-radius: 10px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
    .el-dialog__title {
      display: none;
    }
    .el-dialog__headerbtn {
      top: 20px;
    }
  }
  .title {
    color: #a4afb7;
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 6px;
  }
  .block {
    .block-title {
      font-size: 14px;
      font-weight: bold;
      color: #1f1f40;
      margin-top: 10px;
    }
    .block-content {
      margin-top: 6px;
      line-height: 24px;
      font-size: 14px;
      color: #1f1f40;
    }
  }
  .block-audio {
    margin-top: 20px;
    padding: 17px 20px;
    border-radius: 5px;
    background-color: #f4f5f9;
    display: flex;
    align-items: center;
    ::v-deep .audio-list-mini {
      height: 30px;
      border-radius: 15px;
      line-height: 30px;
    }
  }
  .block-ai-content {
    margin-top: 24px;
    .block-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .block-header-title {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #ffc06a;
        font-weight: bold;
        > span {
          margin-right: 4px;
          font-size: 12px;
          height: 18px;
          width: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px;
          background-color: #ffc06a;
          color: #fff;
        }
      }
      .mark-tag {
        display: flex;
        align-items: center;
        .mark {
          margin-left: 30px;
          position: relative;
          color: #36c626;
          &:after {
            content: '';
            position: absolute;
            left: -14px;
            top: 6px;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background-color: #36c626;
          }
          &.success {
            color: #36c626;
            &:after {
              background-color: #36c626;
            }
          }
          &.fail {
            color: #ff606d;
            &:after {
              background-color: #ff606d;
            }
          }
          &.miss {
            color: #bfbfbf;
            text-decoration: line-through;
            &:after {
              background-color: #bfbfbf;
            }
          }
          &.more {
            color: #ffc06a;
            &:after {
              background-color: #ffc06a;
            }
          }
        }
      }
    }
    .block-ai-body {
      margin-top: 10px;
      line-height: 24px;
      font-size: 14px;
      min-height: 100px;
      .wrong {
        color: #ff606d;
      }
    }
  }
}
</style>

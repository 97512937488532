var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"exercises-tabs-item exercises-tabs-answer"},[_c('div',{staticClass:"tabs"},[_c('TabsBtn',{attrs:{"list":_vm.typeList,"onClick":_vm.handleType}})],1),(_vm.currType === 'answer')?[(_vm.detail.question_answer)?_c('div',{staticClass:"content"},[(
          _vm.detail.answer &&
            _vm.detail.answer.article != undefined &&
            _vm.typeInfo.type === 'HIW'
        )?_c('div',{staticClass:"fill"},_vm._l((_vm.detail.answer.article),function(item,index){return _c('span',{key:index,style:(item.lang == 'default' ? 'margin:0' : '')},[_c('span',{staticClass:"word",class:item.truth ? 'red' : '',style:(item.lang == 'default' ? 'margin:0' : '')},[_c('TranslateWord',{attrs:{"word":item.text}},[_vm._v(_vm._s(item.text))])],1),(item.truth)?_c('span',{staticClass:"truth word"},[_c('TranslateWord',{attrs:{"word":item.truth}},[_vm._v("("+_vm._s(item.truth)+")")])],1):_vm._e()])}),0):(_vm.textList.length)?_c('div',{staticClass:"text-list"},_vm._l((_vm.textList),function(tItem,tIndex){return _c('div',{key:tIndex,staticClass:"text-list-item word"},_vm._l((tItem.content.split(/[ ]+/gi)),function(word,index){return _c('span',{key:`${word}_${index}`},[_c('TranslateWord',{attrs:{"word":word}},[_vm._v(_vm._s(word + " "))])],1)}),0)}),0):(
          _vm.detail.question_answer && typeof _vm.detail.question_answer === 'string'
        )?_c('div',{staticClass:"text word"},_vm._l((_vm.text.split(/[ ]+/gi)),function(word,index){return _c('span',{key:`${word}_${index}`},[_c('TranslateWord',{attrs:{"word":word}},[_vm._v(_vm._s(word + " "))])],1)}),0):_vm._e()]):(
        !_vm.isPractice &&
          _vm.detail.answer_export &&
          ['RL', 'SST'].includes(_vm.typeInfo.type)
      )?_c('div',{staticClass:"content"},_vm._l((_vm.detail.answer_export.split(/[ ]+/gi)),function(word,index){return _c('span',{key:`${word}_${index}`},[_c('TranslateWord',{attrs:{"word":word}},[_vm._v(_vm._s(word + " "))])],1)}),0):_c('div',{staticClass:"empty-content"},[_c('div',[_c('i',{staticClass:"f-icon icon-kong"}),_vm._v(_vm._s(_vm.i18n.locale === 'ch' ? '暂无记录' : 'No record')+" ")])])]:_vm._e(),(_vm.currType === 'parse')?[(_vm.detail.gre_parse || _vm.detail.gre_parse_word)?_c('div',{staticClass:"content"},[(_vm.detail.gre_parse)?_c('div',{staticClass:"content-text"},[_c('span',{staticClass:"word"},[_vm._v(_vm._s(_vm.detail.gre_parse))])]):_vm._e(),(_vm.detail.gre_parse_word)?_c('div',{staticClass:"content-text"},[_c('span',{staticClass:"word"},[_vm._v(_vm._s(_vm.detail.gre_parse_word))])]):_vm._e()]):_c('div',{staticClass:"empty-content"},[_c('div',[_c('i',{staticClass:"f-icon icon-kong"}),_vm._v(_vm._s(_vm.i18n.locale === 'ch' ? '暂无记录' : 'No record')+" ")])])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <el-popover
    class="exercises-content-lead"
    popper-class="exercises-content-lead-popover"
    trigger="manual"
    v-model="visible"
    placement="right"
    @click.stop
    v-if="info && info.demonstration"
    @after-enter="handlePopverShow"
  >
    <Button
      icon="icon-teacher"
      slot="reference"
      :check="visible"
      @click="visible = !visible"
    >
      {{i18n.Pra.shiFan}}
    </Button>

    <AudioToolMini v-if="info" :audioUrl="info.demonstration" ref="audio" />
  </el-popover>
</template>

<script>
import AudioToolMini from '@/components/AudioToolMini/AudioToolMini.vue'
import Button from '@/components/button'
import { apiWebGetDemonstration } from '@/api/api'

export default {
  components: {
    AudioToolMini,
    Button,
  },
  props: {
    id: {
      type: [Number, String],
    },
    typeInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      visible: false,
      currentId: null,

      info: null,
    }
  },
  computed: {
    hadTeacher() {
      return 'RA'.indexOf(this.typeInfo.type) >= 0
    },
    i18n() {
      return this.$t('messages')
    },
  },
  watch: {
    id: {
      immediate: true,
      handler() {
        if (this.hadTeacher) {
          this.fetchInfo()
        }
      },
    },
  },
  mounted() {
    this.docClick = () => {
      this.visible = false

      if (this.$refs.audio) {
        this.$refs.audio.playPause()
      }
    }

    document.addEventListener('click', this.docClick)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.docClick)

    this.docClick()
  },
  methods: {
    playAudio(id) {
      this.currentId = id
    },
    handlePopverShow() {
      if (this.$refs.audio) {
        this.$refs.audio.playAudio()
      }
    },

    fetchInfo() {
      apiWebGetDemonstration({
        id: this.id,
      }).then((res) => {
        if (res.code != 200) {
          return
        }
        this.info = res.data
      })
    },
  },
}
</script>

<style scoped lang="scss">
.exercises-content-lead {
}
</style>

<style lang="scss">
.exercises-content-lead-popover {
  background-color: #585d6e;
  padding: 6px 6px;
  border-radius: 6px;
  .popper__arrow {
    border-right-color: #585d6e !important;
    &:after {
      border-right-color: #585d6e !important;
    }
  }
  .audio-list-mini {
    background: none;
    border: 0;
    .audio-list-content {
      > .play-btn {
        color: #ffc06a;
      }
      .el-slider__bar {
        background-color: #ffc06a;
      }
    }
  }
}
</style>

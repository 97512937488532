var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"detail-list-item"},[_c('img',{attrs:{"src":_vm.item.user_logo}}),_c('div',[_c('div',[_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.item.user_name))]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.item.user_goal))])]),(_vm.item.content_info.type === 'audio')?[_c('div',{staticClass:"audio-wrap"},[_c('Audio',{attrs:{"url":_vm.item.contents,"id":_vm.item.id,"currentId":_vm.currentId},on:{"palyAudio":_vm.palyAudio}}),(
            (_vm.userInfo.id == _vm.item.uid || _vm.item.aiCore) &&
              ['RA', 'RS', 'DI', 'RL', 'ASQ'].includes(_vm.typeInfo.type)
          )?[(_vm.item.aiCore)?_c('div',{staticClass:"audio-score"},[_c('span',[_vm._v(" "+_vm._s(_vm.item.aiCore ? _vm.item.aiCore.overall || 0 : 0)+"/90 ")]),_c('span',{staticClass:"btn",class:{
                disabled: !_vm.item.aiCore
              },on:{"click":_vm.handleShowAiCore}},[_vm._v(" "+_vm._s(_vm.i18n.Mock.ratings)+" ")])]):_c('div',[(_vm.recorderCore)?[_c('div',{staticClass:"audio-score"},[_c('span',[_vm._v(" "+_vm._s(_vm.recorderCore.overall || 0)+"/90 ")]),_c('span',{staticClass:"btn",on:{"click":_vm.handleShowAiCore}},[_vm._v(" "+_vm._s(_vm.i18n.Mock.ratings)+" ")])])]:(_vm.isStartGenCore)?[_c('el-popover',{attrs:{"trigger":"hover","placement":"top","content":_vm.i18n.Pra.text107}},[_c('div',{staticClass:"ai-content",attrs:{"slot":"reference"},on:{"click":_vm.showAiProgress},slot:"reference"},[_c('span',{staticClass:"ai"},[_vm._v("AI")]),_c('SubmitProgress',{attrs:{"text":"评分中","time":20000}})],1)])]:[_c('div',{staticClass:"ai-content",on:{"click":function($event){return _vm.handleGenAI(_vm.item)}}},[_c('span',{staticClass:"ai"},[_vm._v("AI")]),_vm._v(_vm._s(_vm.i18n.Pra.text48)+" ")])]],2)]:_vm._e()],2)]:[(_vm.item.answer_status && Number(_vm.item.answer_status) === 1)?_c('div',{staticClass:"text-record",domProps:{"innerHTML":_vm._s(_vm.item.contents)}}):_c('div',{staticClass:"text-record"},[(_vm.item.content_list)?_vm._l((_vm.item.content_list),function(text){return _c('p',{key:text},[_vm._v(" "+_vm._s(text)+" ")])}):(['HIW'].includes(_vm.typeInfo.type))?[_c('WordDiff',{attrs:{"type":"array","answer":_vm.detail.content_text
                .match(/\{([^{]+)\}/gi)
                .map((m) => m.replace(/\{|\}/g, '')),"content":_vm.item.content_info.content.split(',')}})]:[_vm._v(" "+_vm._s(_vm.item.content_info.content)+" ")]],2),(
          [
            'RO',
            'MCM',
            'FIB',
            'HCS',
            'MCS',
            'SMW',
            'HIW',
            'WFD',
            'RFIB',
            'RWFIB'
          ].includes(_vm.typeInfo.type)
        )?_c('span',{staticClass:"score"},[_vm._v(" "+_vm._s(_vm.item.score_my)+_vm._s(_vm.$t('gre.point'))+" ")]):_vm._e()],_c('div',{staticClass:"bottom"},[_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.item.created_at))]),_c('span',{staticClass:"favour"},[_c('i',{staticClass:"f-icon",class:[_vm.item.is_liked ? 'icon-likes' : 'icon-like'],on:{"click":function($event){return _vm.handleLike(_vm.item, _vm.index)}}}),_vm._v(_vm._s(_vm.item.like_cnt))]),(_vm.type === 1)?_c('i',{staticClass:"f-icon icon-del",on:{"click":function($event){return _vm.handleDel(_vm.item)}}}):_vm._e(),_c('i',{staticClass:"iconfont icon-share1",on:{"click":function($event){return _vm.onShare(_vm.item)}}})]),(_vm.item.subList && _vm.item.subList.length)?_c('div',{staticClass:"fold"},[_vm._v(" "+_vm._s(_vm.i18n.Pra.Folded)+_vm._s(_vm.item.subList.length)+_vm._s(_vm.i18n.Pra.Records)+"，"),_c('span',{on:{"click":function($event){return _vm.showAll(_vm.index)}}},[_vm._v(_vm._s(_vm.i18n.Forum.Click))])]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }
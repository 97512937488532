<template>
  <li class="detail-list-item">
    <img :src="item.user_logo" />

    <div>
      <div>
        <span class="name">{{ item.user_name }}</span>
        <span class="text">{{ item.user_goal }}</span>
      </div>
      <template v-if="item.content_info.type === 'audio'">
        <div class="audio-wrap">
          <Audio
            :url="item.contents"
            :id="item.id"
            :currentId="currentId"
            @palyAudio="palyAudio"
          />
          <template
            v-if="
              (userInfo.id == item.uid || item.aiCore) &&
                ['RA', 'RS', 'DI', 'RL', 'ASQ'].includes(typeInfo.type)
            "
          >
            <div class="audio-score" v-if="item.aiCore">
              <span> {{ item.aiCore ? item.aiCore.overall || 0 : 0 }}/90 </span>
              <span
                class="btn"
                :class="{
                  disabled: !item.aiCore
                }"
                @click="handleShowAiCore"
              >
                {{ i18n.Mock.ratings }}
              </span>
            </div>
            <div v-else>
              <template v-if="recorderCore">
                <div class="audio-score">
                  <span> {{ recorderCore.overall || 0 }}/90 </span>
                  <span class="btn" @click="handleShowAiCore">
                    {{ i18n.Mock.ratings }}
                  </span>
                </div>
              </template>
              <template v-else-if="isStartGenCore">
                <el-popover
                  trigger="hover"
                  placement="top"
                  :content="i18n.Pra.text107"
                >
                  <div
                    class="ai-content"
                    slot="reference"
                    @click="showAiProgress"
                  >
                    <span class="ai">AI</span>
                    <SubmitProgress text="评分中" :time="20000" />
                  </div>
                </el-popover>
              </template>
              <template v-else>
                <div class="ai-content" @click="handleGenAI(item)">
                  <span class="ai">AI</span>{{ i18n.Pra.text48 }}
                </div>
              </template>
            </div>
          </template>
        </div>
      </template>
      <template v-else>
        <div
          class="text-record"
          v-if="item.answer_status && Number(item.answer_status) === 1"
          v-html="item.contents"
        ></div>
        <div class="text-record" v-else>
          <template v-if="item.content_list">
            <p v-for="text of item.content_list" :key="text">
              {{ text }}
            </p>
          </template>
          <template v-else-if="['HIW'].includes(typeInfo.type)">
            <WordDiff
              type="array"
              :answer="
                detail.content_text
                  .match(/\{([^{]+)\}/gi)
                  .map((m) => m.replace(/\{|\}/g, ''))
              "
              :content="item.content_info.content.split(',')"
            />
          </template>
          <template v-else>
            {{ item.content_info.content }}
          </template>
        </div>

        <!--得分-->
        <span
          class="score"
          v-if="
            [
              'RO',
              'MCM',
              'FIB',
              'HCS',
              'MCS',
              'SMW',
              'HIW',
              'WFD',
              'RFIB',
              'RWFIB'
            ].includes(typeInfo.type)
          "
        >
          {{ item.score_my }}{{ $t('gre.point') }}
        </span>
      </template>
      <div class="bottom">
        <span class="time">{{ item.created_at }}</span>
        <span class="favour"
          ><i
            class="f-icon"
            :class="[item.is_liked ? 'icon-likes' : 'icon-like']"
            @click="handleLike(item, index)"
          />{{ item.like_cnt }}</span
        >
        <i class="f-icon icon-del" v-if="type === 1" @click="handleDel(item)" />
        <i class="iconfont icon-share1" @click="onShare(item)"></i>
      </div>
      <div class="fold" v-if="item.subList && item.subList.length">
        {{ i18n.Pra.Folded }}{{ item.subList.length
        }}{{ i18n.Pra.Records }}，<span @click="showAll(index)">{{
          i18n.Forum.Click
        }}</span>
      </div>
    </div>
  </li>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Audio from '@/components/Audio/Audio.vue'
import WordDiff from '@/components/wordDiff'
import SubmitProgress from '@/components/submitProgress'

import {
  apiCommentLike,
  apiDelMyTry,
  apiQuestionTryList,
  apiAiCore
} from '@/api/api'

export default {
  components: {
    Audio,
    WordDiff,
    SubmitProgress
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    index: {
      type: Number,
      default: 0
    },
    detail: {
      type: Object,
      default: () => ({})
    },
    id: {
      type: [Number, String]
    },
    currentId: {
      type: Number,
      default: 0
    },
    isPractice: {
      type: Boolean
    },
    typeInfo: {
      type: Object,
      default: () => {}
    },
    type: {
      type: Number,
      default: 0
    },

    palyAudio: {
      type: Function,
      default: () => {}
    },
    handleShowAudio: {
      type: Function,
      default: () => {}
    },
    handleLike: {
      type: Function,
      default: () => {}
    },
    handleDel: {
      type: Function,
      default: () => {}
    },
    showAll: {
      type: Function,
      default: () => {}
    },
    showAiCore: {
      type: Function,
      default: () => {}
    },
    showAiProgress: {
      type: Function,
      default: () => {}
    },
    showAiVip: {
      type: Function,
      default: () => {}
    },

    onShare: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      isStartGenCore: false,
      recorderCore: null
    }
  },
  computed: {
    ...mapState('app', {
      userInfo: (state) => state.userInfo
    }),
    i18n() {
      return this.$t('messages')
    }
  },
  methods: {
    ...mapActions('app', ['GetUserInfo']),
    handleShowAiCore() {
      this.showAiCore(
        this.item.aiCore
          ? {
              ...this.item.aiCore,
              contents: this.item.contents
            }
          : {
              ...this.recorderCore,
              contents: this.item.contents
            }
      )
    },
    handleGenAI(item) {
      try {
        if (
          (!this.userInfo.vip || this.userInfo.vip.code == 0) &&
          this.userInfo.ai_today_used_cnt >= 5
        ) {
          return this.showAiVip()
        }
      } catch (e) {}

      this.isStartGenCore = true

      apiAiCore({
        uqid: item.id,
        ref_text: this.detail.question_answer,
        oss_url: item.contents,
        type: this.typeInfo.type
      }).then((coreRes) => {
        if (coreRes.code !== 200) {
          this.isStartGenCore = false
          return false
        }

        this.isStartGenCore = false

        this.recorderCore = coreRes.data

        this.showAiProgress(false)

        this.GetUserInfo()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.detail-list-item {
  display: flex;
  align-item: flex-start;
  padding: 22px 0 16px;
  border-bottom: 1px solid #ebebf2;
  &:last-child {
    border: none;
  }
  .audio-content {
    margin: 5px 0;
  }
  .audio-wrap {
    display: flex;
    align-items: center;
    .audio-score {
      padding: 6px;
      background-color: #f4f4f4;
      border-radius: 5px;
      margin-left: 16px;
      color: #9c9c9c;
      font-size: 12px;
      .btn {
        color: #3b86ff;
        margin-left: 5px;
        cursor: pointer;
        &.disabled {
          opacity: 0.4;
          cursor: not-allowed;
        }
      }
    }
  }

  .fold {
    margin-top: 10px;
    span {
      cursor: pointer;
      color: #3b86ff;
    }
  }
  .text-record {
    font-size: 14px;
    line-height: 24px;
    padding: 4px 0;
  }
  .score {
    color: #df394d;
  }
  .ai-content {
    border-radius: 5px;
    margin-left: 16px;
    color: #9c9c9c;
    font-size: 12px;
    line-height: 30px;
    border-radius: 15px;
    padding: 0 14px;
    background-color: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    &.disabled {
      background-color: #bfbfbf;
      color: #fff;
      cursor: not-allowed;
      > .ai {
        background: #fff;
        color: #bfbfbf;
      }
    }
    .ai {
      line-height: 20px;
      text-align: center;
      display: inline-block;
      width: 20px;
      height: 20px;
      background: #ffc06a;
      border-radius: 5px;
      color: #fff;
      margin-right: 5px;
    }
  }
  .icon-del {
    cursor: pointer;
    margin-right: 20px;
  }
  .icon-share1 {
    cursor: pointer;
    font-size: 14px;
  }
  .bottom {
    margin-top: 4px;
  }
}
</style>

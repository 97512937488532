<template>
  <div class="exercises-tabs-everyone-work" v-if="visible">
    <div v-loading="loading && page === 1" class="wrapper">
      <div class="infinite-list-wrapper" v-if="list.length && isPractice">
        <ul class="detail-list">
          <li
            class="detail-list-item"
            v-for="(item, index) of list"
            :key="`${item.id}-${type}`"
          >
            <img :src="item.user_logo" />

            <div>
              <div>
                <span class="name">{{ item.user_name }}</span>
                <span class="text">{{ item.user_goal }}</span>
              </div>
              <template v-if="item.content_info.type === 'audio'">
                <Audio
                  :url="item.contents"
                  :id="item.id"
                  :currentId="currentId"
                  @palyAudio="palyAudio"
                />
              </template>
              <template v-else>
                <div
                  class="text-record"
                  v-if="item.answer_status && Number(item.answer_status) === 1"
                  v-html="item.contents"
                ></div>
                <div class="text-record" v-else>
                  <template v-if="item.content_list">
                    <p v-for="text of item.content_list" :key="text">
                      {{ text }}
                    </p>
                  </template>
                  <template v-else-if="['HIW'].includes(typeInfo.type)">
                    <WordDiff
                      type="array"
                      :answer="
                        detail.content_text
                          .match(/\{([^{]+)\}/gi)
                          .map((m) => m.replace(/\{|\}/g, ''))
                      "
                      :content="item.content_info.content.split(',')"
                    />
                  </template>
                  <template v-else>
                    {{ item.content_info.content }}
                  </template>
                </div>

                <!--得分-->
                <span
                  class="score"
                  v-if="
                    [
                      'RO',
                      'MCM',
                      'FIB',
                      'HCS',
                      'MCS',
                      'SMW',
                      'HIW',
                      'WFD',
                      'RFIB',
                      'RWFIB',
                    ].includes(typeInfo.type)
                  "
                >
                  {{ item.score_my }}{{ $t('gre.point') }}
                </span>
              </template>
              <div class="bottom">
                <span class="time">{{ item.created_at }}</span>
                <span class="favour"
                  ><i
                    class="f-icon"
                    :class="[item.is_liked ? 'icon-likes' : 'icon-like']"
                    @click="handleLike(item, index)"
                  />{{ item.like_cnt }}</span
                >
              </div>
              <div class="fold" v-if="item.subList && item.subList.length">
                {{ i18n.Pra.Folded }}{{ item.subList.length
                }}{{ i18n.Pra.Records }}，<span @click="showAll(index)">{{
                  i18n.Forum.Click
                }}</span>
              </div>
            </div>
          </li>
        </ul>

        <More
          :page="page"
          :pageSize="pageSize"
          :loading="loading"
          :totalPage="count.page"
          @load="load"
        />
      </div>
      <Empty v-if="!isPractice || (!loading && !list.length)" />
    </div>
  </div>
</template>

<script>
import Empty from '@/components/ExercisesEmpty.vue'
import Audio from '@/components/Audio/Audio.vue'
import More from '@/components/more'
import WordDiff from '@/components/wordDiff'
import { apiCommentLike, apiDelMyTry, apiQuestionWorkList } from '@/api/api'

export default {
  components: {
    Empty,
    Audio,
    More,
    WordDiff,
  },
  props: {
    id: {
      type: [Number, String],
    },
    isPractice: {
      type: Boolean,
    },
    type: {
      type: Number,
      default: 0,
    },
    typeInfo: {
      type: Object,
      default: () => {},
    },
    onTotal: {
      type: Function,
      default: () => {},
    },
    visible: {
      type: Boolean,
      default: false,
    },
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    disabled() {
      return this.loading || this.noMore
    },
    noMore() {
      return this.page >= this.count.page
    },
    i18n() {
      return this.$t('messages')
    },
  },
  data() {
    return {
      page: 1,
      pageSize: 10,

      count: {
        done: 0,
        view: 0,
        all: 0,
        page: 0,
      },

      loading: false,
      likeLoading: false,
      list: [],
      currentId: null,
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    getSelectAnswer(contents) {
      if (!contents) {
        return contents
      }
      const list = contents.split(',')
      const letterList = list.map((item) =>
        String.fromCharCode(65 + Number(item))
      )
      return letterList.join(',')
    },
    getNumberAnswer(contents) {
      if (!contents) {
        return contents
      }
      const list = contents.split(',')
      const letterList = list.map((item) => Number(item) + 1)
      return letterList.join(',')
    },
    palyAudio(id) {
      this.currentId = id
    },
    load(page) {
      this.fetchList(page)
    },
    handleLike(item, index) {
      if (this.likeLoading) {
        return
      }
      apiCommentLike({
        aid: item.id,
        type: 'user_questions',
      })
        .then((res) => {
          if (res.code != 200) {
            return
          }
          this.$layer.msg(res.msg)
          const add = item.is_liked ? -1 : 1
          this.list[index].is_liked = add > 0 ? 1 : 0
          this.list[index].like_cnt += add
        })
        .finally(() => {
          this.likeLoading = false
        })
    },
    handleDel(item) {
      apiDelMyTry({
        id: item.id,
      }).then((res) => {
        if (res.code != 200) {
          return
        }
        this.$layer.msg(res.msg)
        this.fetchList(1)
      })
    },
    fetchList(page) {
      if (!this.isPractice) {
        return
      }
      if (this.loading) {
        return
      }
      this.loading = true
      if (page) {
        this.page = page
      }
      apiQuestionWorkList({
        question_id: this.id,
        page_size: this.pageSize,
        page: this.page,
      }).then((res) => {
        if (res.code != 200) {
          this.loading = false
          return
        }
        const { my_try_cnt, all_view_cnt, all_try_cnt, pager } = res.data

        this.count = {
          done: my_try_cnt,
          view: all_view_cnt,
          all: all_try_cnt,
          page: pager.page_cnt,
        }
        this.onTotal(this.count)

        let list = res.data.list
        if (['MCS', 'MCM', 'HCS', 'SMW'].includes(this.typeInfo.type)) {
          list = list.map((item) => {
            return {
              ...item,
              contents: this.getSelectAnswer(item.contents),
            }
          })
        }
        if (['RO'].includes(this.typeInfo.type)) {
          list = list.map((item) => {
            return {
              ...item,
              contents: this.getNumberAnswer(item.contents),
            }
          })
        }
        let foldList = list
        const endItem = this.page !== 1 ? [this.list[this.list.length - 1]] : []
        if (this.type !== 1) {
          foldList = list.reduce((pre, next) => {
            const preItem = pre.length ? pre[pre.length - 1] : {}
            const time = new Date(next.created_at).getTime()
            if (
              preItem.uid === next.uid &&
              (!preItem.subList || preItem.subList.length) &&
              new Date(preItem.created_at).getTime() - time <= 1800000
            ) {
              const addNextItem = { ...next, subList: [] }
              const addItem = preItem.subList
                ? { ...preItem, subList: [...preItem.subList, addNextItem] }
                : { ...preItem, subList: [addNextItem] }
              return [...pre.slice(0, pre.length - 1), addItem]
            } else {
              return [...pre, next]
            }
          }, endItem)
        }
        this.list =
          this.page === 1
            ? foldList
            : [...this.list.slice(0, this.list.length - 1), ...foldList]
        this.loading = false
      })
    },
    showAll(index) {
      this.list.splice(index + 1, 0, ...this.list[index].subList)
      this.list[index].subList = []
    },
  },
}
</script>

<style scoped lang="scss">
.exercises-tabs-everyone-work {
  .detail-list {
    color: #a4afb7;
    font-size: 12px;
    .detail-list-item {
      display: flex;
      align-item: flex-start;
      padding: 22px 0 16px;
      border-bottom: 1px solid #ebebf2;
      &:last-child {
        border: none;
      }
      .audio-content {
        margin: 5px 0;
      }
    }
  }
  .fold {
    margin-top: 10px;
    span {
      cursor: pointer;
      color: #3b86ff;
    }
  }
  .text-record {
    font-size: 14px;
    line-height: 24px;
    padding: 4px 0;
  }
  .score {
    color: #df394d;
  }
  .wrapper {
    height: calc(100% - 50px);
    overflow-y: auto;
    border-bottom: 1px solid #ebebf2;
  }
  .icon-del {
    cursor: pointer;
  }
}
</style>
